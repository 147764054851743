import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IBlindSelect } from '@core/config';

@Injectable({
    providedIn: 'root'
})

export class ShareService {

    private country = new Subject<string>();
    private countryLink = new Subject<string>();
    private countryPopup = new Subject<any>();
    private blindStatus = new Subject<boolean>();
    private blindUnselect = new Subject<boolean>();
    private blindSelect = new Subject<IBlindSelect>();
    private blindEmpty = new Subject<boolean>();
    private blindName = new Subject<string>();
    private blindSizeChanged = new Subject<any>();
    private dataSource = new Subject<string>();
    private retailerInfo = new Subject<any>();
    private linkSessionKey = new Subject<any>();

    private operationName = new Subject<string>();
    private materialType = new Subject<string>();
    private sessionBlindsData = new Subject<any>();
    private sceenShotBLindTemp = new Subject<any>();
    private emptyBlindData = new Subject<any>();
    private blindPosition = new Subject<any>();
    private cursorPointer = new Subject<any>();
    private cursorGrab = new Subject<any>();
    private retailerRequest = new Subject<any>();
    private userTime = new Subject<any>();
    private actionBackPage = new Subject<any>();
    private modelLoaded = new Subject<any>();
    private sceneCreated = new Subject<string>();
    private screenShotSaved = new Subject<any>();
    private loadingSpinner = new Subject<any>();
    private blindsUpdated = new Subject<any>();
    private materialColorChanged = new Subject<any>();
    private blindType = new Subject<string>();
    private accordionType = new Subject<string>();

    getCountryPopup = this.countryPopup.asObservable();
    getCountry = this.country.asObservable();
    getCountryLink = this.countryLink.asObservable();
    getStatus = this.blindStatus.asObservable();
    getBlindName = this.blindName.asObservable();
    getBlindSizeChanged = this.blindSizeChanged.asObservable();
    getBlindUnselect = this.blindUnselect.asObservable();
    getBlindSelect = this.blindSelect.asObservable();
    getBlindEmpty = this.blindEmpty.asObservable();
    currentData = this.dataSource.asObservable();
    getRetailerInfo = this.retailerInfo.asObservable();
    getEmptyBlindData = this.emptyBlindData.asObservable();

    getOperationName = this.operationName.asObservable();
    getMaterialType = this.materialType.asObservable();
    getLinkSessionKey = this.linkSessionKey.asObservable();
    getSessionBlindsData = this.sessionBlindsData.asObservable();
    getSceenShotBLindTemp = this.sceenShotBLindTemp.asObservable();
    getBlindPosition = this.blindPosition.asObservable();
    getCursorPointer = this.cursorPointer.asObservable();
    getCursorGrab = this.cursorGrab.asObservable();
    getRetailerRequest = this.retailerRequest.asObservable();
    getUserTime = this.userTime.asObservable();
    getActionBackPage = this.actionBackPage.asObservable();
    getModelLoaded = this.modelLoaded.asObservable();
    getSceneCreated = this.sceneCreated.asObservable();
    getScreenShotSaved = this.screenShotSaved.asObservable();
    getLoadingSpinner = this.loadingSpinner.asObservable();
    getBlindsUpdated = this.blindsUpdated.asObservable();
    getMaterialColorChanged = this.materialColorChanged.asObservable();
    getBlindType = this.blindType.asObservable();
    getAccordionType = this.accordionType.asObservable();

    setCountryPopup(state: any): void {
        this.countryPopup.next(state);
    }

    setCountry(country: string): void {
        this.country.next(country);
    }

    setCountryLink(country: string): void {
        this.countryLink.next(country);
    }

    setStatus(status: boolean): void {
        this.blindStatus.next(status);
    }

    setBlindName(name: string): void {
        this.blindName.next(name);
    }

    setBlindSizeChanged(size: any): void {
        this.blindSizeChanged.next(size);
    }

    setBlindUnselect(select: boolean): void {
        this.blindUnselect.next(select);
    }

    setBlindSelect(select: IBlindSelect): void {
        this.blindSelect.next(select);
    }

    setBlindEmpty(select: boolean): void {
        this.blindEmpty.next(select);
    }

    changeData(data: any): void {
        this.dataSource.next(data);
    }

    setOperationName(select: string): void {
        this.operationName.next(select);
    }

    setMaterialType(select: string): void {
        this.materialType.next(select);
    }

    setRetailerInfo(data: any): void {
        this.retailerInfo.next(data);
    }

    setLinkSessionKey(data: any): void {
        this.linkSessionKey.next(data);
    }

    setSessionBlindsData(data: any): void {
        this.sessionBlindsData.next(data);
    }

    setSceenShotBLindTemp(data: any): void {
        this.sceenShotBLindTemp.next(data);
    }

    setEmptyBlindData(data: any): void {
        this.emptyBlindData.next(data);
    }

    setBlindPosition(data: any): void {
        this.blindPosition.next(data);
    }

    setCursorPointer(data: any): void {
        this.cursorPointer.next(data);
    }

    setCursorGrab(data: any): void {
        this.cursorGrab.next(data);
    }

    setRetailerRequest(event: any) {
        this.retailerRequest.next(event);
    }

    setUserTime(time: any) {
        this.userTime.next(time);
    }

    setActionBackPage(blindData: boolean) {
        this.actionBackPage.next(blindData);
    }

    setModelLoaded(status: any) {
        this.modelLoaded.next(status);
    }

    setSceneCreated(status: string) {
        this.sceneCreated.next(status);
    }

    setScreenShotSaved(status: any) {
        this.screenShotSaved.next(status);
    }

    setLoadingSpinner(status: any) {
        this.loadingSpinner.next(status);
    }

    setBlindsUpdated(status: any) {
        this.blindsUpdated.next(status);
    }

    setMaterialColorChanged(color: any) {
        this.materialColorChanged.next(color);
    }

    setBlindType(type: string) {
        this.blindType.next(type);
    }

    setAccordionType(type: string) {
        this.accordionType.next(type);
    }
}
