<div class="mc-size__container">
    <div class="mc-size__item">
        <div class="mc-size__item-title">Width</div>

        <mat-slider
            #modelSize
            (change)="updateSetting('width', modelSize.value)"
            (input)="onInputChange('width', modelSize.value)"
            min="1" max="2" step="0.001"
            [ngModel]="valueWidth"
            [value]="modelSize"
        ></mat-slider>
        <div class="mc-size__item-input-block">
            <input type="number"
                   [ngClass]="{'extra': hasExtraSizeWidth}"
                   class="mc-size__item-input"
                   value="{{sizeValue.width}}"
                   [(ngModel)]="dataValue.width"
                   (ngModelChange)="onInputChangeHandler('width', $event)"
                   (keypress)="inputValidate($event)"
            /> mm
        </div>
    </div>
    <div class="mc-size__item">
        <div class="mc-size__item-title">Height</div>

        <mat-slider
            #modelSizeVert
            (change)="updateSetting('height', modelSizeVert.value)"
            (input)="onInputChange('height', modelSizeVert.value)"
            min="1" max="2" step="0.001"
            [ngModel]="valueHeight"
            [value]="modelSizeVert"
        ></mat-slider>
        <div class="mc-size__item-input-block">
            <input type="number"
                   [ngClass]="{'extra': hasExtraSizeHeight}"
                   class="mc-size__item-input"
                   value="{{sizeValue.height}}"
                   [(ngModel)]="dataValue.height"
                   (ngModelChange)="onInputChangeHandler('height', $event)"
                   (keypress)="inputValidate($event)"
                   /> mm
        </div>
    </div>

    <div class="mc-size__info" *ngIf="hasExtraSizeWidth || hasExtraSizeHeight">
        <div class="mc-warn-icon"></div>
        Looks like you want to make a Ziptrak® blind out of the <a href="#">standard size</a>?
        Your retailer will discuss options of how best to meet your needs!
    </div>
    <div class="mc-size__info" *ngIf="modelType === 'interior' && +dataValue.width >= 500 && +dataValue.width <= 700 && operationName === 'Motorised' && !(hasExtraSizeWidth || hasExtraSizeHeight)">
        <div class="mc-warn-icon"></div>
        Note: Minimum width depends on motor type.
    </div>
</div>
