<div class="mc-wrapper">
    <div class="mc-blind-wrapper">
        <div class="mc-blind__local">
            <div class="mc-blind-edit"></div>
        </div>
    </div>
    <app-shutter-control></app-shutter-control>
    <app-model-viewer class="mc-block mc-model-viewer"></app-model-viewer>
    <app-tool-bar class="mc-block mc-tool-bar"></app-tool-bar>
</div>

