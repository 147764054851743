import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '@core/services/loader/loader.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from '@shared/modals/error-modal/error-modal.component';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private requests: HttpRequest<any>[] = [];

    constructor(
        private loaderService: LoaderService,
        private localStorage: LocalStorageService,
        public dialog: MatDialog
    ) { }

    removeRequest(req: HttpRequest<any>): void {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        this.loaderService.isLoading.next(this.requests.length > 0);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.requests.push(req);
        console.log('No of requests---> ' + this.requests.length);
        this.loaderService.isLoading.next(true);
        return Observable.create(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => {
                        this.localStorage.removeBlindData('zip-blind-data');
                        this.localStorage.removeBlindData('zip-blind-session-saved');

                        this.dialog.open(ErrorModalComponent, {
                            width: '80%',
                            height: 'calc(100vh - 200px)',
                            data: {}
                        });
                        this.removeRequest(req);
                        observer.error(err);
                    },
                    () => {
                        this.removeRequest(req);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}
