import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Countries } from '../../../app.config';
import streamSaver from 'streamsaver';

@Injectable({
    providedIn: 'root'
})
export class ServerDataService {
    headers = new HttpHeaders().set('Content-Type', 'application/json');
    countryCode;

    constructor(
        private http: HttpClient
    ) {}

    serverData(): any {
        const sessionCountryCode = JSON.parse(sessionStorage.getItem('zip-country-code'));
        const code = !!sessionCountryCode ? sessionCountryCode : !!this.countryCode ? this.countryCode : sessionCountryCode;

        const link = Countries.filter(item => item.code === code)[0]?.data_store;

        return {
            main_link: link,
            rest: {
                config: link + '/3dc/configurations',
                config_test: link + '/3dc/configurations-test',
                retailers: link + '/far/retailers',
                settings: link + '/far/settings',
                outdoor: link + '/far/retailers/product/outdoor',
                access: link + '/far/access',
                sessionData: link + '/3dc/sessions'
            },
            post: {
                stats: link + '/far/statistics/save',
                data: link + '/3dc/save',
                image: link + '/3dc/image/save'
            },
            put: {
                sessionData: link + '/3dc/sessions/save',
                download: link + '/3dc/sessions/save-and-download'
            },
            link: {
                download: '/3dc/sessions/download/pdf'
            }
        };
    }

    GetServerData(sessionKey, code): Observable<any> {
        this.countryCode = code;
        const sessionKeyLink = sessionKey ? '/' + sessionKey : '';

        return this.http.get(this.serverData().rest.config_test + sessionKeyLink)
            .pipe(
                catchError(this.errorMgmt)
            );
    }

    getPdfData(link) {
        const fileStream = streamSaver.createWriteStream('Ziptrak® Blinds – Summary.pdf');
        return fetch(link).then(res => {
            const readableStream = res.body;

            if (window.WritableStream && readableStream.pipeTo) {
                return readableStream.pipeTo(fileStream);
            }
        });
    }

    GetRetailersData(): Observable<any> {
        return this.http.get(`${this.serverData().rest.retailers}`)
            .pipe(
                catchError(this.errorMgmt)
            );
    }

    GetSettingsData(): Observable<any> {
        return this.http.get(`${this.serverData().rest.settings}`)
            .pipe(
                catchError(this.errorMgmt)
            );
    }

    GetAccess(): Observable<any> {
        return this.http.get(`${this.serverData().rest.access}`)
            .pipe(
                catchError(this.errorMgmt)
            );
    }

    PostImageData(data): Observable<any> {
        return this.http.post(`${this.serverData().post.image}`, data)
            .pipe(
                catchError(this.errorMgmt)
            );
    }

    PostStatsData(data): Observable<any> {
        return this.http.post(`${this.serverData().post.stats}`, data)
            .pipe(
                catchError(this.errorMgmt)
            );
    }

    PostData(data, key): Observable<any> {
        return this.http.post(`${this.serverData().post.data}/${key}`, data)
            .pipe(
                catchError(this.errorMgmt)
            );
    }

    PutSessionData(data, key): Observable<any> {
        return this.http.put(`${this.serverData().put.sessionData}/${key}`, data)
            .pipe(
                catchError(this.errorMgmt)
            );
    }

    // Error handling
    errorMgmt(error: HttpErrorResponse): Observable<any> {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
