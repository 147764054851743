import { Component, OnInit, OnDestroy } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-to-top-btn',
  templateUrl: './to-top-btn.component.html',
  styleUrls: ['./to-top-btn.component.scss']
})
export class ToTopBtnComponent implements OnInit, OnDestroy {
  isMobile = (/Mobi|Android/i.test(navigator.userAgent));
  isActive = false;
  scrollPosition = 0;
  

  constructor() { }

  ngOnInit(): void {
    $(window).on('scroll', () => {
      this.scrollPosition = window.scrollY;
      this.isActive = this.scrollPosition > 150;
    });
  }

  ngOnDestroy(): void {
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}
