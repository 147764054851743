import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

declare var $: any;

@Component({
	selector: 'app-retailer-select-modal',
	templateUrl: './retailer-select-modal.component.html',
	styleUrls: ['./retailer-select-modal.component.scss', './retailer-select-modal.responsive.scss']
})
export class RetailerSelectModalComponent implements OnInit {
	constructor(public dialogRef: MatDialogRef<RetailerSelectModalComponent>) {}

	ngOnInit() {
		$('.cdk-overlay-container').addClass('internal');
	}

	ngOnDestroy() {
		$('.cdk-overlay-container').removeClass('internal');
	}

	close(): void {
		this.dialogRef.close();
	}

	delete(): void {
		this.dialogRef.close(true);
	}

}
