import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';

declare var $: any;

@Component({
	selector: 'app-leave-modal',
	templateUrl: './leave-modal.component.html',
	styleUrls: ['./leave-modal.component.scss', './leave-modal.responsive.scss']
})
export class LeaveModalComponent implements OnInit, OnDestroy {

	constructor(
		public dialogRef: MatDialogRef<LeaveModalComponent>,
		private countryCodeService: SetupPageService
	) { }

	ngOnInit() {
		$('.cdk-overlay-container').addClass('internal');
	}

	ngOnDestroy() {
		$('.cdk-overlay-container').removeClass('internal');
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	leave(): void {
		this.countryCodeService.resetOnBeforeUnloadHandler();
		this.countryCodeService.parseLocalLinkHandler();
		this.closeDialog();
	}
}
