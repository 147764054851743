<div class="mc-modal__content">
	<div class="mc-modal__icon"></div>
	<div class="mc-modal__title" [innerHTML]="'modals.success.title' | translate"></div>
	<div class="mc-modal__subtitle">
		{{'modals.success.subtitle_1' | translate}} 
		<ng-container *ngFor="let ratailer of checkedRetailers; let i = index">
			<span class="mc-modal__retailer-name">{{ratailer.name}} </span>
			<span *ngIf="checkedRetailers.length > 1 && i !== checkedRetailers.length - 1">and </span>
		</ng-container>
	</div>
	<div class="mc-modal__subtitle">{{'modals.success.subtitle_2' | translate}}</div>
	<div class="mc-modal__buttons">
		<div class="mc-modal__button mc-modal__button--yellow"
			 (click)="close()">{{'modals.success.buttons.homepage' | translate}}</div>
	</div>
</div>
