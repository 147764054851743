import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

declare var $: any;

@Component({
	selector: 'app-delete-modal',
	templateUrl: './delete-modal.component.html',
	styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent {

	constructor(
		private dialogRef: MatDialogRef<DeleteModalComponent>
	) { }

	ngOnInit() {
		$('.cdk-overlay-container').addClass('internal');
	}

	ngOnDestroy() {
		$('.cdk-overlay-container').removeClass('internal');
	}

	close(): void {
		this.dialogRef.close();
	}

	delete(): void {
		this.dialogRef.close(true);
	}
}
