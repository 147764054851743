import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';

import { Observable, of } from 'rxjs';

@Directive({
    selector: '[appValidateLatin]',
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => LatinValidatorDirective),
            multi: true
        }
    ]
})
export class LatinValidatorDirective implements AsyncValidator {
    @Input() minlength?: string;

    constructor() {
    }

    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        const regex = new RegExp(/^(?![\s'\.,-\/#!$%\^&\*;:{}@?+|=\-_`~()]+$)[A-z0-9\u00C0-\u00ff\s'\.,-\/#!$%\^&\*;:{}@?+|=\-_`~()“”&‘’"]+$/);
        const valid = regex.test(control.value?.trim());

        if (control.value && this.minlength && +this.minlength > control.value?.trim().length) {
            return of({minlength: true});
        }

        if (control.value && !valid) {
            return of({latin: true});
        }

        return of(null);
    }

}
