import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;

@Component({
    selector: 'app-country-modal',
    templateUrl: './country-modal.component.html',
    styleUrls: ['./country-modal.component.scss', './country-modal.responsive.scss']
})
export class CountryModalComponent implements OnInit, OnDestroy {
    @ViewChild('countryContent') countryContent: ElementRef;

    getCountryPopup: Subscription;

    constructor(
        public shareDataService: ShareService,
        public sessionStorageService: SessionStorageService,
        public dialogRef: MatDialogRef<CountryModalComponent>,
        public sanitizer: DomSanitizer,

        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    ngOnInit() {
        this.getCountryPopup = this.shareDataService.getCountryPopup.subscribe(res => {
            this.spinnerHandler(false);

            this.dialogRef.close();
        });
    }

    ngOnDestroy() {
        this.getCountryPopup.unsubscribe();
    }

    setCountrySite(e): void {
        const code = $(e.currentTarget).data();

        this.shareDataService.setCountryLink(code.code);
        this.sessionStorageService.setSession(code.code, 'zip-country-code');

        setTimeout(this.spinnerHandler.bind(this, true));
    }

    spinnerHandler(status): void {
        $(this.countryContent.nativeElement).toggleClass('loading', status);
    }
}
