import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
declare var $: any;

@Component({
  selector: 'app-model-viewer',
  templateUrl: './model-viewer.component.html',
  styleUrls: ['./model-viewer.component.scss', './model-viewer.responsive.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelViewerComponent implements OnInit, OnDestroy {

    @ViewChild('rendererCanvas', { static: true }) public rendererCanvas: ElementRef<HTMLCanvasElement>;
    @ViewChild('cursorMove', { static: true }) public cursorMove: ElementRef<HTMLCanvasElement>;
    @ViewChild('cursorZoomIn', { static: true }) public cursorZoomIn: ElementRef<HTMLCanvasElement>;
    @ViewChild('cursorZoomOut', { static: true }) public cursorZoomOut: ElementRef<HTMLCanvasElement>;
    @ViewChild('modelContainer', { static: true }) public modelContainer: ElementRef<HTMLCanvasElement>;

    getCursorPointer: Subscription;
    getCursorGrab: Subscription;
    getModelLoaded: Subscription;
    getCurrentData: Subscription;
    getActionBackPage: Subscription;
    cursorCollapsed = false;

    constructor(
        private engineService: EngineService,
        private shareServiceData: ShareService,
        private changeDetection: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.engineService.init(this.rendererCanvas);

        this.changeHintsText();

        this.getCursorPointer = this.shareServiceData.getCursorPointer.subscribe(this.cursorPoinerHandler.bind(this));

        this.getCurrentData = this.shareServiceData.currentData.subscribe(res => {
            $(this.modelContainer.nativeElement).addClass('loader');
        });

        this.getModelLoaded = this.shareServiceData.getModelLoaded.subscribe(res => {
            if (res) {
                setTimeout(() => {
                    $(this.modelContainer.nativeElement).removeClass('loader');
                }, 500);
            }
        });

        this.getActionBackPage = this.shareServiceData.getActionBackPage.subscribe(() => {
            $('.mc-cursor__container').remove();
            this.cursorCollapsed = true;
        });

        this.engineService.animate();
        this.hideCursorOnGrab();
    }

    ngOnDestroy(): void {
        this.getCursorPointer.unsubscribe();
        this.getCursorGrab.unsubscribe();
        this.getModelLoaded.unsubscribe();
        this.getCurrentData.unsubscribe();
        this.getActionBackPage.unsubscribe();
    }

    cursorPoinerHandler(res): void {
        if (!this.cursorCollapsed) {
            res.cursor = res.cursor.split(', ').splice(-1, 1).join('');

            if (res.cursor === 'grabbing' && res.clicked && !$(this.cursorZoomOut.nativeElement).hasClass('active')) {
                $(this.cursorMove.nativeElement).fadeOut().remove();
                $(this.cursorZoomIn.nativeElement).fadeIn().addClass('active');
            }
            
            if (res.zoomIn) {
                $(this.cursorMove.nativeElement).remove();
                $(this.cursorZoomIn.nativeElement).remove();
                $(this.cursorZoomOut.nativeElement).fadeIn().addClass('active');
            }

            if (res.zoomOut && $(this.cursorZoomOut.nativeElement).hasClass('active')) {
                $(this.cursorZoomOut.nativeElement).fadeOut().remove();
                this.cursorCollapsed = true;
            }
        }

        this.changeDetection.markForCheck();
    }

    hideCursorOnGrab(): void {
        this.getCursorGrab = this.shareServiceData.getCursorGrab.subscribe(res => {
            if (res.cursor === 'default' || !res.clicked) {
                return;
            }

            $(this.cursorMove.nativeElement).hide();
            $(this.cursorZoomIn.nativeElement).hide();
            $(this.cursorZoomOut.nativeElement).hide();

            this.changeDetection.markForCheck();
        });
    }

    changeHintsText(): void {
        if (this.hasTouch()) {
            $(this.cursorZoomIn.nativeElement).addClass('mobile')
            $(this.cursorMove.nativeElement).addClass('mobile');
            $(this.cursorZoomOut.nativeElement).addClass('mobile');
            $(this.cursorMove.nativeElement).children().hide();
            $(this.cursorZoomIn.nativeElement).children().hide();
            $(this.cursorZoomOut.nativeElement).children().hide();
        }
    }

    hasTouch() {
        return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
    }
}
