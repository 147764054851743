import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-blind-item-description',
    templateUrl: './blind-item-description.component.html',
    styleUrls: ['./blind-item-description.component.scss']
})
export class BlindItemDescriptionComponent implements OnInit {
    @Input() blindItem;
    @Input() blindItemType;
    @Input() blindType;

    constructor() { }

    ngOnInit(): void { }
}
