import { ChangeDetectorRef,
    Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { DeleteModalComponent } from '@shared/modals/delete-modal/delete-modal.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Config, BREAKPOINTS } from '@root/app.config';
import { Breakpoints } from '@root/app.interfaces';
import { MatDialog } from '@angular/material/dialog';

declare var $: any;
import * as _ from 'lodash';

@Component({
    selector: 'app-summary-table',
    templateUrl: './summary-table.component.html',
    styleUrls: ['./summary-table.component.scss', './summary-table.responsive.scss',
    '../overview-page/overview-page.component.scss', '../overview-page/overview-page.responsive.scss'],
    encapsulation: ViewEncapsulation.None
})

export class SummaryTableComponent implements OnInit, OnDestroy {
    setServerBlindData: Subscription;
    getSessionBLindData: Subscription;
    getActivatedRouteRules: Subscription;

    blindsData = [];
    filteredBlindsData = [];
    blindOptionAvailability = {
        frame_color: false,
        frame_style: false,
        frame_bar: false,
        material: false,
        size: false,
        operation: false,
        mounting: false
    };
    hasBlindsData = false;
    downloading: boolean = false;
    saving: boolean = false;
    type: string = 'all';
    types: string[];
    breakpoints: Breakpoints = BREAKPOINTS;

    slideConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        infinite: false,
        variableWidth: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: Config.breakpoints['tablet-landspace'],
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2
                }
            }
        ]
    };

    storageText = this.localStorageService.getBlindData('zip-blind-text');
    localText = _.isEmpty(this.storageText) ? 'Some text' : this.localStorageService.getBlindData('zip-blind-text');

    constructor(
        private localStorageService: LocalStorageService,
        private serverDataService: ServerDataService,
        private sessionStorageService: SessionStorageService,
        private saveSessionBlind: SaveSessionBlindsService,
        private countryCodeService: SetupPageService,
        private engineService: EngineService,
        private route: ActivatedRoute,
        private router: Router,
        private shareService: ShareService,
        private changeDetection: ChangeDetectorRef,
        private matDialog: MatDialog
    ) { }

    emailBodyHandler(sessionKey): void {
        const getCountryCode = this.countryCodeService.getCountryCode();

        const emailSpace = '%0D%0A%0D%0A';
        const emailAmpersand = '%26';
        const subject = 'Ziptrak® | Design Your Blind';
        const emailUpText = 'Check out my Ziptrak® blind design!';
        const emailDownText = 'This link gives you access to view and modify the design at any time';
        const countryCodeQuery = `${emailAmpersand}country=${getCountryCode}`;
        const emailLink = emailSpace + window.location.href + '?=' + sessionKey + countryCodeQuery + emailSpace;
        const emailBody = `Hi,${emailSpace}${emailUpText}${emailLink}${emailDownText}.`;

        window.location.href = `mailto:?subject=${subject}&body=${emailBody}`;
    }

    ngOnInit(): void {
        this.getActivatedRouteRules = this.route.data.subscribe(res => {
            this.getLocalStorageData();
        });

        if (!this.hasBlindsData) {
            this.getSessionBLindData = this.shareService.getSessionBlindsData.subscribe(() => {
                this.getLocalDefaultValue();
                this.getLocalStorageData();
            });
        }
    }

    ngOnDestroy(): void {
        if (this.setServerBlindData) {
            this.setServerBlindData.unsubscribe();
        }
        if (this.getSessionBLindData) {
            this.getSessionBLindData.unsubscribe();
        }
        if (this.getActivatedRouteRules) {
            this.getActivatedRouteRules.unsubscribe();
        }
    }

    getLocalStorageData(): void {
        const storageData = this.localStorageService.getBlindData('zip-blind-data');

        if (!_.isEmpty(storageData)) {
            this.hasBlindsData = true;
            this.blindsData = [];
            for (const blind of storageData) {
                if (blind.has_name) {
                    this.blindsData.push(blind);
                }
            }

            this.getBlindsTypes();
            this.checkOptionsAvailability();
        }

        this.changeDetection.markForCheck();
    }

    setBlindsTableData(): void {
        this.shareService.setRetailerInfo(true);
    }

    checkOptionsAvailability(): void {
        const frameColor = 'frame_color';
        const frameStyle = 'frame_style';
        const frameBar = 'frame_bar';
        const material = 'material';
        const size = 'size';
        const operation = 'operation';
        const mounting = 'mounting';

        for (const option of this.blindsData) {
            if (!_.isEmpty(option.setup.frames)) {
                this.blindOptionAvailability[frameColor] =
                    !this.blindOptionAvailability[frameColor] ?
                        !_.isEmpty(option.setup.frames.frame_color) : this.blindOptionAvailability[frameColor];
                this.blindOptionAvailability[frameStyle] =
                    !this.blindOptionAvailability[frameStyle] ?
                        !_.isEmpty(option.setup.frames.top_style) : this.blindOptionAvailability[frameStyle];
                this.blindOptionAvailability[frameBar] =
                    !this.blindOptionAvailability[frameBar] ?
                        !_.isEmpty(option.setup.frames.bottom_bar) : this.blindOptionAvailability[frameBar];
            }

            this.blindOptionAvailability[material] =
                !this.blindOptionAvailability[material] ?
                    !_.isEmpty(option.setup.material) : this.blindOptionAvailability[material];
            this.blindOptionAvailability[size] =
                !this.blindOptionAvailability[size] ?
                    !_.isEmpty(option.setup.size) : this.blindOptionAvailability[size];
            this.blindOptionAvailability[operation] =
                !this.blindOptionAvailability[operation] ?
                    !_.isEmpty(option.setup.operation) : this.blindOptionAvailability[operation];
            this.blindOptionAvailability[mounting] =
                !this.blindOptionAvailability[mounting] ?
                    !_.isEmpty(option.setup.mounting) : this.blindOptionAvailability[mounting];
        }
    }

    getCountryCode(): any {
        return this.sessionStorageService.getSession('zip-country-code');
    }

    putDownloadData(): void {
        const getStorageData = this.localStorageService.getBlindData('zip-blind-data');
        const storageDataWithAction = {
            action: 'pdf',
            country: this.getCountryCode(),
            blinds: getStorageData
        };

        this.downloading = true;

        this.setServerBlindData = this.serverDataService.PutSessionData(
            storageDataWithAction, this.setupSessionKeyForSavingHandler()).subscribe(() => {
            this.localStorageSessionKeyHelper();

            const link =
                this.serverDataService.serverData().main_link +
                this.serverDataService.serverData().link.download + '/' +
                this.setupSessionKeyForSavingHandler();

            if (navigator.vendor === 'Apple Computer, Inc.') {
                this.serverDataService.getPdfData(link).then(() => this.downloading = false);
            } else {
                window.open(link, '_blank');
                this.downloading = false;
            }

            this.changeDetection.markForCheck();
        });

    }

    putSaveBlinds(): void {
        const getStorageData = this.localStorageService.getBlindData('zip-blind-data');
        const storageDataWithAction = {
            action: 'email',
            country: this.getCountryCode(),
            blinds: getStorageData
        };

        this.saving = true;

        this.setServerBlindData = this.serverDataService.PutSessionData(
            storageDataWithAction, this.setupSessionKeyForSavingHandler()).subscribe(() => {
                this.localStorageService.setBlindData(true, 'zip-blind-progress-saved');

                this.countryCodeService.resetOnBeforeUnloadHandler();
                this.emailBodyHandler(this.setupSessionKeyForSavingHandler());

                this.localStorageSessionKeyHelper();

                this.saving = false;
                this.changeDetection.markForCheck();
        });
    }

    localStorageSessionKeyHelper(): void {
        this.localStorageService.setBlindData(this.setupSessionKeyForSavingHandler(), 'zip-blind-session');
        this.shareService.setLinkSessionKey(this.setupSessionKeyForSavingHandler());

        this.localStorageService.setBlindData(true, 'zip-blind-session-saved');
        this.localStorageService.removeBlindData('zip-blind-temp-session');
    }

    getLocalDefaultValue(): void {
        const localStorageText = this.localStorageService.getBlindData('zip-blind-text');

        if (!_.isEmpty(localStorageText)) {
            this.localText = localStorageText;
        }
    }

    setupSessionKeyForSavingHandler(): any {
        const hasSavedSession = this.localStorageService.getBlindData('zip-blind-session-saved');
        const tempSessionKey = this.localStorageService.getBlindData('zip-blind-temp-session');
        const sessionKey = this.localStorageService.getBlindData('zip-blind-session');

        return hasSavedSession ? sessionKey : tempSessionKey;
    }

    onSelectFilter(type: string): void {
        this.type = type;

        this.filteredBlindsData = this.blindsData.filter(blind => blind.type === this.type || this.type === 'all');

    }

    getBlindsTypes(): void {
        this.types = [...new Set(this.blindsData.map(blind => blind.type))];

        this.onSelectFilter(this.types.includes(this.type) ? this.type : 'all');
    }

    trackByIndex(index: number): number {
        return index;
    }

    onDeleteBlind(id: number): void {
        const dialogRef = this.matDialog.open(DeleteModalComponent, {
            width: '100%',
            maxWidth: '680px',
            height: 'auto',
            minHeight: '200p',
            panelClass: 'mc-modal__internal'
        });

        dialogRef.afterClosed().subscribe(status => {
            if (status) {
                const sessionSaved = this.localStorageService.getBlindData('zip-blind-session-saved');
                this.getLocalStorageData();

                const deletedBlindIndex = this.blindsData.findIndex(x => x.blind_id === id);
                if (deletedBlindIndex < this.blindsData.length - 1) {
                    this.blindsData.forEach((x, i) => x.name = i > deletedBlindIndex && x.name === `Blind ${i + 1}` ? `Blind ${i}` : x.name);
                }

                this.blindsData.splice(deletedBlindIndex, 1);
                this.localStorageService.setBlindData(this.blindsData, 'zip-blind-data');
                this.getBlindsTypes();

                if (sessionSaved) {
                    this.saveSessionBlind.PutStorageDataToServer();
                }

                if (_.isEmpty(this.blindsData)) {
                    window.scrollTo(0, 0);
                    this.router.navigate(['/']);
                    this.countryCodeService.sendBlindTypeForRender(true);
                    this.shareService.setBlindEmpty(true);
                }
            }
        });
    }

    onEditBlind(blind: any): void {
        this.localStorageService.setBlindData(blind.blind_id, 'zip-current-blind-id');
        this.sessionStorageService.setSession(blind.type, 'zip-blind-type');
        this.router.navigateByUrl('home');
    }

}
