import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplacePipe } from '@core/pipes/replace/replace.pipe';
import { HexToRgbPipe } from './hex-to-rgb/hex-to-rgb.pipe';

@NgModule({
    declarations: [ ReplacePipe, HexToRgbPipe ],
    imports: [ CommonModule ],
    exports: [ ReplacePipe, HexToRgbPipe ]
})
export class PipesModule { }
