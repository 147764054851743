<div *ngIf="data" class="mc-blind-title">{{'modals.blind_type.title' | translate}}</div>
<div class="mc-blind-modal-container" [class.internal]="!data">
    <div class="mc-blind-modal__title">{{'modals.blind_type.subtitle' | translate}}</div>
    <div #blindTypeContent class="mc-blind-modal">
        <button class="mc-blind-modal-button outdoor"
                [class.internal]="!data"
                [disabled]="clicked"
                (click)="onBlindTypeHandler('outdoor')">
            {{'modals.blind_type.buttons.outdoor' | translate}}
        </button>
        <button class="mc-blind-modal-button interior"
                [class.internal]="!data"
                [disabled]="clicked"
                (click)="onBlindTypeHandler('interior')">
            {{'modals.blind_type.buttons.interior' | translate}}
        </button>
    </div>
    <div class="mc-blind-modal-close" *ngIf="!data" (click)="onClose()"></div>
</div>
