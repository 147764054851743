import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ShareService } from '@core/services/share-data/share-data.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { OverviewLinkHandlerService } from '@core/services/overview-link-handler/overview-link-handler.service';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { Subscription } from 'rxjs';

import * as _ from 'lodash';


@Component({
    selector: 'app-add-blind',
    templateUrl: './add-blind.component.html',
    styleUrls: ['./add-blind.component.scss', './add-blind.responsive.scss']
})
export class AddBlindComponent implements OnInit, OnDestroy {
    getBlindName: Subscription;

    data = [];
    blindId;
    setBlindInfoStatus = false;
    isBlindTypeClicked: string;
    isModelLoaded: string;
    overviewStatus: boolean;
    getBlindInfoStatus: Subscription;
    getBlindEmpty: Subscription;
    getModelLoaded: Subscription;
    getBlindNameStatus: Subscription;
    getBlindStatus: Subscription;
    getSceneCreated: Subscription;
    getBlindType: Subscription;

    constructor(
        private shareDataService: ShareService,
        private localStorageService: LocalStorageService,
        private screenShotService: ScreenshotService,
        private overviewLinkHandlerService: OverviewLinkHandlerService,
        private setupPageService: SetupPageService,
        private changeDetection: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        const blindDataStorage = this.localStorageService.getBlindData('zip-blind-data');
        this.getModelLoaded = this.shareDataService.getModelLoaded.subscribe(res => {
            this.setBlindInfoStatus = res;
            this.changeDetection.markForCheck();
        });
        this.getBlindInfoStatus = this.shareDataService.getStatus.subscribe(res => {
            this.setBlindInfoStatus = res;
            this.changeDetection.markForCheck();
        });
        this.getBlindStatus = this.shareDataService.getEmptyBlindData.subscribe(res => {
            this.overviewStatus = res;
            this.changeDetection.markForCheck();
        });
        this.getSceneCreated = this.shareDataService.getSceneCreated.subscribe(res => {
            this.isModelLoaded = res;
        });
        this.getBlindType = this.shareDataService.getBlindType.subscribe(res => {
            this.onSelectOption(res);
        });

        this.overviewStatus = _.isEmpty(blindDataStorage);
        this.getBlindName = this.shareDataService.getBlindName.subscribe(this.hasBlinds.bind(this));
        this.getBlindEmpty = this.shareDataService.getBlindEmpty.subscribe(this.hasBlinds.bind(this));
        this.getBlindNameStatus = this.shareDataService.getStatus.subscribe(this.blindStatusHandler.bind(this));
    }

    ngOnDestroy(): void {
        this.getModelLoaded.unsubscribe();
        this.getBlindInfoStatus.unsubscribe();
        this.getBlindName.unsubscribe();
        this.getBlindEmpty.unsubscribe();
        this.getBlindNameStatus.unsubscribe();
        this.getBlindStatus.unsubscribe();
        this.getBlindType.unsubscribe();
        this.getSceneCreated.unsubscribe();
    }

    hasBlinds(isBlindEmpty): void {
        this.overviewStatus = _.isBoolean(isBlindEmpty) && isBlindEmpty;

        if (this.overviewStatus) {
            this.setupPageService.sendBlindTypeForRender(true);
        }

        this.changeDetection.markForCheck();
    }

    blindStatusHandler(status): void {
        this.overviewStatus = !status;
        this.changeDetection.markForCheck();
    }

    onAddBlindHandler(): void {
        this.saveCurrentBlindScrennshot();
        this.setupPageService.sendBlindTypeForRender(false);
    }

    onSelectOption(blindType: string): void {
        const hasLocalData = this.localStorageService.getBlindData('zip-blind-data');
        const defaultBlindName = blindType === 'outdoor' ? 'Ziptrak® Outdoor Blind' : 'Ziptrak® Interior Blind';

        this.blindId = Math.floor(1000 + Math.random() * 9000);

        const defaultBlindTemp = [{
            default_name: defaultBlindName,
            blind_id: this.blindId,
            type: blindType
        }];

        if (_.isEmpty(hasLocalData)) {
            this.data = defaultBlindTemp;
        } else {
            this.data = hasLocalData.concat(defaultBlindTemp);
        }

        this.localStorageService.setBlindData(this.data, 'zip-blind-data');
        this.localStorageService.setBlindData(this.blindId, 'zip-current-blind-id');
        this.shareDataService.changeData(this.data);
        this.shareDataService.setBlindSelect({id: this.blindId, status: 'new'});
    }

    overviewLinkHandler(): void {
       this.overviewLinkHandlerService.link();
    }

    saveCurrentBlindScrennshot(): void {
        const currentBlindId = this.localStorageService.getBlindData('zip-current-blind-id');
        const hasLocalData = this.localStorageService.getBlindData('zip-blind-data');

        if (!_.isEmpty(hasLocalData)) {
            this.screenShotService.saveScreenShot(currentBlindId);
        }
    }
}
