import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

declare var $:any;

@Component({
	selector: 'app-retailer-no-select-modal',
	templateUrl: './retailer-no-select-modal.component.html',
	styleUrls: ['./retailer-no-select-modal.component.scss', './retailer-no-select-modal.responsive.scss']
})
export class RetailerNoSelectModalComponent implements OnInit {

	constructor(public dialogRef: MatDialogRef<RetailerNoSelectModalComponent>) {}

	ngOnInit() {
		$('.cdk-overlay-container').addClass('internal');
	}

	ngOnDestroy() {
		$('.cdk-overlay-container').removeClass('internal');
	}

	close(): void {
		this.dialogRef.close();
	}

	delete(): void {
		this.dialogRef.close(true);
	}

}
