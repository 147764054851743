import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ShareService } from '@core/services/share-data/share-data.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { OverviewLinkHandlerService } from '@core/services/overview-link-handler/overview-link-handler.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LeaveModalComponent } from '@shared/modals/leave-modal/leave-modal.component';
import { Config } from 'src/app/app.config';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { Location } from '@angular/common';

declare var $: any;
import * as _ from 'lodash';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss', './header.responsive.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    @ViewChild('wrapper') wrapper: ElementRef;
    @ViewChild('navMenu') navMenu: ElementRef;

    getBlindName: Subscription;
    getSessionBlindsData: Subscription;
    getBlindNameStatus: Subscription;
    getBlindStatus: Subscription;
    getBlindEmpty: Subscription;
    getBlindType: Subscription;
    getActionBackPage: Subscription;
    isModalOpened = false;
    isBlindTypeSelected: boolean;
    overviewStatus: boolean;

    constructor(
        private location: Location,
        private shareDataService: ShareService,
        private localStorageService: LocalStorageService,
        private changeDetection: ChangeDetectorRef,
        private overviewLinkHandlerService: OverviewLinkHandlerService,
        private countryCodeService: SetupPageService,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        const blindDataStorage = this.localStorageService.getBlindData('zip-blind-data');
        this.overviewStatus = _.isEmpty(blindDataStorage);

        this.getSessionBlindsData = this.shareDataService.getSessionBlindsData.subscribe(this.sessionBlindsHandler.bind(this));
        this.getBlindName = this.shareDataService.getBlindName.subscribe(this.hasBlinds.bind(this));
        this.getBlindNameStatus = this.shareDataService.getStatus.subscribe(this.blindStatusHandler.bind(this));
        this.getBlindEmpty = this.shareDataService.getBlindEmpty.subscribe(this.emptyBlindHandler.bind(this));

        this.getBlindType = this.shareDataService.getBlindType.subscribe(res => this.isBlindTypeSelected = !!res);
        this.getActionBackPage = this.shareDataService.getActionBackPage.subscribe(res => {
            this.activeLinkHandler();
            this.setMainPageSize(res);
        });
        this.checkExit();
    }

    ngOnDestroy(): void {
        this.getBlindName.unsubscribe();
        this.getSessionBlindsData.unsubscribe();
        this.getBlindStatus.unsubscribe();
        this.getBlindEmpty.unsubscribe();
        this.getActionBackPage.unsubscribe();
        this.getBlindType.unsubscribe();
    }

    hasBlinds(isBlindEmpty): void {
        this.overviewStatus = isBlindEmpty === true;
        this.changeDetection.markForCheck();
    }

    blindStatusHandler(status): void {
        this.overviewStatus = !status;
        this.changeDetection.markForCheck();
    }

    sessionBlindsHandler(status): void {
        const currentDomainLink = this.location.path().split('?');
        const currentLink = currentDomainLink[0].split('/')[1] || 'home';

        if (status && currentLink === 'overview') {
            setTimeout(this.overviewLinkHandlerService.addActiveClass.bind(this));
        }

        this.overviewStatus = !status;
        this.isBlindTypeSelected = status;
        this.changeDetection.markForCheck();
    }

    emptyBlindHandler(status): void {
        if (!status) {
            this.activeLinkHandler();
        }

        this.overviewStatus = status;
        this.isBlindTypeSelected = !status;
        this.changeDetection.markForCheck();
    }

    activeLinkHandler(): void {
        $(this.navMenu?.nativeElement).find('li').last().removeClass('active');
    }

    setMainPageSize(res): void {
        const mainPageWrapper = $('.mc-wrapper');

        if (res && window.innerWidth > Config.breakpoints['l-desktop']) {
            const toolBarControls = $('.mc-tool-bar__controls');
            const header = $(this.wrapper.nativeElement).parent();

            setTimeout(() => {
                mainPageWrapper.height(`calc(100vh - ${header.height()}px)`);
                toolBarControls.height(`calc(100vh - ${header.height() + 40}px)`);
                window.dispatchEvent(new Event('resize'));
            }, 400);
        }

        this.changeDetection.markForCheck();
    }

    overviewLinkHandler(status: boolean): void {
        if (!status) {
            this.overviewLinkHandlerService.link();
        }
    }

    openModal(e): void {
        e.preventDefault();
        // const isProgressSaved = this.getProgressSaved();
        // let dialogRef;

        // if (isProgressSaved) {
        //     dialogRef = this.dialog.open(LeaveModalComponent, {
        //         width: '711px',
        //         maxWidth: '100vw',
        //         panelClass: 'mc-modal__internal'
        //     });
        //     this.isModalOpened = true;
        // } else {
        //     this.countryCodeService.parseLocalLinkHandler();
        // }

        // if (dialogRef) {
        //     dialogRef.afterClosed().subscribe(() => {
        //         this.isModalOpened = false;
        //     });
        // }
    }

    checkExit(): void {
        // window.onbeforeunload = (e) => {
        //     const isProgressSaved = this.getProgressSaved();
        //     let status = false;

        //     if (isProgressSaved && !this.isModalOpened) {
        //         e.returnValue = true;
        //         status = true;
        //     }

        //     return status;
        // };
    }

    getProgressSaved() {
        const storage = this.localStorageService.getBlindData('zip-blind-data');
        const isProgressSaved = this.localStorageService.getBlindData('zip-blind-progress-saved');
        let status = false;

        if (!_.isEmpty(storage) && !isProgressSaved) {
            status = true;
        }

        return status;
    }
}
