import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';

import * as _ from 'lodash';
declare var $: any;

@Component({
	selector: 'app-retailer-success-modal',
	templateUrl: './retailer-success-modal.component.html',
	styleUrls: ['./retailer-success-modal.component.scss', 'retailer-success-modal.responsive.scss']
})
export class RetailerSuccessModalComponent implements OnInit, OnDestroy {

	checkedRetailers = [];

	constructor(
		public dialogRef: MatDialogRef<RetailerSuccessModalComponent>,
		private countryCodeService: SetupPageService,

		@Inject(MAT_DIALOG_DATA) public data: any
	) {}
	hasData = !_.isEmpty(this.data);
	getDialogClosed: Subscription;

	ngOnInit(): void {
		$('.cdk-overlay-container').addClass('internal');
		this.getCheckedRetailers();

		this.getDialogClosed = this.dialogRef.beforeClosed().subscribe(res => {
			this.countryCodeService.resetOnBeforeUnloadHandler();
			window.location.reload();
		});
	}

	ngOnDestroy(): void {
		$('.cdk-overlay-container').removeClass('internal');
		this.getDialogClosed.unsubscribe();
	}

	close(): void {
		this.countryCodeService.parseLocalLinkHandler();
	}

	getCheckedRetailers(): void {
		if (!_.isEmpty(this.data)) {
			for (const item of this.data.checkedRetailersID) {
				const retailerNonPremium = this.data.nonPremiumMarkers.find(retailer => retailer.id === item);
				const retailerPremium = this.data.premiumMarkers.find(retailer => retailer.id === item);

				if (retailerNonPremium !== undefined) {
					this.checkedRetailers.push({
						name: retailerNonPremium.name,
						website: retailerNonPremium.website
					});
				}

				if (retailerPremium !== undefined) {
					this.checkedRetailers.push({
						name: retailerPremium.name,
						website: retailerPremium.website
					});
				}
			}
		}
	}

}
