<div class="mc-blind-container">
    <div class="mc-blind-list" *ngIf="blindsData">
        <div class="mc-blind-item"
             *ngFor="let blind of blindsData; let i = index"
             data-id="{{blind.blind_id}}" [class]="blind.type">
            <div class="mc-blind-item__header" (click)="accordionBlindItems($event, blind.blind_id, blind.type)">
                <div class="mc-blind-item__index">{{i + 1}}</div>
                <div class="mc-blind-item__name">
                    {{blind.default_name}}
                    <span *ngIf="blind.name">: <br />{{blind.name}}</span>
                </div>
                <span class="mc-blind-item__setup-delete"
                          placement="left"
                          #deletePopover="ngbPopover"
                          container="body"
                          (click)="$event.stopPropagation()"
                          [ngClass]="deletePopover.isOpen() ? 'active' : ''"
                          [ngbPopover]="deleteBlindContent"
                          popoverClass="mc-popover mc-popover-delete">Delete</span>
                <div class="mc-blind-item__arrow">Arrow</div>
            </div>

            <div class="mc-blind-item__setup-container hidden" *ngIf="blind.type === 'outdoor'">
                <div class="mc-blind-item__setup-content mc-config">
                    <app-outdoor-list [index]="i" [blindsListComponent]="this"></app-outdoor-list>
                </div>
            </div>

            <div class="mc-blind-item__setup-container hidden" *ngIf="blind.type === 'interior'">
                <div class="mc-blind-item__setup-content mc-config">
                    <app-interior-list [index]="i" [blindsListComponent]="this"></app-interior-list>
                </div>
            </div>

            <ng-template #deleteBlindContent>
                <div class="mc-blind-item__delete-title">{{'modals.delete_blind.title' | translate}}</div>
                <button class="mc-blind-item__button yes" (click)="deleteBlindItem(blind.blind_id)">
                    {{'modals.delete_blind.buttons.yes' | translate}}
                </button>
                <button class="mc-blind-item__button no" (click)="[deletePopover.close(), $event.stopPropagation()]">
                    {{'modals.delete_blind.buttons.no' | translate}}
                </button>
            </ng-template>
        </div>
    </div>

    <app-add-blind></app-add-blind>
</div>
