import { Component, OnInit, OnDestroy } from '@angular/core';


@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss', './main-page.responsive.scss']
})
export class MainPageComponent implements OnInit, OnDestroy {

  constructor() { }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
