<div class="mc-modal__content retailer-select">
  <div class="mc-modal__close" (click)="close()"></div>
  <div class="mc-modal__icon"></div>
  <div class="mc-modal__title">{{'modals.retailer_select.title' | translate}}</div>
  <div class="mc-modal__subtitle">{{'modals.retailer_select.subtitle' | translate}}</div>
  <div class="mc-modal__buttons">
      <div class="mc-modal__button mc-modal__button--yellow"
           (click)="close()">{{'modals.retailer_select.buttons.try_again' | translate}}</div>
  </div>
</div>
