<div #modelContainer class="mc-model-viwer-wrapper">
  <canvas #rendererCanvas id="renderCanvas"></canvas>
  <div #cursorMove class="mc-cursor__container mc-cursor__move">
    <div class="mc-cursor__text">Click & hold to rotate</div>
  </div>
  <div #cursorZoomIn class="mc-cursor__container mc-cursor__zoom-in">
    <div class="mc-cursor__text">Move mouse wheel up to zoom in</div>
  </div>
  <div #cursorZoomOut class="mc-cursor__container mc-cursor__zoom-out">
    <div class="mc-cursor__text">Move mouse wheel down to zoom out</div>
  </div>
</div>
