<div class="error-modal-container">
    <div class="error-modal-wrapper-logo">
        <div class="error-modal-logo"></div>
    </div>
    <div class="error-modal-header">
        Oops, something went wrong.
    </div>
    <div class="error-modal-description">
        Sorry, we can't find the page you were looking for —
        <a href="mailto:contact-a-retailer@ziptrak.com.au">let us know what happened</a>
        and we'll do our best to help you out!
    </div>
    <a href="" class="error-modal-button" (click)="closeDialog()">
        Return to Homepage
    </a>
</div>
