import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    ChangeDetectorRef, AfterViewInit
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import 'simplebar';
declare var $: any;

@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.scss', './tool-bar.responsive.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolBarComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('toolBarContainer', { read: ElementRef }) public toolBarContainer: ElementRef<any>;
    getBlindPosition: Subscription;
    getLoadingSpinner: Subscription;

    status = true;

    constructor(
        private shareDataService: ShareService,
        private localStorageService: LocalStorageService,
        private changeDetection: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.getBlindPosition = this.shareDataService.getBlindPosition.subscribe(res => {
            setTimeout(() => {
                $(this.toolBarContainer.nativeElement).parents('.simplebar-content-wrapper').animate(
                    { scrollTop: res }, 500
                );
            });
            this.changeDetection.markForCheck();
        });

        this.setLoadingHandler();
    }

    ngAfterViewInit() {
        this.getLoadingSpinner = this.shareDataService.getLoadingSpinner.subscribe(res => {

            setTimeout(() => {
                $(this.toolBarContainer.nativeElement).toggleClass('loading', res);
            });

            this.changeDetection.markForCheck();
        });
    }

    setLoadingHandler(): void {
        const savedStatus = this.localStorageService.getBlindData('zip-blind-session-saved');

        if (savedStatus) {
            setTimeout(() => {
                $(this.toolBarContainer.nativeElement).addClass('loading');
            });
        }
    }

    ngOnDestroy(): void {
        this.getBlindPosition.unsubscribe();
    }
}
