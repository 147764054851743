import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  setModelSize(data, storage): void {
    sessionStorage.setItem(storage, JSON.stringify(data));
  }

  setSession(data, storage): void {
      sessionStorage.setItem(storage, JSON.stringify(data));
  }

  getSession(storage) {
    return JSON.parse(sessionStorage.getItem(storage));
  }

  getModelSize(storage) {
    return JSON.parse(sessionStorage.getItem(storage));
  }

  removeData(storage): void {
    sessionStorage.removeItem(storage);
  }
}
