<div class="mc-retailer">
    <div class="mc-retailer__block add">
        <span class="mc-retailer__block-label"><span></span>Step 01</span>
        <div class="mc-retailer__block-container">
            <div class="mc-retailer__block-header">
                <div class="mc-retailer__block-title">{{'retailer_action.blocks.add.title' | translate}}</div>
                <div class="mc-retailer__block-text">{{'retailer_action.blocks.add.text' | translate}}</div>
            </div>
            <div class="mc-retailer__form">
                <form (ngSubmit)="onSubmit()" [formGroup]="findRetailer">
                    <div class="mc-retailer__form-section">
                        <label class="mc-retailer__form-section-label" for="firstName">
                            {{'retailer_action.inputs.first_name.title' | translate}}
                        </label>
                        <div class="mc-retailer__form-section-wrapper full">
                            <input [ngClass]="{'mc-retailer__form-section-input--error': submitted && f.first_name.errors}"
                                   [placeholder]="'retailer_action.inputs.first_name.placeholder' | translate"
                                   appValidateLatin
                                   class="mc-retailer__form-section-input"
                                   formControlName="first_name"
                                   id="firstName" minlength="2"
                                   required type="text">

                            <div *ngIf="submitted && f.first_name.errors && f.first_name.errors['minlength']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.first_name.errors.length' | translate}}
                            </div>
                            <div *ngIf="submitted && f.first_name.errors && f.first_name.errors['required']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.first_name.errors.required' | translate}}
                            </div>
                            <div *ngIf="f.first_name.errors && f.first_name.errors['latin']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.first_name.errors.wrongFormat' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="mc-retailer__form-section">
                        <label class="mc-retailer__form-section-label" for="lastName">
                            {{'retailer_action.inputs.last_name.title' | translate}}
                        </label>
                        <div class="mc-retailer__form-section-wrapper full">
                            <input [ngClass]="{'mc-retailer__form-section-input--error': submitted && f.last_name.errors}"
                                   [placeholder]="'retailer_action.inputs.last_name.placeholder' | translate"
                                   appValidateLatin
                                   class="mc-retailer__form-section-input"
                                   formControlName="last_name"
                                   id="lastName" minlength="2"
                                   required type="text">

                            <div *ngIf="submitted && f.last_name.errors && f.last_name.errors['minlength']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.last_name.errors.length' | translate}}
                            </div>
                            <div *ngIf="submitted && f.last_name.errors && f.last_name.errors['required']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.last_name.errors.required' | translate}}
                            </div>
                            <div *ngIf="f.last_name.errors && f.last_name.errors['latin']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.last_name.errors.wrongFormat' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="mc-retailer__form-section">
                        <span class="mc-retailer__form-section-label">
                            <label for="postCode">{{'retailer_action.inputs.postcode.title' | translate}}</label>
                            <span class="mc-help-icon"
                                  [ngbPopover]="'retailer_action.inputs.postcode.tooltip' | translate"
                                  triggers="click"
                                  container="body"
                                  placement="top-left auto"
                                  popoverClass="mc-popover mc-popover-info"></span>
                        </span>
                        <div class="mc-retailer__form-section-wrapper postcode">
                            <input #postCode
                                   [ngClass]="{'mc-retailer__form-section-input--error': submitted && f.post_code.errors}"
                                   [placeholder]="'retailer_action.inputs.postcode.placeholder' | translate"
                                   class="mc-retailer__form-section-input"
                                   formControlName="post_code" id="postCode"
                                   required type="text">

                            <div *ngIf="submitted && f.post_code.errors && f.post_code.errors['required']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.postcode.errors.required' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="mc-retailer__form-section">
                        <label class="mc-retailer__form-section-label" for="phone">
                            {{'retailer_action.inputs.phone.title' | translate}}
                        </label>
                        <div class="mc-retailer__form-section-wrapper">
                            <input [ngClass]="{'mc-retailer__form-section-input--error': submitted && f.phone.errors}"
                                   [placeholder]="'retailer_action.inputs.phone.placeholder' | translate"
                                   appValidateLatin
                                   class="mc-retailer__form-section-input"
                                   formControlName="phone"
                                   id="phone" minlength="3"
                                   required type="text">

                            <div *ngIf="submitted && f.phone.errors && f.phone.errors['minlength']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.phone.errors.length' | translate}}
                            </div>
                            <div *ngIf="submitted && f.phone.errors && f.phone.errors['required']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.phone.errors.required' | translate}}
                            </div>
                            <div *ngIf="f.phone.errors && f.phone.errors['latin']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.phone.errors.wrongFormat' | translate}}
                            </div>
                        </div>
                        <label class="mc-retailer__form-section-radio" for="phoneRadio">
                            <input formControlName="contact" hidden id="phoneRadio" type="radio" value="phone">
                            <span class="radio-checkmark"></span>
                            <span class="radio-text">{{'retailer_action.inputs.contact.title' | translate}}</span>
                        </label>
                    </div>
                    <div class="mc-retailer__form-section">
                        <label class="mc-retailer__form-section-label" for="email">
                            {{'retailer_action.inputs.email.title' | translate}}
                        </label>
                        <div class="mc-retailer__form-section-wrapper">
                            <input [ngClass]="{'mc-retailer__form-section-input--error': submitted && f.email.errors}"
                                   [placeholder]="'retailer_action.inputs.email.placeholder' | translate"
                                   appValidateEmail
                                   class="mc-retailer__form-section-input"
                                   formControlName="email"
                                   id="email"
                                   required type="text">

                            <div *ngIf="submitted && f.email.errors && f.email.errors['required']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.email.errors.required' | translate}}
                            </div>
                            <div *ngIf="f.email.errors && (f.email.errors['pattern'] || f.email.errors['email'])"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.email.errors.wrongFormat' | translate}}
                            </div>
                        </div>
                        <label class="mc-retailer__form-section-radio" for="emailRadio">
                            <input formControlName="contact" hidden id="emailRadio" type="radio" value="email">
                            <span class="radio-checkmark"></span>
                            <span class="radio-text">{{'retailer_action.inputs.contact.title' | translate}}</span>
                        </label>
                    </div>
                    <div class="mc-retailer__form-section">
                        <label class="mc-retailer__form-section-label" for="confirm_email">
                            {{'retailer_action.inputs.confirm_email.title' | translate}}
                        </label>
                        <div class="mc-retailer__form-section-wrapper">
                            <input [ngClass]="{'mc-retailer__form-section-input--error': submitted && f.confirm_email.errors}"
                                   [placeholder]="'retailer_action.inputs.confirm_email.placeholder' | translate"
                                   appValidateEmail
                                   class="mc-retailer__form-section-input"
                                   formControlName="confirm_email"
                                   id="confirm_email"
                                   type="text">

                            <div *ngIf="submitted && f.confirm_email.errors && f.confirm_email.errors['required']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.confirm_email.errors.required' | translate}}
                            </div>
                            <div *ngIf="f.confirm_email.errors && (f.confirm_email.errors['pattern'] || f.confirm_email.errors['email'])"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.confirm_email.errors.wrongFormat' | translate}}
                            </div>
                            <div *ngIf="submitted && f.confirm_email.errors && f.confirm_email.errors['notMatch'] && !f.confirm_email.errors['required']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.confirm_email.errors.notMatch' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="mc-retailer__form-section">
                        <label class="mc-retailer__form-section-label" for="comments">
                            {{'retailer_action.inputs.comments.title' | translate}}
                            <span class="mc-retailer__form-section-label-optional">
                                {{'retailer_action.labels.optional' | translate}}
                            </span>
                        </label>
                        <div class="mc-retailer__form-section-wrapper full">
                            <textarea [placeholder]="'retailer_action.inputs.comments.placeholder' | translate"
                                      [ngClass]="{'mc-retailer__form-section-input--error': submitted && f.comments.errors}"
                                      appValidateLatin class="mc-retailer__form-section-textarea"
                                      formControlName="comments" id="comments" minlength="10"
                                      rows="3"></textarea>

                            <div *ngIf="submitted && f.comments.errors && f.comments.errors['minlength']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.comments.errors.length' | translate}}
                            </div>
                            <div *ngIf="f.comments.errors && f.comments.errors['latin']"
                                 class="mc-retailer__form-section-message">
                                {{'retailer_action.inputs.comments.errors.wrongFormat' | translate}}
                            </div>
                        </div>
                    </div>
                    <button class="mc-retailer__form-submit"
                            [ngClass]="{'loading': loading}"
                            [disabled]="loading"
                            id="retailers-search-button"
                            type="submit">
                        {{'retailer_action.buttons.search' | translate}}
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="mc-retailer-block-wrapper" [ngClass]="{'hidden': !searchRetailerAvailability}">
    <div class="mc-retailer-block" #retailerContact>
        <span class="mc-retailer__block-label"><span></span>Step 02</span>
        <div class="mc-retailer-block-content">
            <div class="mc-retailer__block-title">{{'retailer_action.blocks.search.title' | translate}}</div>
            <div class="mc-retailer__block-text">{{'retailer_action.blocks.search.text' | translate}}</div>
            <div class="mc-retailer-toggle" [class]="{'map': shown}" (click)="toggleShow()">
                <span class="mc-retailer-toggle-text" [class]="{'show': shown}">
                    {{'retailer_action.buttons.show_list' | translate}}
                </span>
                <span class="mc-retailer-toggle-text" [class]="{'show': !shown}">
                    {{'retailer_action.buttons.show_map' | translate}}
                </span>
            </div>
        </div>
    </div>

    <div class="mc-retailer-block" [ngClass]="{'hide': shown}" *ngIf="far.premiumMarkers.length > 0">
        <div class="mc-retailer-block-label">
            <div class="mc-retailer-status premium">
                {{'retailer_action.block_status.premium.title' | translate}}
            </div>
            <div class="mc-retailer-block-description">
                {{'retailer_action.block_status.premium.text' | translate}}
            </div>
        </div>

        <div #premiumRetailer class="mc-retailer-block-results">
            <div class="mc-retailer-card premium" [class]="{'active': premiumMarker.marker?.checked}"
                 id="{{premiumMarker.id}}"
                 *ngFor="let premiumMarker of far.premiumMarkers"
                 (mouseenter)="onHoverRetailerEvent($event, premiumMarker)">
                <div class="mc-retailer-card-header">
                    <div class="mc-retailer-status premium">
                        {{'retailer_action.block_status.premium.name' | translate}}
                    </div>
                    <img class="mc-retailer-card-item logo"
                         *ngIf="premiumMarker.logo" [src]="premiumMarker.logo" [alt]="premiumMarker.name">
                    <div class="mc-retailer-card-item name">{{premiumMarker.name}}</div>
                </div>
                <div class="mc-retailer-card-separator"></div>
                <div class="mc-retailer-card-content">
                    <div class="mc-retailer-card-item address">{{premiumMarker.address}}, {{premiumMarker.zip_code}}</div>
                    <div class="mc-retailer-card-item phone">{{premiumMarker.phone}}</div>
                    <div class="mc-retailer-card-item types">
                        <span class="mc-retailer-card-info-icon"
                              [ngbPopover]="typesPopover"
                              triggers="click"
                              container="body"
                              placement="top top-left"
                              popoverClass="mc-popover mc-popover-card-info"></span>
                        <i  class="mc-retailer-card-type outdoor"
                            *ngIf="premiumMarker.product === 'outdoor' || premiumMarker.product === 'all'"></i>
                        <i  class="mc-retailer-card-type interior"
                            *ngIf="premiumMarker.product === 'interior' || premiumMarker.product === 'all'"></i>
                        <i  class="mc-retailer-card-type showroom"
                            *ngIf="premiumMarker.store_type === 'showroom' || premiumMarker.store_type === 'all'"></i>
                        <i  class="mc-retailer-card-type mobile"
                            *ngIf="premiumMarker.store_type === 'mobile' || premiumMarker.store_type === 'all'"></i>
                        <i  class="mc-retailer-card-type service"
                            *ngIf="+premiumMarker.service_and_repairs"></i>
                        <i  class="mc-retailer-card-type swiftee"
                            *ngIf="+premiumMarker.swiftee"></i>
                    </div>

                    <button class="mc-retailer-card-button"
                            [disabled]="far.numberOfSelectedRetailers === 2 &&
                                        !far.checkedRetailersID.includes(premiumMarker.id)"
                            (click)="onClickRetailerEvent(premiumMarker)">
                        <span class="select">
                            {{'retailer_action.buttons.select' | translate}}
                        </span>
                        <span class="remove">
                            {{'retailer_action.buttons.remove' | translate}}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="mc-retailer-block" [ngClass]="{'hide': shown}" *ngIf="far.nonPremiumMarkers?.length > 0">
        <div class="mc-retailer-block-label">
            <div class="mc-retailer-status">
                {{'retailer_action.block_status.all.title' | translate}}
            </div>
            <div class="mc-retailer-block-description">
                {{'retailer_action.block_status.all.text' | translate}}
            </div>
        </div>

        <div #nonPremiumRetailer class="mc-retailer-block-results">
            <div class="mc-retailer-card" [class]="{'active': nonPremiumMarker.marker?.checked}"
                 id="{{nonPremiumMarker.id}}"
                 *ngFor="let nonPremiumMarker of far.nonPremiumMarkers"
                 (mouseenter)="onHoverRetailerEvent($event, nonPremiumMarker)">
                <div class="mc-retailer-card-header">
                    <img class="mc-retailer-card-item logo"
                         *ngIf="nonPremiumMarker.logo" [src]="nonPremiumMarker.logo" [alt]="nonPremiumMarker.name">
                    <div class="mc-retailer-card-item name">{{nonPremiumMarker.name}}</div>
                </div>
                <div class="mc-retailer-card-separator"></div>
                <div class="mc-retailer-card-content">
                    <div class="mc-retailer-card-item address">{{nonPremiumMarker.address}}, {{nonPremiumMarker.zip_code}}</div>
                    <div class="mc-retailer-card-item phone">{{nonPremiumMarker.phone}}</div>
                    <div class="mc-retailer-card-item types">
                        <span class="mc-retailer-card-info-icon"
                              [ngbPopover]="typesPopover"
                              triggers="click"
                              container="body"
                              placement="top top-left"
                              popoverClass="mc-popover mc-popover-card-info"></span>
                        <i  class="mc-retailer-card-type outdoor"
                            *ngIf="nonPremiumMarker.product === 'outdoor' || nonPremiumMarker.product === 'all'"></i>
                        <i  class="mc-retailer-card-type interior"
                            *ngIf="nonPremiumMarker.product === 'interior' || nonPremiumMarker.product === 'all'"></i>
                        <i  class="mc-retailer-card-type showroom"
                            *ngIf="nonPremiumMarker.store_type === 'showroom' || nonPremiumMarker.store_type === 'all'"></i>
                        <i  class="mc-retailer-card-type mobile"
                            *ngIf="nonPremiumMarker.store_type === 'mobile' || nonPremiumMarker.store_type === 'all'"></i>
                        <i  class="mc-retailer-card-type service"
                            *ngIf="+nonPremiumMarker.service_and_repairs"></i>
                        <i  class="mc-retailer-card-type swiftee"
                            *ngIf="+nonPremiumMarker.swiftee"></i>
                    </div>

                    <button class="mc-retailer-card-button"
                            [disabled]="far.numberOfSelectedRetailers === 2 &&
                                        !far.checkedRetailersID.includes(nonPremiumMarker.id)"
                            (click)="onClickRetailerEvent(nonPremiumMarker)">
                        <span class="select">{{'retailer_action.buttons.select' | translate}}</span>
                        <span class="remove">{{'retailer_action.buttons.remove' | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="mc-retailer-map-wrapper" [ngClass]="{'hide': !shown}">
        <div #mapElement class="mc-retailer-map"></div>
        <ng-container *media="breakpoints['phone'].max">
            <div class="mc-retailer-card" *ngIf="mapSelectedRetailer"
                 [class]="{'active': mapSelectedRetailer.marker?.checked}"
                 id="{{mapSelectedRetailer.id}}">
                <div class="mc-retailer-card-header"
                     (click)="onRetailerCardHandler($event, null)">
                    <div class="mc-retailer-card-item back-button"
                         (click)="onCloseRetailerCard()">
                        {{'retailer_action.buttons.back_to_all' | translate}}
                    </div>
                    <div class="mc-retailer-card-item name">
                        <span>{{mapSelectedRetailer.name}}</span>
                        <span class="mc-retailer-card-selected"></span>
                        <span class="mc-retailer-card-open"></span>
                    </div>
                </div>
                <div class="mc-retailer-card-content">
                    <div class="mc-retailer-card-separator"></div>
                    <div class="mc-retailer-card-item">
                        <img class="mc-retailer-card-item logo" *ngIf="mapSelectedRetailer.logo"
                             [src]="mapSelectedRetailer.logo" [alt]="mapSelectedRetailer.name">
                        <div class="mc-retailer-status premium"
                             *ngIf="+mapSelectedRetailer.premium">
                            {{'retailer_action.block_status.premium.name' | translate}}
                        </div>
                    </div>
                    <div class="mc-retailer-card-item address">{{mapSelectedRetailer.address}}, {{mapSelectedRetailer.zip_code}}</div>
                    <div class="mc-retailer-card-item phone">{{mapSelectedRetailer.phone}}</div>
                    <div class="mc-retailer-card-item types">
                        <span class="mc-retailer-card-info-icon"
                              [ngbPopover]="typesPopover"
                              triggers="click"
                              container="body"
                              placement="top-left"
                              popoverClass="mc-popover mc-popover-card-info map"></span>
                        <i  class="mc-retailer-card-type outdoor"
                            *ngIf="mapSelectedRetailer.product === 'outdoor' || mapSelectedRetailer.product === 'all'"></i>
                        <i  class="mc-retailer-card-type interior"
                            *ngIf="mapSelectedRetailer.product === 'interior' || mapSelectedRetailer.product === 'all'"></i>
                        <i  class="mc-retailer-card-type showroom"
                            *ngIf="mapSelectedRetailer.store_type === 'showroom' || mapSelectedRetailer.store_type === 'all'"></i>
                        <i  class="mc-retailer-card-type mobile"
                            *ngIf="mapSelectedRetailer.store_type === 'mobile' || mapSelectedRetailer.store_type === 'all'"></i>
                        <i  class="mc-retailer-card-type service"
                            *ngIf="+mapSelectedRetailer.service_and_repairs"></i>
                        <i  class="mc-retailer-card-type swiftee"
                            *ngIf="+mapSelectedRetailer.swiftee"></i>
                    </div>

                    <button class="mc-retailer-card-button"
                            [disabled]="far.numberOfSelectedRetailers === 2 &&
                                        !far.checkedRetailersID.includes(mapSelectedRetailer.id)"
                            (click)="onClickRetailerEvent(mapSelectedRetailer)">
                        <span class="select">{{'retailer_action.buttons.select' | translate}}</span>
                        <span class="remove">{{'retailer_action.buttons.remove' | translate}}</span>
                    </button>
                </div>
            </div>
        </ng-container>

        <div class="mc-retailer-map-list" *media="breakpoints['phone-portrait'].min">
            <div class="mc-retailer-map-list-title">
                {{'retailer_action.block_status.all.title' | translate}}
            </div>
            <div #mapCardsList class="mc-retailer-map-list-items">
                <div class="mc-retailer-card"
                     [class]="{'active': retailerMarker.marker?.checked, 'premium': +retailerMarker.premium}"
                     id="{{retailerMarker.id}}"
                     *ngFor="let retailerMarker of far.premiumMarkers.concat(far.nonPremiumMarkers)"
                     (mouseenter)="onHoverRetailerEvent($event, retailerMarker)">
                    <div class="mc-retailer-card-header" (click)="onRetailerCardHandler($event, mapCardsList)">
                        <div class="mc-retailer-card-item name">
                            <span>{{retailerMarker.name}}</span>
                            <span class="mc-retailer-card-selected"></span>
                            <span class="mc-retailer-card-open"></span>
                        </div>
                    </div>
                    <div class="mc-retailer-card-content">
                        <div class="mc-retailer-card-separator"></div>
                        <div class="mc-retailer-card-item">
                            <img class="mc-retailer-card-item logo" *ngIf="retailerMarker.logo"
                                 [src]="retailerMarker.logo" [alt]="retailerMarker.name">
                            <div class="mc-retailer-status premium"
                                 *ngIf="+retailerMarker.premium">
                                 {{'retailer_action.block_status.premium.name' | translate}}</div>
                        </div>
                        <div class="mc-retailer-card-item address">{{retailerMarker.address}}, {{retailerMarker.zip_code}}</div>
                        <div class="mc-retailer-card-item phone">{{retailerMarker.phone}}</div>
                        <div class="mc-retailer-card-item types">
                            <span class="mc-retailer-card-info-icon"
                                  [ngbPopover]="typesPopover"
                                  triggers="click"
                                  container="body"
                                  placement="top-left"
                                  popoverClass="mc-popover mc-popover-card-info"></span>
                            <i  class="mc-retailer-card-type outdoor"
                                *ngIf="retailerMarker.product === 'outdoor' || retailerMarker.product === 'all'"></i>
                            <i  class="mc-retailer-card-type interior"
                                *ngIf="retailerMarker.product === 'interior' || retailerMarker.product === 'all'"></i>
                            <i  class="mc-retailer-card-type showroom"
                                *ngIf="retailerMarker.store_type === 'showroom' || retailerMarker.store_type === 'all'"></i>
                            <i  class="mc-retailer-card-type mobile"
                                *ngIf="retailerMarker.store_type === 'mobile' || retailerMarker.store_type === 'all'"></i>
                            <i  class="mc-retailer-card-type service"
                                *ngIf="+retailerMarker.service_and_repairs"></i>
                            <i  class="mc-retailer-card-type swiftee"
                                *ngIf="+retailerMarker.swiftee"></i>
                        </div>

                        <button class="mc-retailer-card-button"
                                [disabled]="far.numberOfSelectedRetailers === 2 &&
                                            !far.checkedRetailersID.includes(retailerMarker.id)"
                                (click)="onClickRetailerEvent(retailerMarker)">
                            <span class="select">{{'retailer_action.buttons.select' | translate}}</span>
                            <span class="remove">{{'retailer_action.buttons.remove' | translate}}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mc-retailer-block" [ngClass]="{'hide': !shown}" *media="breakpoints['phone'].max">
        <div class="mc-retailer-block-results" *ngIf="checkedRetailers.length">
            <div class="mc-retailer-block-results-title">
                {{'retailer_action.block_status.selected.title' | translate}}
            </div>
            <div class="mc-retailer-card"
                 [class]="{'active': retailerMarker.marker?.checked, 'premium': +retailerMarker.premium}"
                 id="{{retailerMarker.id}}"
                 *ngFor="let retailerMarker of checkedRetailers">
                <div class="mc-retailer-card-header">
                    <div class="mc-retailer-status premium" *ngIf="+retailerMarker.premium">
                        {{'retailer_action.block_status.premium.title' | translate}}
                    </div>
                    <img class="mc-retailer-card-item logo" *ngIf="retailerMarker.logo" [src]="retailerMarker.logo" [alt]="retailerMarker.name">
                    <div class="mc-retailer-card-item name">{{retailerMarker.name}}</div>
                </div>
                <div class="mc-retailer-card-separator"></div>
                <div class="mc-retailer-card-content">
                    <div class="mc-retailer-card-item address">{{retailerMarker.address}}, {{retailerMarker.zip_code}}</div>
                    <div class="mc-retailer-card-item phone">{{retailerMarker.phone}}</div>
                    <div class="mc-retailer-card-item types">
                        <span class="mc-retailer-card-info-icon"
                              [ngbPopover]="typesPopover"
                              triggers="click"
                              container="body"
                              placement="top top-left"
                              popoverClass="mc-popover mc-popover-card-info"></span>
                        <i  class="mc-retailer-card-type outdoor"
                            *ngIf="retailerMarker.product === 'outdoor' || retailerMarker.product === 'all'"></i>
                        <i  class="mc-retailer-card-type interior"
                            *ngIf="retailerMarker.product === 'interior' || retailerMarker.product === 'all'"></i>
                        <i  class="mc-retailer-card-type showroom"
                            *ngIf="retailerMarker.store_type === 'showroom' || retailerMarker.store_type === 'all'"></i>
                        <i  class="mc-retailer-card-type mobile"
                            *ngIf="retailerMarker.store_type === 'mobile' || retailerMarker.store_type === 'all'"></i>
                        <i  class="mc-retailer-card-type service"
                            *ngIf="+retailerMarker.service_and_repairs"></i>
                        <i  class="mc-retailer-card-type swiftee"
                            *ngIf="+retailerMarker.swiftee"></i>
                    </div>

                    <button class="mc-retailer-card-button"
                            [disabled]="far.numberOfSelectedRetailers === 2 &&
                                        !far.checkedRetailersID.includes(retailerMarker.id)"
                            (click)="onClickRetailerEvent(retailerMarker)">
                        <span class="select">{{'retailer_action.buttons.select' | translate}}</span>
                        <span class="remove">{{'retailer_action.buttons.remove' | translate}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="mc-retailer-block-request" *ngIf="far.hasResults">
        <div class="mc-retailer__block-text request">
            {{'retailer_action.blocks.request.text' | translate}}
        </div>

        <div #retailerSendData class="mc-retailer-data-submit">
            <button (click)="onSendData($event)" class="mc-summary__block-link--request" id="retailers-request-button"
                    type="submit">
                <span class="loader"></span>
                {{'retailer_action.buttons.request' | translate}}
            </button>
        </div>

        <div class="mc-retailer__block-text privacy">
            {{'retailer_action.blocks.request.privacy' | translate}}<a
                href="https://www.ziptrak.com.au/contact/privacy-policy/"
                target="_blank"> {{'retailer_action.blocks.request.here' | translate}}</a>.
        </div>
    </div>


    <ng-template #typesPopover>
        <div class="mc-retailer-popover-info">
            <div class="mc-retailer-popover-info-item">
                <div class="mc-retailer-popover-icon outdoor"></div>
                <div class="mc-retailer-popover-info-item-text">{{'modals.retailer_popover_info.items.outdoor' | translate}}</div>
            </div>
            <div class="mc-retailer-popover-info-item">
                <div class="mc-retailer-popover-icon interior"></div>
                <div class="mc-retailer-popover-info-item-text">{{'modals.retailer_popover_info.items.interior' | translate}}</div>
            </div>
            <div class="mc-retailer-popover-info-item">
                <div class="mc-retailer-popover-icon showroom"></div>
                <div class="mc-retailer-popover-info-item-text">{{'modals.retailer_popover_info.items.showroom' | translate}}</div>
            </div>
            <div class="mc-retailer-popover-info-item">
                <div class="mc-retailer-popover-icon mobile"></div>
                <div class="mc-retailer-popover-info-item-text">{{'modals.retailer_popover_info.items.mobile' | translate}}</div>
            </div>
            <div class="mc-retailer-popover-info-item">
                <div class="mc-retailer-popover-icon service"></div>
                <div class="mc-retailer-popover-info-item-text">{{'modals.retailer_popover_info.items.service' | translate}}</div>
            </div>
            <div class="mc-retailer-popover-info-item">
                <div class="mc-retailer-popover-icon swiftee"></div>
                <div class="mc-retailer-popover-info-item-text">{{'modals.retailer_popover_info.items.swiftee' | translate}}</div>
            </div>
            <hr class="mc-retailer-popover-info-separator"/>
            <div class="mc-retailer-popover-info-footer">{{'modals.retailer_popover_info.text' | translate}}</div>
        </div>
    </ng-template>

</div>
