import { Injectable } from '@angular/core';
import { Countries } from '../../../app.config';
import { CountryModalComponent } from '@shared/modals/country-modal/country-modal.component';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { MatDialog } from '@angular/material/dialog';
import { BlindTypeModalComponent } from '@shared/modals/blind-type-modal/blind-type-modal.component';

import * as _ from 'lodash';


@Injectable({
  providedIn: 'root'
})
export class SetupPageService {
  constructor(
      private sessionStorageService: SessionStorageService,
      private shareDataService: ShareService,
      public dialog: MatDialog
  ) { }

  getCountryCode(): any {
    return this.sessionStorageService.getSession('zip-country-code');
  }

  validateCountryCodeHandler(code): any {
    return !_.isEmpty(Countries.filter(item => item.code === code));
  }

  /** The resetOnBeforeUnloadHandler() method prevents standard modal windows from appearing in browsers by resetting the onbeforeunload event. */
  resetOnBeforeUnloadHandler(): void {
      // window.onbeforeunload = () => {};
  }

  parseLocalLinkHandler(): void {
    const getCountryCode = this.getCountryCode();
    let link: string = '';

    for (const item of Countries) {
        if (item.code === getCountryCode) {
            link = item.link;
        }
    }

    link = link || Countries.filter(item => item.code === 'au')[0].link;
    window.open(link, '_self');
  }

  sendCountryCodeForRender(code, status): void {
    if (status || this.getCountryCode()) {
        const country = this.getCountryCode() === code ? code : status ? code : this.getCountryCode();

        this.shareDataService.setCountry(country);
        this.sessionStorageService.setSession(country, 'zip-country-code');
    } else {
        this.dialog.open(CountryModalComponent, {
            maxWidth: '1070px',
            height: 'auto',
            minHeight: '200px',
            data: Countries,
            disableClose: true,
            backdropClass: 'mc-country-modal__bg',
            panelClass: 'mc-country-modal__dialog'
        });
    }
  }

  sendBlindTypeForRender(status?): void {
      const dialogExist = this.dialog.getDialogById('blind-type');

      if (!dialogExist) {
          this.dialog.open(BlindTypeModalComponent, {
              id: 'blind-type',
              maxWidth: '1070px',
              height: 'auto',
              minHeight: '200px',
              data: status,
              disableClose: true,
              backdropClass: status ? 'mc-blind-modal__bg' : '',
              panelClass: status ? 'mc-blind-modal__dialog' : 'mc-blind-modal__internal',
              closeOnNavigation: false
          });
      }
  }
}
