import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header/header.component';
import { LoaderComponent } from './loader/loader.component';
import { AppRoutingModule} from '../app-routing.module';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { ToTopBtnComponent } from './to-top-btn/to-top-btn.component';
import { LeaveModalComponent } from './modals/leave-modal/leave-modal.component';
import { DeleteModalComponent } from './modals/delete-modal/delete-modal.component';
import { RetailerSuccessModalComponent } from './modals/retailer-success-modal/retailer-success-modal.component';
import { RetailerNoSelectModalComponent } from './modals/retailer-no-select-modal/retailer-no-select-modal.component';
import { RetailerSelectModalComponent } from './modals/retailer-select-modal/retailer-select-modal.component';

@NgModule({
	declarations: [
		HeaderComponent,
		LoaderComponent,
		ErrorModalComponent,
		ToTopBtnComponent,
		LeaveModalComponent,
		DeleteModalComponent,
		RetailerSuccessModalComponent,
		RetailerNoSelectModalComponent,
		RetailerSelectModalComponent
	],
	imports: [
		CommonModule,
		AppRoutingModule,
		TranslateModule
	],
	exports: [
		HeaderComponent,
		LoaderComponent,
		ToTopBtnComponent
	]
})
export class SharedModule { }
