<div class="bars">
    <div *ngIf="hasVerticalBar" class="bar bar_vertical">
        <div class="thumb"
             appDraggable="vertical"
             [style.height.%]="verticalSize"
             [style.top.%]="verticalPosition"
             (dragged)="onVertical($event)">
        </div>
    </div>
</div>
<div class="content" (waResizeObserver)="onScroll()">
    <ng-content></ng-content>
</div>
