import {
    Component,
    OnDestroy,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewChild,
    ElementRef
} from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { Subscription } from 'rxjs';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { ActivatedRoute } from '@angular/router';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { AccordionSelector } from '@root/app.interfaces';

declare var $: any;
import * as _ from 'lodash';

@Component({
    selector: 'app-blind-list',
    templateUrl: './blind-list.component.html',
    styleUrls: ['./blind-list.component.scss', './blind-list.responsive.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlindListComponent implements OnInit, OnDestroy {
    @ViewChild('newBlindName') newBlindName: ElementRef;
    blindsData;
    accordShow;
    item = {
        length: 0,
        id: ''
    };
    slideSpeed = 200;
    isBlindName = true;
    isModelLoaded: string;
    isFirstLoading = true;
    blindTempName = '';
    localText = {
        material_title: '',
        operation_title: '',
        mounting_title: '',
        size_pop_up_text: ''
    };
    blindSelectors: AccordionSelector = {
        title: '.mc-blind-item',
        content: '.mc-blind-item__setup-container'
    };
    blindId: string;
    blindItemObject = {};
    blindType = this.sessionStorageService.getSession('zip-blind-type');
    isBlindSelected: boolean = true;
    setBlindSelectData = {
        id: '',
        type: ''
    };

    getBlindsData: Subscription;
    getBlindStatus: Subscription;
    getSessionBlindsData: Subscription;
    getActivatedRouteRules: Subscription;
    getScreenShot: Subscription;
    getModelLoadedStatus: Subscription;
    getBlindsUpdated: Subscription;
    getBlindType: Subscription;

    activeBlindItem(id, type): void {
        this.blindId = id;
        const blindById = document.getElementById(id);

        this.collapseAccordionList();
        this.driveAccordionItem(id, true);

        const top = type === 'outdoor' ? $(blindById).offset().top : $(blindById).offset().top - 130;
        this.shareDataService.setBlindPosition(!_.isEmpty(blindById) ? top : 0);
        this.blindNameSave(id);
    }

    constructor(
        public shareDataService: ShareService,
        public engineService: EngineService,
        public localStorageService: LocalStorageService,
        public serverDataService: ServerDataService,
        public saveSessionBlind: SaveSessionBlindsService,
        public route: ActivatedRoute,
        public screenShotService: ScreenshotService,
        public sessionStorageService: SessionStorageService,

        public changeDetection: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.getActivatedRouteRules = this.route.data.subscribe(res => {
            this.getLocalDefaultValue();
            this.getLocalStorageData();
            this.setSessionStorage();

            setTimeout(this.setNameMask.bind(this));

            this.changeDetection.markForCheck();
        });

        this.getBlindType = this.shareDataService.getBlindType.subscribe(this.getServerDataHandler.bind(this));
        this.getBlindsData = this.shareDataService.currentData.subscribe(this.setBlindDataHandler.bind(this));
        this.getBlindStatus = this.shareDataService.getStatus.subscribe(status => {
            this.isBlindName = status;
            this.changeDetection.markForCheck();
        });

        this.getScreenShot = this.screenShotService.getScreenShot.subscribe(this.setScreenShotToBLind.bind(this));
        this.getModelLoadedStatus = this.shareDataService.getSceneCreated.subscribe(res => {
            this.isModelLoaded = res;

            if (this.isFirstLoading) {
                this.accordionCurrentBlind();
                this.isFirstLoading = false;
            }

            if (!this.isFirstLoading && !this.isBlindSelected && this.setBlindSelectData.type === res) {
                this.isBlindSelected = true;
                this.shareDataService.setBlindSelect(this.setBlindSelectData);
            }
        });

        this.getBlindsUpdated = this.shareDataService.getBlindsUpdated.subscribe(() => {
            if (this.blindsData.length === 0) {
                this.deletedLastBlindItem();
            }
        });
    }

    ngOnDestroy(): void {
        this.getBlindsData.unsubscribe();
        this.getBlindStatus.unsubscribe();
        this.getBlindType.unsubscribe();
        this.getBlindsUpdated.unsubscribe();
        this.getActivatedRouteRules.unsubscribe();
        this.getScreenShot.unsubscribe();
        this.getModelLoadedStatus.unsubscribe();
        this.getSessionBlindsData?.unsubscribe();
    }

    getServerDataHandler(): void {
        this.getSessionBlindsData = this.shareDataService.getSessionBlindsData.subscribe(res => {
            this.setConfigurationDataHandler();

            if (!res && this.isModelLoaded.length) {
                this.engineService.createScene(this.isModelLoaded);
            }

            this.changeDetection.markForCheck();
        });

        this.setConfigurationDataHandler();
    }

    setConfigurationDataHandler(): void {
        this.getLocalDefaultValue();
        this.getLocalStorageData();

        setTimeout(this.setNameMask.bind(this));
    }

    setSessionStorage(): void {
        const localStorageSession = this.localStorageService.getBlindData('zip-blind-session');

        if (localStorageSession) {
            this.shareDataService.setLinkSessionKey(localStorageSession);
        }
    }

    getLocalStorageData(): void {
        const storageData = this.localStorageService.getBlindData('zip-blind-data');

        if (_.isEmpty(this.blindsData)) {
            this.blindsData = storageData;
        } else {
            storageData.forEach((blind, index) => {
                this.blindsData[index] = Object.assign(this.blindsData[index] || {}, blind);
            });
        }
    }

    setBlindDataHandler(data): void {
        for (const item of data) {
            this.item = {
                length: data.length,
                id: item.blind_id
            };
        }
        setTimeout(() => {
            this.setNameMask();
            this.activeBlindItem(this.item.id, data.type);
            this.localStorageService.setBlindData(true, 'zip-model-init');
        });

        this.blindTempName = '';
        this.getLocalStorageData();

        if (this.blindsData.length > 0) {
            this.shareDataService.setStatus(false);
        }
        this.blindItemObject = {};
        this.changeDetection.markForCheck();
    }

    blindName(newBlindName, event, id): void {
        this.blindTempName = newBlindName.trim();

        if (event.keyCode === 13 || event.type === 'blur') {
            this.blindNameSave(id);
        }
    }

    setNameMask(): void {
        const blindList = $('.mc-blind-list');

        if (!_.isEmpty(this.blindsData)) {
            for (const item of this.blindsData) {

                if (item.has_name) {
                    for (const blindId of blindList) {
                        const currentBlindId = $(blindId).find(`#${item.blind_id}`);
                        currentBlindId.find('.mc-config-item__setup').addClass('named');
                    }
                }
            }
        }
    }

    blindNameSave(id): void {
        const blindList = $('.mc-blind-list');

        for (const blindId of blindList) {
            const currentBlindId = $(blindId).find(`#${id}`);

            if (this.newBlindName?.nativeElement) {
                this.blindTempName = $(this.newBlindName.nativeElement).val();
            }

            if (this.blindTempName.length > 1) {
                currentBlindId.find('.mc-config-item__setup').removeClass('error');
                currentBlindId.find('.mc-config-item__setup').addClass('named');

                this.getBlindData(id, true);
                this.shareDataService.setStatus(true);
            } else {
                currentBlindId.find('.mc-config-item__setup').addClass('error');
            }
        }
    }

    blindNameEdit(id, event): void {
        const elem = $(event.currentTarget);
        $(event.currentTarget).parent().removeClass('named');
        setTimeout(() => elem.children().focus());

        this.getBlindData(id, false);
        this.shareDataService.setStatus(false);
    }

    getBlindData(id, hasName): void {
        const blindItem = this.blindsData.filter(x => x.blind_id === id)[0];
        const localStorageData = this.localStorageService.getBlindData('zip-blind-data')
        const blindItemCopy = localStorageData.filter(x => x.blind_id === id)[0];

        blindItem.setup = blindItemCopy.setup;

        if (this.blindTempName.length && (_.isEmpty(blindItem.name) || !blindItem.has_name)) {
            blindItem.name = this.blindTempName;
        }
        blindItem.has_name = hasName;

        localStorageData[localStorageData.indexOf(blindItemCopy)] = blindItem;

        this.localStorageService.setBlindData(localStorageData, 'zip-blind-data');
        this.saveSessionBlind.PutStorageDataToServer();
        this.shareDataService.setBlindName(this.blindTempName);
    }

    getCountryCode(): any {
      return this.sessionStorageService.getSession('zip-country-code');
    }

    setScreenShotToBLind(res): void {
        const tempSessionKey = this.localStorageService.getBlindData('zip-blind-temp-session');
        const sessionKey = this.localStorageService.getBlindData('zip-blind-session');

        const imageName = !_.isEmpty(tempSessionKey) ? `${tempSessionKey}_${res.blindId}` : `${sessionKey}_${res.blindId}`;

        const imageData = {
            country: this.getCountryCode(),
            image: res.img,
            name: imageName
        };

        this.serverDataService.PostImageData(imageData).subscribe(() => {
            const blindData = this.localStorageService.getBlindData('zip-blind-data');
            const blindItem = blindData.filter(x => x.blind_id === res.blindId);
            const currentBlindsDataItem = this.blindsData.filter(x => x.blind_id === res.blindId);

            blindItem.map( blindObj => {
                blindObj.blind_img = imageName + '.png';
            });

            currentBlindsDataItem.map( blindObj => {
                blindObj.blind_img = imageName + '.png';
            });

            this.localStorageService.setBlindData(blindData, 'zip-blind-data');
        });

        this.changeDetection.markForCheck();
    }

    accordionConfAction(event, selector, status): void {
        const accordionList = $(selector);
        const accordionItem = $(event.currentTarget).parents(selector);

        for (const item of accordionList) {
            if ($(item).hasClass('active')) {
                $(item).children(0).next().stop().slideUp(this.slideSpeed, () => {
                    $(item).removeClass('active');
                });
            }
        }

        this.accordShow = status;
        this.accordShow = !this.accordShow;

        if (accordionItem.hasClass('active')) {
            $(event.currentTarget).next().stop().slideUp(this.slideSpeed, () => {
                this.accordShow = false;
                accordionItem.removeClass('active');
            });
        } else {
            $(event.currentTarget).next().stop().height('auto').slideDown(this.slideSpeed, () => {
                this.accordShow = true;
                accordionItem.addClass('active');
            });
        }
    }

    collapseAccordionList(): void {
        const accordionList = $(this.blindSelectors.title);
        for (const item of accordionList) {
            if (item.id) {
                $(item).data('slided', false);
                $(item).removeClass('active');

                $(item).find(this.blindSelectors.content).stop().slideUp(this.slideSpeed);
            }
        }
    }

    driveAccordionItem(id: number, open: boolean): void {
        const blindItem = $(`#${id}`);

        blindItem.toggleClass('active', open);
        blindItem.data('slided', open);

        if (open) {
            blindItem.find(this.blindSelectors.content).stop().slideDown(this.slideSpeed);
        } else {
            blindItem.find(this.blindSelectors.content).stop().slideUp(this.slideSpeed);
        }
    }

    accordionBlindItems(event, itemId, type): void {
        if (this.isBlindName) {
            if (event && ($(event.currentTarget).parent().data('slided') === true || $(event.currentTarget).parent().hasClass('active'))) {
                this.driveAccordionItem(itemId, false);
                this.localStorageService.removeBlindData('zip-current-blind-id');
            } else {
                if (this.isModelLoaded !== type) {
                    this.engineService.createScene(type);
                    this.sessionStorageService.setSession(type, 'zip-blind-type');
                } else {
                    this.shareDataService.setAccordionType(type);
                }

                this.collapseAccordionList();
                setTimeout(this.driveAccordionItem.bind(this, itemId, true));

                this.localStorageService.getBlindData('zip-blind-data').filter(x => x.blind_id === itemId).map(item => {
                    this.setBlindSelectData = {
                        id: item.blind_id,
                        type: item.type
                    };

                    if (item.has_name) {
                        this.saveCurrentBlindScrennshot();
                        this.shareDataService.setBlindName(item.name);

                        if (this.isModelLoaded === type) {
                            this.shareDataService.setBlindSelect(this.setBlindSelectData);
                        }

                        this.isBlindSelected = this.isModelLoaded === type;
                    }
                });
            }
        }
    }

    deleteBlindItem(itemId): void {
        const sessionSaved = this.localStorageService.getBlindData('zip-blind-session-saved');
        this.getLocalStorageData();

        const deletedBlindIndex = this.blindsData.findIndex(x => x.blind_id === itemId);
        if (deletedBlindIndex < this.blindsData.length - 1) {
            this.blindsData.forEach((x, i) => x.name = i > deletedBlindIndex && x.name === `Blind ${i + 1}` ? `Blind ${i}` : x.name);
        }

        this.blindsData.splice(deletedBlindIndex, 1);
        this.localStorageService.setBlindData(this.blindsData, 'zip-blind-data');
        this.saveSessionBlind.PutStorageDataToServer();

        if (this.blindsData.length > 0) {
            this.shareDataService.setBlindUnselect(true);
            this.accordionCurrentBlind(true);
        } else if (!sessionSaved) {
           this.deletedLastBlindItem();
        }

        this.shareDataService.setStatus(true);
        this.blindItemObject = {};
    }

    openPopover(e, popover): void {
        e.stopPropagation();

        if (this.isBlindName) {
            popover.open();
            this.saveCurrentBlindScrennshot();
        }
    }

    closePopover(e, popover): void {
        e.stopPropagation();
        popover.close();
    }

    getLocalDefaultValue(): void {
        const getBlindType = this.sessionStorageService.getSession('zip-blind-type');
        const localStorageText = this.localStorageService.getBlindData('zip-blind-text');

        if (!_.isEmpty(localStorageText)) {
            this.localText = localStorageText;
        }
    }

    saveCurrentBlindScrennshot(): void {
        const currentBlindId = this.localStorageService.getBlindData('zip-current-blind-id');
        this.screenShotService.saveScreenShot(currentBlindId);
    }

    accordionCurrentBlind(afterDelete?: boolean): void {
        if (!_.isEmpty(this.blindsData) && this.isModelLoaded) {
            const lastOpenedBlindId = this.localStorageService.getBlindData('zip-current-blind-id');
            const lastArrayBlind = this.blindsData[this.blindsData.length - 1];
            const lastOpenedBlind = this.localStorageService.getBlindItemById(lastOpenedBlindId, 'zip-blind-data')[0] || lastArrayBlind;
            const lastOpenedBlindElement =  $(`#${lastOpenedBlind.blind_id}`);

            if (!lastOpenedBlindElement[0].classList.contains('active') || afterDelete) {
                this.accordionBlindItems(null, lastOpenedBlind.blind_id, lastOpenedBlind.type);
            }
        }
    }

    onBlindItemDescriptionHandler(event, id?: number) {
        const currentBlind = this.blindsData.filter(item => item.blind_id === id)[0];
        this.blindItemObject = {...currentBlind.setup, ...event};
        currentBlind.setup = this.blindItemObject;
    }

    deletedLastBlindItem(): void {
        this.shareDataService.setBlindEmpty(true);
        this.engineService.createScene(this.isModelLoaded);
    }

    onPopover(popover: NgbPopover, button: HTMLElement): void {
        $(button).toggleClass('active', popover.isOpen());
    }

}
