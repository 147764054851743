<div class="mc-shutter-control">
	<div class="mc-shutter-icon"></div>
	<mat-slider
		class="mc-shutter-slider"
		[vertical]="true"
		[(value)]="status"
		(input)="onShutterChange($event)"
		min="0" max="1"
		[step]="step"
	></mat-slider>
</div>
