import { Component, OnDestroy, OnInit } from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-shutter-control',
	templateUrl: './shutter-control.component.html',
	styleUrls: ['./shutter-control.component.scss', './shutter-control.responsive.scss']
})
export class ShutterControlComponent implements OnInit, OnDestroy {
	getAccordingType: Subscription;
	getModelLoaded: Subscription;
	
	value = 1;
	step = 0.001;
	status: number;
	
	constructor(
		private engineService: EngineService,
		private shareService: ShareService
	) { }

	ngOnInit(): void {
		this.shutterHandler();
	}
	
	ngOnDestroy() {
		this.getAccordingType.unsubscribe();
		this.getModelLoaded.unsubscribe();
	}
	
	onShutterChange(data): void {
		this.engineService.setTop(this.value - data.value);
	}

	shutterHandler(): void {
		this.getModelLoaded = this.shareService.getSceneCreated.subscribe(this.closeShutter.bind(this));
		this.getAccordingType = this.shareService.getAccordionType.subscribe(this.closeShutter.bind(this));
	}

	closeShutter(): void {
		this.status = 0;
		this.onShutterChange({value: this.status});
	}

}
