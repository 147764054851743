import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import { Router, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { Location } from '@angular/common';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { OverviewLinkHandlerService } from '@core/services/overview-link-handler/overview-link-handler.service';
import { EngineService } from '@core/services/engine/engine.service';
import { SetupPageService } from '@core/services/setup-page/setup-page.service';
import { TranslateService } from '@ngx-translate/core';

import * as _ from 'lodash';
declare var $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    title = '3dc';
    sessionKey = '';
    getCountry: Subscription;
    getCountryLink: Subscription;
    getRouteEvents: Subscription;
    getServerData: Subscription;
    getDefaultServerData: Subscription;
    getSessionLink: Subscription;
    getSessionData: Subscription;
    getRetailerRequest: Subscription;
    getActionBackPage: Subscription;
    getActivatedRouteRules: Subscription;
    getModelLoaded: Subscription;
    userVisitTime = 0;
    sceneCreated = false;
    countryLink = '';
    timerId;
    currentLink;

    constructor(
        private router: Router,
        private serverData: ServerDataService,
        private location: Location,
        private localStorage: LocalStorageService,
        private route: ActivatedRoute,
        private setupPageService: SetupPageService,

        private shareService: ShareService,
        private changeDetection: ChangeDetectorRef,
        private overviewLinkHandlerService: OverviewLinkHandlerService,
        private engineService: EngineService,
        private readonly translateService: TranslateService,
        private sessionStorageService: SessionStorageService
    ) {}

    private setDefaultLang() {
        this.translateService.addLangs(['en']);
        this.translateService.setDefaultLang('en');

        const browserLanguage: any = this.translateService.getBrowserLang();
        this.translateService.use(browserLanguage.match(/en/) ? browserLanguage : 'en');
    }

    ngOnInit(): void {
        this.setDefaultLang();
        this.getModelLoaded = this.shareService.getSceneCreated.subscribe(() => {
            this.sceneCreated = true;

            if (this.countryLink) {
                this.getDefaultServerDataHandler(this.countryLink);
            }

            this.changeDetection.markForCheck();
        });

        this.getCountry = this.shareService.getCountry.subscribe(res => {
            this.countryLink = res;

            this.changeDetection.markForCheck();
        });

        this.getCountryLink = this.shareService.getCountryLink.subscribe(res => {
            this.countryLink = res;
            this.getDefaultServerDataHandler(res);

            this.changeDetection.markForCheck();
        });

        this.bindEvent();
    }

    ngOnDestroy(): void {
        this.getRouteEvents.unsubscribe();
        this.getServerData.unsubscribe();
        this.getDefaultServerData.unsubscribe();
        this.getSessionLink.unsubscribe();
        this.getSessionData.unsubscribe();
        this.getRetailerRequest.unsubscribe();
        this.getActivatedRouteRules.unsubscribe();
        this.getModelLoaded.unsubscribe();
        this.getCountry.unsubscribe();
        this.getCountryLink.unsubscribe();
    }

    bindEvent(): void {
      const currentDomainLink = this.location.path().split('?');

      this.currentLink = currentDomainLink[0].split('/')[1] || 'home';
      const splitQuery = currentDomainLink[1]?.split('=');
      const sessionKeyQuery = splitQuery && splitQuery[0] !== 'country' ? currentDomainLink[1]?.split('=')[1] : '';
      this.sessionKey = this.parseQueryParams() ? sessionKeyQuery.split('&')[0] : sessionKeyQuery;

      this.getActivatedRouteRules = this.route.data.subscribe(() => {
        this.routeCurrentPageHandler('');
        this.removeAllLocalStorageData();
        this.checkActivePageHandler();

        if (!this.sessionKey && this.countryLink) {
            this.setupPageService.sendBlindTypeForRender(true);
            setTimeout(this.setDefaultLoader.bind(this, true));
        }

        this.changeDetection.markForCheck();
      });

      this.getSessionLink = this.shareService.getLinkSessionKey.subscribe(res => {
        this.routeCurrentPageHandler(res);

        this.changeDetection.markForCheck();
      });

      this.getUserVisitTime();
      this.getActionBackPage = this.router.events
        .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
        .subscribe((events: RoutesRecognized[]) => {
          const prevUrl = events[0].urlAfterRedirects.split('?')[0];
          const curUrl = events[1].urlAfterRedirects.split('?')[0];

          if (prevUrl === '/overview' && curUrl === '/home') {
              setTimeout(() => this.shareService.setActionBackPage(true));
          }
        });
    }

    parseQueryParams(): any {
        const urlParams = new URLSearchParams(window.location.search);
        const countryCode = urlParams.get('country');

        const hasCountryCode = this.setupPageService.validateCountryCodeHandler(countryCode?.toLowerCase());

        this.setupPageService.sendCountryCodeForRender(countryCode, hasCountryCode);

        return !!countryCode;
    }

    routeCurrentPageHandler(session): void {
        this.sessionKey = session ? session : this.sessionKey;

        const queryParam = this.sessionKey ? { queryParams: {'': this.sessionKey} } : {};

        this.router.navigate(
            [this.location.path().split('?')[0]],
            queryParam
        );
    }

    removeAllLocalStorageData(): void {
        this.localStorage.removeBlindData('zip-blind-session-saved');
        this.localStorage.removeBlindData('zip-blind-data');
        this.localStorage.removeBlindData('zip-blind-text');
        this.localStorage.removeBlindData('zip-blind-session');
        this.localStorage.removeBlindData('zip-current-blind-id');
        this.localStorage.removeBlindData('zip-blind-temp-session');
        this.localStorage.removeBlindData('zip-blind-progress-saved');
    }

    setDefaultLoader(status): void {
        $('.mc-wrapper').toggleClass('loading', status);
    }

    checkActivePageHandler(): void {
        if (this.currentLink === 'overview') {
            if (!this.sessionKey) {
                this.setEmptyBlindData(true);
            } else if (this.countryLink) {
                this.getDefaultServerDataHandler(this.countryLink);
            }
        }
    }

    getDefaultServerDataHandler(code): void {
        this.getDefaultServerData = this.serverData.GetServerData(this.sessionKey, code).subscribe(res => {
            this.localStorage.setBlindData(res.session_key, 'zip-blind-temp-session');
            this.localStorage.setBlindData(res, 'zip-blind-config');
            this.localStorage.setBlindData(res.text, 'zip-blind-text');

            if (!_.isEmpty(res.blinds)) {
                this.setBlindDataFromServer(res);
            } else {
                this.setEmptyBlindData();
            }

            this.shareService.setCountryPopup(true);
        });
    }

    setBlindDataFromServer(res): void {
        let blindType = this.localStorage.getBlindData('zip-blind-type');

        this.localStorage.setBlindData(res.blinds, 'zip-blind-data');
        this.localStorage.setBlindData(true, 'zip-blind-session-saved');
        this.localStorage.setBlindData(res.session_key, 'zip-blind-session');

        if (!blindType) {
            blindType = res.blinds[res.blinds.length - 1].type;
            this.sessionStorageService.setSession(blindType, 'zip-blind-type');
        }

        this.shareService.setSessionBlindsData(true);

        this.shareService.setLoadingSpinner(false);
        setTimeout(this.setDefaultLoader.bind(this, false));

        this.changeDetection.markForCheck();
        return;
    }

    setEmptyBlindData(status?: boolean): void {
        const getStorageData = this.localStorage.getBlindData('zip-blind-data');

        if (!getStorageData && this.currentLink === 'overview') {
            this.router.navigate(['/home']);
        }

        this.shareService.setLoadingSpinner(false);
        setTimeout(this.setDefaultLoader.bind(this, false));

        if (status) {
            this.parseQueryParams();
        } else if (!getStorageData) {
            this.setupPageService.sendBlindTypeForRender(true);
        }

        this.changeDetection.markForCheck();
        return;
    }

    getUserVisitTime(): void {
        this.userVisitTimer();

        this.getRetailerRequest = this.shareService.getRetailerRequest.subscribe(res => {
            clearInterval(this.timerId);

            this.changeDetection.markForCheck();
        });
    }

    userVisitTimer(): void {
        this.shareService.setUserTime(this.userVisitTime);

        this.timerId = setTimeout(() => {
            this.userVisitTime +=  1;
            this.userVisitTimer();
        }, 1000);
    }
}
