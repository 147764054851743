import {
    Component,
    OnInit,
    Input,
    ChangeDetectorRef,
    OnDestroy,
    OnChanges, ViewChildren, QueryList, Output, EventEmitter
} from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { Subscription } from 'rxjs';
import { SimpleChanges } from '@angular/core';
import { MATERIAL_MESH, MATERIAL_COLORS, SPLINE_MESH } from '../../app.config';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';

declare var $: any;
import * as _ from 'lodash';


@Component({
    selector: 'app-color-change',
    templateUrl: './color-change.component.html',
    styleUrls: ['./color-change.component.scss', './color-change.responsive.scss']
})

export class ColorChangeComponent implements OnInit, OnDestroy, OnChanges {
    getBlindSelect: Subscription;

    @Input() blindId;
    @Output() colorData = new EventEmitter();
    @ViewChildren('colorPicker') colorPicker: QueryList<any>;

    blindSetupData = {};
    color = '';
    opacity = 1;
    type = '';
    cleanPVCStatus = false;
    sunscreenStatus = false;
    serverMaterialData;
    blindType;
    localConfig = this.localStorageService.getBlindData('zip-blind-config');
    localText = this.localStorageService.getBlindData('zip-blind-text');
    materialColors = MATERIAL_COLORS;
    materialData;


    defaultColor = '#999999';

    constructor(
        private engineService: EngineService,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService,
        private shareDataService: ShareService,
        private serverDataService: ServerDataService,
        private saveSessionBlind: SaveSessionBlindsService,
        private screenShotService: ScreenshotService,
        private changeDetection: ChangeDetectorRef
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        const getCurrentBLindId = this.localStorageService.getBlindData('zip-current-blind-id');

        if (changes.blindId.currentValue === getCurrentBLindId) {
            setTimeout(this.setDefaultValue.bind(this));
        }
    }

    ngOnInit(): void {
        this.blindType = this.sessionStorageService.getSession('zip-blind-type');
        this.serverMaterialData = this.localConfig && this.localConfig[this.blindType].material;
        this.getMaterialData();
        this.getStorageColors();
        this.getBlindSelect = this.shareDataService.getBlindSelect.subscribe(this.setBlindSelectId.bind(this));
    }

    ngOnDestroy(): void {
        this.getBlindSelect.unsubscribe();
    }

    getStorageColors(id?: number): void {
        const materialStorage = this.localStorageService.getBlindItemById(id || this.blindId, 'zip-blind-data');

        if (materialStorage) {
            for (const item of materialStorage) {
                if (item.setup && item.setup.material) {
                    this.color = item.setup.material.color;
                    this.type = item.setup.material.type;
                    this.opacity = item.setup.material.opacity;
                    this.sunscreenStatus = item.setup.material.type === '95%_sunscreen_mesh';
                    this.cleanPVCStatus = item.setup.material.type === 'clear_pvc';
                }
            }
        }
    }

    setModelColor(name, hex, opacity): void {
        const meshes = opacity === 1 ? [...MATERIAL_MESH, ...SPLINE_MESH] : MATERIAL_MESH;

        for (const meshId of meshes) {
            this.engineService.setColor(meshId, hex, opacity, 'material');
        }
    }

    onChangeColorTemp(meshId, color): void {
        if (this.blindType === 'outdoor') {
            this.sunscreenStatus = true;
            this.cleanPVCStatus = false;
        }
        this.setModelColor(meshId, color, 1);
    }

    onChangeColor(data): void {
        this.color = data.color;
        this.type = data.type;

        if (!this.cleanPVCStatus) {
            for (const meshId of MATERIAL_MESH) {
                this.onChangeColorTemp(meshId, data.color);
            }
        }

        if (this.blindType === 'interior') {
            const currentMaterial = this.materialData.filter(item => item.type === data.type)[0];
            currentMaterial.color = this.color;
        }

        const materialObject = {
            id: this.materialData.filter(item => item.type === data.type)[0].id,
            name: data.name,
            opacity: data.opacity,
            type: data.type,
            color: data.color,
            color_name: data.color_name
        };

        this.blindSetupData = {
            material: materialObject
        };

        this.saveBlindSetup();
        this.shareDataService.setMaterialColorChanged(materialObject);
    }


    onSelectMesh(isColorPickerOpened): void {
        if (!this.sunscreenStatus && !isColorPickerOpened) {
            $(this.colorPicker.first.nativeElement).find('.mc-color-picker').click();
        }
        this.sunscreenStatus = true;
        this.cleanPVCStatus = false;

        const materialObject = {
            id: this.serverMaterialData[0].id,
            name: this.serverMaterialData[0].name,
            color: this.color,
            opacity: 1,
            type:  this.serverMaterialData[0].type
        };

        this.blindSetupData = {
            material: materialObject
        };
        this.onChangeColorTemp('', !_.isEmpty(this.color) ? this.color : this.defaultColor);
        this.saveBlindSetup();
        this.shareDataService.setMaterialColorChanged(materialObject);
    }

    onClearPVC(): void {
        this.sunscreenStatus = false;
        this.cleanPVCStatus = true;
        this.setModelColor('', '#ffffff', 0.5);

        const colorProperty = {
            name: this.serverMaterialData[1].name,
            color: this.color,
            opacity: 0.5,
            type: 'clear_pvc'
        };

        this.onChangeColor(colorProperty);
        this.shareDataService.setMaterialType(this.serverMaterialData[1].type);
    }

    onInterior(index?: number): void {
        $(this.colorPicker.filter(item => item)[index].nativeElement).find('.mc-color-picker').click();
        this.type = this.materialData[index].type;
        this.color = this.materialData[index].color;

        const materialObject = {
            id: this.serverMaterialData[index].id,
            name: this.serverMaterialData[index].name,
            color: this.color,
            opacity: 1,
            type: this.type
        };

        this.blindSetupData = {
            material: materialObject
        };
        this.onChangeColorTemp('', !_.isEmpty(this.color) ? this.color : this.defaultColor);
        this.saveBlindSetup();
        this.shareDataService.setMaterialColorChanged(materialObject);
    }

    setBlindSelectId(data): void {
        this.blindType = this.localStorageService.getBlindItemById(data?.id, 'zip-blind-data')[0].type;
        this.serverMaterialData = this.localConfig[this.blindType].material;
        this.getMaterialData();
        this.getStorageColors(data.id);

        if (data.status === 'new') {
            this.color = '';
            this.opacity = 1;
            this.type = '';
        }

        if (data.id === this.blindId || data.status === 'new') {
            if (this.blindType === 'outdoor') {
                this.sunscreenStatus = this.type === this.serverMaterialData[0].type;
                this.cleanPVCStatus = this.type === this.serverMaterialData[1].type;
            }

            this.materialData.forEach(item => {
                item.color = item.type === this.type ? this.color : item.color.default;
            });

            const setColorPalette =
                this.cleanPVCStatus
                ? '#ffffff' : !_.isEmpty(this.color)
                    ? this.color : this.defaultColor;
            for (const meshId of MATERIAL_MESH) {
                this.setModelColor(meshId, setColorPalette, this.opacity);
            }

            const colorProperty = {
                color: setColorPalette,
                type:  this.type
            };

            this.shareDataService.setMaterialColorChanged(colorProperty);
        }

        this.changeDetection.markForCheck();
    }

    setDefaultValue(): void {
        const materialConfig = this.localStorageService.getBlindData('zip-blind-config')[this.blindType].material;
        const materialStorage = this.localStorageService.getBlindItemById(this.blindId, 'zip-blind-data');

        if (materialStorage?.[0].setup) {
            return;
        }
        for (const material of materialConfig) {
            if (material.is_default) {
                const colorArr = MATERIAL_COLORS.filter(item =>  material.color.default.toLowerCase() === item.color.toLowerCase())[0];
                this.defaultColor = material.color.default;
                this.color = this.defaultColor;
                this.type = material.type;

                this.materialData.forEach(item => {
                    item.color = item.type === this.type ? this.color : item.color.default;
                });

                const colorProperty = {
                    name: material.name,
                    color: this.defaultColor,
                    color_name: colorArr.name,
                    opacity: 1,
                    type:  material.type
                };
                this.sunscreenStatus = true;
                this.onChangeColor(colorProperty);
            }
        }
    }

    saveBlindSetup(): void {
        this.localStorageService.setBlindSetupDataById(this.blindId, this.blindSetupData, 'zip-blind-data');
        this.saveSessionBlind.PutStorageDataToServer();
        this.shareDataService.setMaterialType(this.serverMaterialData[0].type);
        this.colorData.emit(this.blindSetupData);
    }

    onPopover(popover: NgbPopover, button: HTMLElement): void {
        $(button).toggleClass('active', popover.isOpen());
    }

    getMaterialData(): void {
        this.materialData = this.serverMaterialData.map(item => ({...item}));
    }

}
