import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ShareService } from '@core/services/share-data/share-data.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EngineService } from '@core/services/engine/engine.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';

declare var $: any;

@Component({
    selector: 'app-country-modal',
    templateUrl: './blind-type-modal.component.html',
    styleUrls: ['./blind-type-modal.component.scss', './blind-type-modal.responsive.scss']
})
export class BlindTypeModalComponent implements OnInit, OnDestroy {
    @ViewChild('blindTypeContent') blindTypeContent: ElementRef;

    clicked: boolean = false;

    constructor(
        public shareDataService: ShareService,
        public dialogRef: MatDialogRef<BlindTypeModalComponent>,
        private engineService: EngineService,
        private sessionStorageService: SessionStorageService,

        @Inject(MAT_DIALOG_DATA) public data
    ) {}

    ngOnInit() {
        if (!this.data) {
            $('.cdk-overlay-container').addClass('internal');
        }
    }

    ngOnDestroy() {
        $('.cdk-overlay-container').removeClass('internal');
    }

    onBlindTypeHandler(type): void {
        if (type === 'interior') {
            this.engineService.createScene('interior');
        }

        if (type === 'outdoor') {
            this.engineService.createScene('outdoor');
        }

        this.clicked = true;
        this.sessionStorageService.setSession(type, 'zip-blind-type');
        this.shareDataService.setBlindType(type);
        this.dialogRef.close();
    }

    onClose(): void {
        this.dialogRef.close();
    }
}
