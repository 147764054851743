import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges,
    OnDestroy,
    OnInit,
    Output, SimpleChanges
} from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { INTERIOR_FRAME_TOP_STYLE } from '@root/app.config';
import { Subscription } from 'rxjs';
import { ShareService } from '@core/services/share-data/share-data.service';
import * as _ from 'lodash';

declare var $: any;

@Component({
    selector: 'app-size-change',
    templateUrl: './size-change.component.html',
    styleUrls: ['./size-change.component.scss', './size-change.responsive.scss']
})
export class SizeChangeComponent implements OnInit, OnDestroy, AfterViewChecked, OnChanges {
    @Input() blindId;
    @Output() sizeData = new EventEmitter();

    getBlindSelect: Subscription;
    getMaterialType: Subscription;
    getOperationName: Subscription;
    getSceneCreated: Subscription;

    storageSizes = {
        size: {}
    };

    storageMaterialType;
    serverSizeData;
    currentStorageMaterialType;

    modelSize;
    valueWidth;
    valueHeight;
    modelSizeVert;
    colorType: string;
    getBlindStatus: number | string;
    modelType: string;
    operationName: string;
    currentBlindId: number;

    sizeValue = {
        width: '',
        height: ''
    };

    dataValue = {
        width: '',
        height: ''
    };
    blindType = this.sessionStorageService.getSession('zip-blind-type');
    localConfig = this.localStorageService.getBlindData('zip-blind-config');
    defaultSizeData = this.sessionStorageService.getModelSize('default-model-size');

    setServerData = false;
    hasExtraSizeWidth = false;
    hasExtraSizeHeight = false;
    firstOpenSizeError = false;

    constructor(
        private engineService: EngineService,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService,
        private shareDataService: ShareService,
        private saveSessionBlind: SaveSessionBlindsService,
        private screenShotService: ScreenshotService,
        private changeDetection: ChangeDetectorRef
    ) { }

    sizeConverterParams(type): any {
        const min = type === 'width' ? this.serverSizeData.width.minumum : this.serverSizeData.height.minumum;
        const max = type === 'width' ? this.serverSizeData.width.maximum : this.serverSizeData.height.maximum;

        return {
            minSize: min,
            dY: max - min
        };
    }

    sizeIntoMMConvertationHandler(x, type): any {
        const dX = this.sizeConverterParams(type).minSize - this.sizeConverterParams(type).dY;
        return Math.round(this.sizeConverterParams(type).dY * x + dX);
    }

    sizeIntoPointConvertationHandler(mm, type): any {
        const dX = this.sizeConverterParams(type).minSize - mm;
        return dX < 0 ? Math.round((Math.abs(dX / this.sizeConverterParams(type).dY) + 1) * 1000) / 1000 : 1;
    }

    ngOnInit(): void {
        if (this.localConfig) {
            this.getStorageMaterialType();
            this.getServerDefaultData();
            this.getStorageSizes();
        }

        this.getBlindSelect = this.shareDataService.getBlindSelect.subscribe(res => {
            this.getBlindStatus = res.id;
            this.modelType = this.modelType || res?.type;

            if (this.modelType && (this.modelType === res?.type)) {
                this.setBlindSelectId(res);
            }
        });
        this.getOperationName = this.shareDataService.getOperationName.subscribe(res => this.operationName = res);
        this.getMaterialType = this.shareDataService.getMaterialType.subscribe(res => {
            this.colorType = res;
            if (this.modelType) {
                this.setMaterialTypeSize(this.colorType);
            }
        });
        this.getSceneCreated = this.shareDataService.getSceneCreated.subscribe(res => {
            this.modelType = res;
            const getCurrentBLindId = this.localStorageService.getBlindData('zip-current-blind-id');

            if (this.currentBlindId === getCurrentBLindId) {
                this.setMaterialTypeSize(this.colorType);
            }

            this.setBlindSelectId({id: this.getBlindStatus});
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.currentBlindId = changes.blindId.currentValue;
    }

    ngAfterViewChecked(): void {
        if (this.hasExtraSizeWidth || this.hasExtraSizeHeight) {
            this.openSizeItem();
        }
    }

    ngOnDestroy(): void {
        this.getBlindSelect.unsubscribe();
        this.getMaterialType.unsubscribe();
        this.getSceneCreated.unsubscribe();
    }

    setCorrectSizesHandler(data): void {
        for (const item of data) {
            if (this.storageMaterialType === item.type) {
                this.serverSizeData = item.sizes;
            }
        }
    }

    getStorageSizes(): void {
        const sizeStorage = this.localStorageService.getBlindItemById(this.blindId, 'zip-blind-data');

        if (sizeStorage) {
            for (const item of sizeStorage) {
                if (item.setup && item.setup.size) {
                    this.storageSizes.size = item.setup.size;
                    if (this.blindType === 'interior' && item.type === 'interior') {
                        this.getInteriorTopStyle();
                    }

                    this.dataValue['height'] =  this.storageSizes.size['height'];
                    this.dataValue['width'] =  this.storageSizes.size['width'];

                    if (this.serverSizeData) {
                        this.valueHeight = this.sizeIntoPointConvertationHandler(this.storageSizes.size['height'], 'height');
                        this.valueWidth = this.sizeIntoPointConvertationHandler(this.storageSizes.size['width'], 'width');
                    }
                }
            }
        }
    }

    getStorageMaterialType(): void {
        const sizeStorage = this.localStorageService.getBlindItemById(this.blindId, 'zip-blind-data');

        if (sizeStorage) {
            for (const item of sizeStorage) {
                if (item.setup && item.setup.material) {
                    this.storageMaterialType = item.setup.material.type;
                }
                if (item.setup && item.setup.operation) {
                    this.operationName = item.setup.operation.name;
                }
            }
        }
    }

    updateSetting(type, event): void {
        if (type === 'width') {
            this.modelSize = event;
        }

        if (type === 'height') {
            this.modelSizeVert = event;
        }

        this.saveSessionBlind.PutStorageDataToServer();
    }

    onInputChange(type, event: any): void {
        this.hasExtraSizeWidth =  !!this.hasExtraSizeWidth;
        this.hasExtraSizeHeight = !!this.hasExtraSizeHeight;

        this.sizeValue[type] = this.sizeIntoMMConvertationHandler(event, type);

        if (type === 'width') {
            this.hasExtraSizeWidth = this.sizeValue.width < this.serverSizeData.width.minumum ||
                this.sizeValue.width > this.serverSizeData.width.maximum;
        }
        if (type === 'height') {
            this.hasExtraSizeHeight = this.sizeValue.height < this.serverSizeData.height.minumum ||
                this.sizeValue.height > this.serverSizeData.height.maximum;
        }


        this.engineService.setSize({[type]: event});

        this.storageSizes.size = {
            ...this.storageSizes.size, ...{
                [type]: this.sizeIntoMMConvertationHandler(event, type),
                extraSize: this.hasExtraSizeWidth || this.hasExtraSizeHeight
            }
        };

        if (this.blindType === 'interior') {
            this.getInteriorTopStyle();
        }

        this.dataValue[type] =  this.storageSizes.size[type];

        this.sizeData.emit(this.storageSizes);
        this.localStorageService.setBlindSetupDataById(this.blindId, this.storageSizes, 'zip-blind-data');
        this.shareDataService.setBlindSizeChanged({id: this.blindId});
    }

    onInputChangeHandler(type, event): void {
        let modelSize = event;
        this.hasExtraSizeWidth = !!this.hasExtraSizeWidth;
        this.hasExtraSizeHeight =  !!this.hasExtraSizeHeight;

        if (type === 'width') {
            this.hasExtraSizeWidth = +event < this.serverSizeData.width.minumum ||
                +event > this.serverSizeData.width.maximum;
        }

        if (type === 'height') {
            this.hasExtraSizeHeight = type === 'height' && (+event < this.serverSizeData.height.minumum ||
                +event > this.serverSizeData.height.maximum);
        }


        if (Math.round(event).toString().length > 1) {
            this.setModelSizes(type, event);
            modelSize = this.sizeIntoPointConvertationHandler(event, type);

            if (+this.storageSizes.size[type] !== +event) {
                this.storageSizes.size = {
                    ...this.storageSizes.size, ...{
                        [type]: +event,
                        extraSize: this.hasExtraSizeWidth || this.hasExtraSizeHeight
                    }
                };

                if (this.blindType === 'interior') {
                    this.getInteriorTopStyle();
                }

                this.sizeData.emit(this.storageSizes);
                this.localStorageService.setBlindSetupDataById(this.blindId, this.storageSizes, 'zip-blind-data');
                this.saveSessionBlind.PutStorageDataToServer();
            }
        }

        this.engineService.setSize({
            [type]: modelSize > 2 ? 2 : modelSize <= 0 ? 1 : modelSize
        });
        this.shareDataService.setBlindSizeChanged({id: this.blindId});
    }

    setBlindSelectId(data): void {
        this.blindType = this.localStorageService.getBlindItemById(data?.id, 'zip-blind-data')[0]?.type || this.blindType;
        this.getStorageMaterialType();
        this.getServerDefaultData();
        this.getStorageSizes();

        if (this.setServerData && typeof data !== 'string' && data !== undefined) {
            if (data.status === 'new') {
                this.storageSizes.size = {
                    width: this.serverSizeData.width.default,
                    height: this.serverSizeData.height.default
                };
            }

            if (data.id === this.blindId || data.status === 'new') {
                if (_.isEmpty(this.defaultSizeData)) {
                    for (const key of Object.keys(this.serverSizeData)) {
                        this.setModelSizes(key, this.serverSizeData[key].default);
                    }
                }

                for (const [key, value] of Object.entries(this.storageSizes.size)) {
                    this.setModelSizes(key, value);
                    this.onInputChangeHandler(key, value);
                    this.dataValue[key] = value;
                }
            }

            if (data.id === this.blindId) {
                this.localStorageService.setBlindData(this.blindId, 'zip-current-blind-id');
            }
        }

        this.changeDetection.markForCheck();
    }

    setMaterialTypeSize(type): void {
        this.currentStorageMaterialType = this.storageMaterialType || type;
        this.storageMaterialType = type;
        this.getServerDefaultData();
        this.setDefaultSizeValues();
        this.changeDetection.markForCheck();
    }

    setModelSizes(key, value): void {
        if (key === 'width') {
            this.modelSize = this.sizeIntoPointConvertationHandler(value, key);
            this.valueWidth = this.sizeIntoPointConvertationHandler(value, key);

            this.engineService.setSize({[key]: this.valueWidth});
        }
        if (key === 'height') {
            this.modelSizeVert = this.sizeIntoPointConvertationHandler(value, key);
            this.valueHeight = this.sizeIntoPointConvertationHandler(value, key);

            this.engineService.setSize({[key]: this.valueHeight});
        }
    }

    setDefaultSizeValues(): void {
        if (this.setServerData && _.isEmpty(this.storageSizes.size)) {
            for (const key of Object.keys(this.serverSizeData)) {
                this.setModelSizes(key, this.serverSizeData[key].default);
                this.dataValue[key] = this.serverSizeData[key].default;
            }

            this.setDefaultSizeToStorage();
        }

        if (this.currentStorageMaterialType !== this.storageMaterialType && this.serverSizeData) {
            this.hasExtraSizeWidth = (this.storageSizes.size['width'] < +this.serverSizeData.width.minumum ||
            this.storageSizes.size['width'] > +this.serverSizeData.width.maximum);
            this.hasExtraSizeHeight = (this.storageSizes.size['height'] < +this.serverSizeData.height.minumum ||
            this.storageSizes.size['height'] > +this.serverSizeData.height.maximum);

            if (this.sizeValue.width) {
                this.valueWidth = this.sizeIntoPointConvertationHandler(this.sizeValue.width, 'width');
            }

            if (this.sizeValue.height) {
                this.valueHeight = this.sizeIntoPointConvertationHandler(this.sizeValue.height, 'height');
            }
        }
    }

    setDefaultSizeToStorage(): void {
        this.storageSizes.size = this.dataValue;
        this.sizeData.emit(this.storageSizes);
        this.localStorageService.setBlindSetupDataById(this.blindId, this.storageSizes, 'zip-blind-data');
        this.saveSessionBlind.PutStorageDataToServer();
    }

    getServerDefaultData(): void {
        this.blindType = this.sessionStorageService.getSession('zip-blind-type');
        this.setCorrectSizesHandler(this.localConfig[this.blindType].material);
        this.setServerData = true;
    }

    openSizeItem(): void {
        const title = $('app-size-change').parent('.mc-config-item__content').prev('.mc-config-item__title');

        if (!title.parent().hasClass('active') && !this.firstOpenSizeError) {
            title.click();
            this.firstOpenSizeError = true;
        }
    }

    inputValidate(event: KeyboardEvent) {
        if (event.key === '-' || event.key === '.') {
           event.preventDefault();
        }
    }

    getInteriorTopStyle(): void {
        const setupData = this.localStorageService.getBlindItemById(this.blindId, 'zip-blind-data')[0].setup;
        const width = +this.storageSizes.size['width'];
        const height = +this.storageSizes.size['height'];
        const topStyleId = INTERIOR_FRAME_TOP_STYLE.reduce((acc, x) => !acc && width > x.width && height > x.height ? x.id : acc, '');
        const topStyle = this.localConfig[this.blindType].frame.top_style.filter(el => el.id === topStyleId)[0];

        setupData.frames.top_style = topStyle;

        this.localStorageService.setBlindSetupDataById(this.blindId, setupData, 'zip-blind-data');
    }
}
