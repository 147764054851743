import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { Subscription } from 'rxjs';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { EngineService } from '@core/services/engine/engine.service';
import { SimpleChanges } from '@angular/core';
import { REVEAL_CHANNEL, FACE_FIX, REVEAL_CHANNEL_INTERIOR, FACE_FIX_INTERIOR } from '../../app.config';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';

declare var $: any;

@Component({
    selector: 'app-mounting-change',
    templateUrl: './mounting-change.component.html',
    styleUrls: ['./mounting-change.component.scss', './mounting-change.responsive.scss']
})
export class MountingChangeComponent implements OnInit, OnDestroy, OnChanges {
    @Input() blindId;
    @Output() mountingData = new EventEmitter();

    getBlindSelect: Subscription;
    serverMountingData;
    storageData = {
        mounting: {
            id: '',
            checked: false
        }
    };
    blindType = this.sessionStorageService.getSession('zip-blind-type');
    localConfig = this.localStorageService.getBlindData('zip-blind-config');
    localText = this.localStorageService.getBlindData('zip-blind-text');

    selectors = {
        list: '.mc-mounting__list',
        input: '.mc-mounting__input'
    };
    defaultId;

    constructor(
        private sessionStorageService: SessionStorageService,
        private localStorageService: LocalStorageService,
        private shareDataService: ShareService,
        private saveSessionBlind: SaveSessionBlindsService,
        private screenShotService: ScreenshotService,
        private engineService: EngineService,
        private changeDetection: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.serverMountingData = this.localConfig?.[this.blindType].frame.mounting;

        this.getStorageMounting();
        this.getBlindSelect = this.shareDataService.getBlindSelect.subscribe(this.setBlindSelectId.bind(this));
    }

    ngOnChanges(changes: SimpleChanges): void {
        const getCurrentBLindId = this.localStorageService.getBlindData('zip-current-blind-id');

        if (changes.blindId.currentValue === getCurrentBLindId) {
            setTimeout(this.setDefaultValue.bind(this));
        }
    }

    ngOnDestroy(): void {
        this.getBlindSelect.unsubscribe();
    }

    getStorageMounting(): void {
        const mountingStorage = this.localStorageService.getBlindItemById(this.blindId, 'zip-blind-data');
        if (mountingStorage) {
            for (const item of mountingStorage) {
                if (item.setup && item.setup.mounting) {
                    this.storageData.mounting = this.serverMountingData.find(x => x.id === item.setup.mounting.id);
                    this.storageData.mounting.checked = true;
                    this.modelManagingHandler(this.storageData.mounting.id, 'storage');
                }
            }
        }
    }

    onSubmitRadioOptions(event): void {
        const currentOption = $(event.currentTarget);

        if (!currentOption.prev().is(':checked')) {
            const optionID = currentOption.attr('id');
            this.modelManagingHandler(optionID, event);
        }
    }

    modelManagingHandler(operationId, event): void {
        this.blindType = this.sessionStorageService.getSession('zip-blind-type');
        const getCurrentData = this.serverMountingData.find(x => x.id === +operationId);
        const meshArray = this.blindType === 'outdoor' ? ( +operationId === 1 ? REVEAL_CHANNEL : FACE_FIX)
                                     : ( +operationId === 1 ? REVEAL_CHANNEL_INTERIOR : FACE_FIX_INTERIOR);

        this.engineService.setMounting({
            meshes: meshArray,
            id: operationId,
            type: event.type,
            description: getCurrentData.description
        });

        if (+this.storageData.mounting.id !== +operationId) {
            this.storageData.mounting = {...this.storageData.mounting, ...getCurrentData};
            this.mountingData.emit(this.storageData);
            this.localStorageService.setBlindSetupDataById(this.blindId, this.storageData, 'zip-blind-data');
            this.saveSessionBlind.PutStorageDataToServer();
        }

        this.checkingRadio(getCurrentData);
    }

    setBlindSelectId(data): void {
        this.serverMountingData = this.localConfig[this.blindType].frame.mounting;

        if (data.id === this.blindId) {
            this.getStorageMounting();
        }

        this.changeDetection.markForCheck();
    }

    setDefaultValue(): void {
        const frameConfig = this.localStorageService.getBlindData('zip-blind-config')[this.blindType].frame;
        const materialStorage = this.localStorageService.getBlindItemById(this.blindId, 'zip-blind-data');

        if (materialStorage?.[0].setup.mounting) {
            return;
        }

        for (const item of frameConfig.mounting) {
            if (item.is_default) {
                this.modelManagingHandler(item.id, 'loading');
                this.defaultId = item.id;
            }
        }
    }

    checkingRadio(operationData): void {
        const inputId = `${this.blindId}${operationData.name.split(' ').join('')}`;

        $(`#${inputId}`).prop('checked', true);
    }

    onPopover(popover: NgbPopover, button: HTMLElement): void {
        $(button).toggleClass('active', popover.isOpen());
    }

}
