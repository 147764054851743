import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ShareService } from '@core/services/share-data/share-data.service';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
import * as _ from 'lodash';

@Component({
    selector: 'app-overview-page',
    templateUrl: './overview-page.component.html',
    styleUrls: ['./overview-page.component.scss', './overview-page.responsive.scss']
})
export class OverviewPageComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('retailerInfo') retailerInfo: ElementRef;
    @ViewChild('overviewPage') overviewPage: ElementRef;

    getRetailerInfo: Subscription;
    getLoadingSpinner: Subscription;
    getActivatedRouteRules: Subscription;

    addInfoAvailability = false;

    constructor(
        private shareService: ShareService,
        private localStorageService: LocalStorageService,
        private route: ActivatedRoute,
        private changeDetection: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.getRetailerInfo = this.shareService.getRetailerInfo.subscribe(res => {
            this.addInfoAvailability = res;

            if (this.addInfoAvailability) {
                setTimeout(() => {
                    this.retailerInfo.nativeElement.scrollIntoView({
                        behavior: 'smooth'
                    });
                }, 200);
            }

            this.changeDetection.markForCheck();
        });

        this.getActivatedRouteRules = this.route.data.subscribe(() => {
            this.setSessionStorage();

            this.changeDetection.markForCheck();
        })

        this.setLoadingHandler();
    }

    ngAfterViewInit() {
        this.getLoadingSpinner = this.shareService.getLoadingSpinner.subscribe(res => {

            setTimeout(() => {
                $(this.overviewPage.nativeElement).find('.mc-overview-section').toggleClass('loading', res);
            });

            this.changeDetection.markForCheck();
        });
    }

    setSessionStorage(): void {
        const localStorageSession = this.localStorageService.getBlindData('zip-blind-session');

        if (localStorageSession) {
            this.shareService.setLinkSessionKey(localStorageSession);
        }
    }

    setLoadingHandler(): void {
        const savedStatus = this.localStorageService.getBlindData('zip-blind-session-saved');
        const savedData = this.localStorageService.getBlindData('zip-blind-data');

        if (savedStatus && _.isEmpty(savedData)) {
            setTimeout(() => {
                $(this.overviewPage.nativeElement).find('.mc-overview-section').addClass('loading');
            });
        }
    }

    ngOnDestroy(): void {
        this.getRetailerInfo.unsubscribe();
        this.getActivatedRouteRules.unsubscribe();
    }
}
