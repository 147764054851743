<div class="mc-country-title">{{'modals.country.title' | translate}}</div>
<div class="mc-country-modal__title">{{'modals.country.subtitle' | translate}}</div>
<div #countryContent class="mc-country-modal">
    <div class="mc-country-modal__content">
        <div *ngFor="let country of data"
             [attr.data-code]="country.code"
             class="mc-country-modal__item {{country.code}}"
             (click)="setCountrySite($event)">
            {{country.name}}
        </div>
    </div>
</div>
