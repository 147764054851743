import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  setBlindData(data, storage): void {
    localStorage.setItem(storage, JSON.stringify(data));
  }

  setBlindSetupDataById(itemId, data, storage): void {
      const blinds: any = localStorage.getItem(storage);
      const parsedData = JSON.parse(blinds);

      if (parsedData) {
          const blindArray = _.filter(parsedData, { blind_id: itemId } );
          const getBlindById = parsedData.findIndex(x => x.blind_id === itemId);

          for (const blindObject of blindArray) {
              blindObject.setup = {...blindObject.setup, ...data};

              parsedData[getBlindById] = blindObject;
          }

          this.setBlindData(parsedData, storage);
      }
  }

  getBlindData(storage): any {
    return JSON.parse(localStorage.getItem(storage));
  }

  removeBlindData(storage): any {
      localStorage.removeItem(storage);
  }

  getBlindItemById(itemId, storage): any {
    const blinds: any = localStorage.getItem(storage);

    if (blinds) {
      return _.filter(JSON.parse(blinds), { blind_id: itemId } );
    }
  }
}
