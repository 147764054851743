import {
    ChangeDetectorRef,
    Component,
    ElementRef, EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { EngineService } from '@core/services/engine/engine.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { Subscription } from 'rxjs';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import {
    TOP_STYLE_PELMET,
    COLOR_FRAME_PELMET,
    MESHES_IDS,
    FRAME_COLORS,
    TOP_STYLE_IDS,
    BOTTOM_BAR_IDS,
    SPLINE_MESH,
    INTERIOR_COLOR_FRAME_PELMET,
    BOTTOM_CHANNEL_HANDLE,
    INTERIOR_FRAME_TOP_STYLE,
    INTERIOR_END_CAPS
} from '../../app.config';
import { SimpleChanges } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
import * as _ from 'lodash';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';

@Component({
    selector: 'app-frame-change',
    templateUrl: './frame-change.component.html',
    styleUrls: ['./frame-change.component.scss', './frame-change.responsive.scss']
})
export class FrameChangeComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('frameColorItem') frameColorItem: ElementRef;
    @ViewChild('frameTopStyleItem') frameTopStyleItem: ElementRef;
    @ViewChild('frameBottomBarItem') frameBottomBarItem: ElementRef;

    @Input() blindId;
    @Output() frameData = new EventEmitter();
    frameColor;
    otherColor;
    customColor;
    materialType;
    getBlindSelect: Subscription;
    getMaterialColorChanged: Subscription;

    frameColorObject = {
        color: '',
        id: 0,
        name: '',
        is_custom: false
    };

    frameBottomBarObject = {
        id: 0,
        name: '',
        item: '',
        meshes: []
    };

    frameTopStyleObject = {
        id: 0,
        name: '',
        meshes: []
    };
    framesData = {
        frames: {
            top_style: {},
            frame_color: {},
            optionals: [],
        }
    };
    blindType = this.sessionStorageService.getSession('zip-blind-type');
    localConfig = this.localStorageService.getBlindData('zip-blind-config');
    localText = this.localStorageService.getBlindData('zip-blind-text');

    defaultColor = '';
    defaultUncolored = '#818181';
    defaultMeshes = TOP_STYLE_PELMET;
    defaultBottomBar = MESHES_IDS[22];
    frameColors = FRAME_COLORS;
    customBottomBarId = 4;

    frames = [];
    topStyle = [];
    bottomBar = [];
    optionals = [];

    constructor(
        private engineService: EngineService,
        private shareDataService: ShareService,
        private sessionStorageService: SessionStorageService,
        private localStorageService: LocalStorageService,
        private saveSessionBlind: SaveSessionBlindsService,
        private changeDetection: ChangeDetectorRef
    ) { }

    static activeClassHandler(container, selector): void {
        for (const item of $(container).children()) {
            $(item).removeClass('active');
            $(selector).addClass('active');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const getCurrentBLindId = this.localStorageService.getBlindData('zip-current-blind-id');

        if (changes.blindId.currentValue === getCurrentBLindId) {
            setTimeout(this.setDefaultValue.bind(this));
        }
    }

    ngOnInit(): void {
        this.getStorageFrame();

        this.frames = this.localConfig?.[this.blindType].frame.frame_color;

        if (this.blindType === 'outdoor') {
            this.topStyle = this.localConfig?.[this.blindType].frame.top_style;
            this.bottomBar = this.localConfig?.[this.blindType].frame.bottom_bar_protection;
        } else {
            this.optionals = this.localConfig?.[this.blindType].frame.optionals;
        }

        this.defaultColor = this.localConfig?.[this.blindType].frame.frame_color.filter(item => item.is_default === true)[0].color;

        this.getBlindSelect = this.shareDataService.getBlindSelect.subscribe(res => {
            this.setBlindSelectId(res);
        });
        this.getMaterialColorChanged = this.shareDataService.getMaterialColorChanged.subscribe(this.getMaterialType.bind(this));
    }

    ngOnDestroy(): void {
        this.getBlindSelect.unsubscribe();
        this.getMaterialColorChanged.unsubscribe();
    }

    getStorageFrame(id?: number): void {
        const getBlindType = this.sessionStorageService.getSession('zip-blind-type');
        const frameStorage = this.localStorageService.getBlindItemById(id || this.blindId, 'zip-blind-data');

        if (frameStorage) {
            for (const item of frameStorage) {
                if (item.setup && item.setup.frames) {
                    this.framesData = item.setup;
                    this.frameColorObject = item.setup.frames.frame_color;

                    if (getBlindType === 'outdoor') {
                        this.frameTopStyleObject = item.setup.frames.top_style;
                        this.frameBottomBarObject = item.setup.frames.bottom_bar;
                    } else if (getBlindType === 'interior') {
                        this.optionals = !_.isEmpty(item.setup.frames.optionals) ? item.setup.frames.optionals : this.optionals;

                        if (!_.isEmpty(this.optionals)) {
                            const bottomChannelOption = this.framesData.frames.optionals.filter(x => x.id === 1)[0];
                            const hasBottomChannel = bottomChannelOption && bottomChannelOption.is_checked;

                            this.engineService.setBottomChannel({
                                meshes: BOTTOM_CHANNEL_HANDLE,
                                state: hasBottomChannel,
                                type: 'storage'
                            });
                        }
                    }
                }
            }
        }
    }

    onSelectColor(data, event): void {
        this.getInteriorTopStyle();
        let framesData;
        this.frameColor = data.color;

        if (event) {
            FrameChangeComponent.activeClassHandler(this.frameColorItem.nativeElement, event.currentTarget);
        }
        if (data.event) {
            this.otherColor = data.color;
            FrameChangeComponent.activeClassHandler(this.frameColorItem.nativeElement, $(`.mc-frame__item-block.other[data-blind-id="${this.blindId}"]`));
        }

        if (_.isEmpty(this.framesData.frames)) {
            framesData = this.framesData = {
                frames: {
                    ...this.framesData.frames,
                    frame_color: {
                        id: data.id,
                        name: data.name,
                        color: data.color,
                        color_name: data.color_name
                    }
                }
            };
        } else {
            const frameColorChanged = {
                frame_color: {
                    id: data.id,
                    name: data.name,
                    color: data.color,
                    color_name: data.color_name
                }
            };
            framesData = {
                frames: {...this.framesData.frames, ...frameColorChanged}
            };
            this.framesData.frames = {...this.framesData.frames, ...frameColorChanged};
        }
        this.setModelColor(data.color);
        this.frameColorObject.is_custom = !_.isEmpty(data.is_custom);
        this.frameData.emit(framesData);
        this.localStorageService.setBlindSetupDataById(this.blindId, framesData, 'zip-blind-data');
        this.saveSessionBlind.PutStorageDataToServer();
    }

    onSelectTopStyle(data, event): void {
        if (event) {
            FrameChangeComponent.activeClassHandler(this.frameTopStyleItem.nativeElement, event.currentTarget);
        }

        const topStyleMeshes = TOP_STYLE_IDS.filter(item => item.id === data.id)[0].meshes;

        const topStyleData = {
            top_style: {
                id: data.id,
                name: data.name
            }
        };
        this.engineService.setTopStyle({
            meshes: topStyleMeshes,
            type: event.type,
            description: data.description
        });

        this.framesData.frames = {...this.framesData.frames, ...topStyleData};
        this.frameData.emit(this.framesData);
        this.localStorageService.setBlindSetupDataById(this.blindId, this.framesData, 'zip-blind-data');
        this.saveSessionBlind.PutStorageDataToServer();
    }

    onSelectBottomBar(data, event): void {
        if (event) {
            FrameChangeComponent.activeClassHandler(this.frameBottomBarItem.nativeElement, event.currentTarget);
        }

        const bottomBarMeshes = BOTTOM_BAR_IDS.filter(item => item.id === data.id)[0].meshes;

        const bottomBarData = {
            bottom_bar: {
                id: data.id,
                name: data.name
            }
        };
        this.engineService.setBottomBar({
            meshes: bottomBarMeshes,
            type: event.type,
            description: data.description
        });

        this.framesData.frames = {...this.framesData.frames, ...bottomBarData};
        this.frameData.emit(this.framesData);
        this.localStorageService.setBlindSetupDataById(this.blindId, this.framesData, 'zip-blind-data');
        this.saveSessionBlind.PutStorageDataToServer();
    }

    onSelectBottomChannel(data, event): void {
        this.getInteriorTopStyle();

        this.engineService.setBottomChannel({
            meshes: BOTTOM_CHANNEL_HANDLE,
            state: data.is_checked,
            type: event.type,
            description: data.name
        });

        this.frameData.emit(this.framesData);
        this.localStorageService.setBlindSetupDataById(this.blindId, this.framesData, 'zip-blind-data');
        this.saveSessionBlind.PutStorageDataToServer();
    }

    setBlindSelectId(data): void {
        this.getStorageFrame(data?.id);
        this.blindType = this.localStorageService.getBlindItemById(data?.id, 'zip-blind-data')[0].type;
        this.frames = this.localConfig[this.blindType].frame.frame_color;

        if (data.status === 'new') {
            this.frameColorObject.color = this.defaultColor;

            if (this.blindType === 'outdoor') {
                this.frameBottomBarObject.item = this.defaultBottomBar;
            }
        }

        if (this.blindType === 'outdoor') {
            const topStyleData = TOP_STYLE_IDS.filter(item => item.id === this.frameTopStyleObject?.id);
            const bottomBarData = BOTTOM_BAR_IDS.filter(item => item.id === this.frameBottomBarObject?.id);

            this.topStyle = this.localConfig[this.blindType].frame.top_style;
            this.bottomBar = this.localConfig[this.blindType].frame.bottom_bar_protection;
            this.frameTopStyleObject.meshes = topStyleData[0]?.meshes || this.defaultMeshes;
            this.frameBottomBarObject.meshes = bottomBarData[0]?.meshes || [this.defaultBottomBar];
        }

        if (data.id === this.blindId || data.status === 'new') {
            const frameColor = this.frameColorObject.is_custom ? this.defaultUncolored : _.isEmpty(this.frameColorObject.color) ?
                this.defaultColor : this.frameColorObject.color;
            this.customColor = this.frameColorObject.is_custom ?  this.frameColorObject.color : '';

            this.setModelColor(frameColor);

            if (this.blindType === 'outdoor') {
                const frameMeshes = _.isEmpty(this.frameTopStyleObject) && _.isEmpty(this.frameTopStyleObject.meshes) ?
                    this.defaultMeshes : this.frameTopStyleObject.meshes;

                const frameBottomBar = _.isEmpty(this.frameBottomBarObject) && _.isEmpty(this.frameBottomBarObject.meshes) ?
                    this.defaultMeshes : this.frameBottomBarObject.meshes;

                this.engineService.setTopStyle({
                    meshes: frameMeshes,
                    type: 'storage'
                });
                this.engineService.setBottomBar({
                    meshes: frameBottomBar,
                    type: 'storage'
                });
            }

            if (!_.isEmpty(this.frameColorObject.name) && this.frameColorObject.name === 'Other') {
                this.frameColor = frameColor;
                this.otherColor = frameColor;
            }
        }

        this.changeDetection.markForCheck();
    }

    setModelColor(color): void {
        const getBlindType = this.sessionStorageService.getSession('zip-blind-type');
        const getBlindSetup = this.localStorageService.getBlindItemById(this.blindId, 'zip-blind-data')[0];
        let meshes;

        if (getBlindType === 'outdoor') {
            meshes = this.materialType === this.localConfig[getBlindType].material[0].type ? COLOR_FRAME_PELMET
                : [...COLOR_FRAME_PELMET, ...SPLINE_MESH];
        } else {
            meshes =  INTERIOR_COLOR_FRAME_PELMET;

            if (getBlindSetup.setup?.size) {
                const width = getBlindSetup.setup.size['width'];
                const height = getBlindSetup.setup.size['height'];
                const topStyleId = INTERIOR_FRAME_TOP_STYLE.reduce((acc, x) => !acc && width > x.width && height > x.height ? x.id : acc, '');
                const topStyle = this.localConfig[getBlindType].frame.top_style.filter(el => el.id === topStyleId)[0];

                meshes = topStyle.id === INTERIOR_FRAME_TOP_STYLE[0].id ? [...meshes, ...INTERIOR_END_CAPS] : meshes;
            }
        }

        for (const meshId of meshes) {
            this.engineService.setColor(meshId, color, 1, 'frame');
        }
    }

    setBottomBarColor(color, meshes): void {
        for (const meshId of meshes) {
            this.engineService.setColor(meshId, color, 1, 'frame');
        }
    }

    setDefaultValue(): void {
        const getBlindType = this.sessionStorageService.getSession('zip-blind-type');
        const frameStorage = this.localStorageService.getBlindItemById(this.blindId, 'zip-blind-data');
        const frameConfig = this.localStorageService.getBlindData('zip-blind-config')[getBlindType].frame;

        if (_.isEmpty(frameStorage[0] && frameStorage[0].setup.frames?.frame_color)) {
            for (const color of frameConfig.frame_color) {
                if (color.is_default) {
                    this.defaultColor = color.color;
                    this.onSelectColor(color, false);
                }
            }
        }

        if (_.isEmpty(frameStorage[0] && frameStorage[0].setup.frames?.top_style) && frameConfig.top_style) {
            for (const topStyle of frameConfig.top_style) {
                if (topStyle.is_default) {
                    this.onSelectTopStyle(topStyle, false);
                }
            }
        }

        if (_.isEmpty(frameStorage[0] && frameStorage[0].setup.frames?.bottom_bar) && frameConfig.bottom_bar_protection) {
            for (const bottomBar of frameConfig.bottom_bar_protection) {
                if (bottomBar.is_default) {
                    this.defaultBottomBar = bottomBar.name;
                    this.onSelectBottomBar(bottomBar, false);
                }
            }
        }

        if (_.isEmpty(frameStorage[0] && frameStorage[0].setup.frames?.optionals) && frameConfig.optionals) {
            this.framesData.frames.optionals = [...frameConfig.optionals];
            this.onSelectBottomChannel(frameConfig.optionals[0], false);
        }
    }

    onInputCustomColor(event, color): void {
        let framesData;
        framesData = this.framesData.frames;
        const lastSavedColor =
            framesData.frame_color.last_saved_color
                ? framesData.frame_color.last_saved_color
                : !framesData.frame_color.is_custom
                    ? framesData.frame_color.color
                    : this.defaultColor;
        color = color.trim();

        if (!_.isEmpty(this.framesData.frames) && !_.isEmpty(color)) {
            const colorProperty = {
                color,
                color_name: color,
                last_saved_color: lastSavedColor,
                id: 0,
                name: 'Custom',
                is_custom: true
            };

            this.customColor = color;
            this.frameColorObject = colorProperty;
            framesData.frame_color = this.frameColorObject;
            this.frameColor = this.defaultUncolored;
            FrameChangeComponent.activeClassHandler(this.frameColorItem.nativeElement, false);
            this.setModelColor(this.defaultUncolored);
        }
        if (_.isEmpty(color) || (!event.target.checked && event.type === 'change')) {
            const elem = $(this.frameColorItem.nativeElement).find(`[data-color="${lastSavedColor}"]`);
            this.frameColorObject.is_custom = false;

            if (elem.hasClass('other')) {
                const otherColor = {
                    id: 99,
                    name: 'Other',
                    color: lastSavedColor
                };
                elem.addClass('active');
                this.onSelectColor(otherColor, false);
            } else {
                elem.click();
            }
        }
        if (event.type === 'blur' || event.type === 'change' || event.type === 'focus') {
            this.frameData.emit(this.framesData);
            this.localStorageService.setBlindSetupDataById(this.blindId, this.framesData, 'zip-blind-data');
            this.saveSessionBlind.PutStorageDataToServer();
        }
    }

    getMaterialType(colorProperty): void {
        const getBlindType = this.sessionStorageService.getSession('zip-blind-type');
        const isCurrentBlind = this.localStorageService.getBlindData('zip-current-blind-id') === this.blindId;
        const meshes = [...BOTTOM_BAR_IDS.filter(item => item.id === this.customBottomBarId)[0].meshes];
        this.materialType = colorProperty.type;

        // Sunscreen type
        if (colorProperty.type === this.localConfig[getBlindType].material[0].type && isCurrentBlind) {
            meshes.push(...SPLINE_MESH);
            this.setBottomBarColor(colorProperty.color, meshes);
        }

        // PVC type
        if (colorProperty.type === this.localConfig[getBlindType].material[1].type && this.frameColor && isCurrentBlind) {
            this.setBottomBarColor(this.defaultUncolored, meshes);
            this.setModelColor(this.frameColor);
        }
    }

    getInteriorTopStyle() {
        const top_style = this.localStorageService.getBlindItemById(this.blindId, 'zip-blind-data')[0].setup.frames?.top_style;

        this.framesData.frames = {...this.framesData.frames, top_style};
    }

    onPopover(popover: NgbPopover, button: HTMLElement): void {
        $(button).toggleClass('active', popover.isOpen());
    }

    onSubmitCheckboxOptions(event): void {
        const currentOption = $(event.currentTarget);
        const isChecked = currentOption.prev().prop('checked');
        const optionID = currentOption[0].id;

        const {...getCurrentData} = this.optionals.find(x => x.id === +optionID);
        getCurrentData.is_checked = !isChecked;

        const index = this.framesData.frames.optionals.findIndex(x => x.id === +optionID);
        this.framesData.frames.optionals[index] = getCurrentData;

        if (optionID === '1') {
            this.onSelectBottomChannel(getCurrentData, event);
        }
    }
}
