<div class="mc-modal__content">
	<div class="mc-modal__close" (click)="closeDialog()"></div>
	<div class="mc-modal__icon"></div>
	<div class="mc-modal__title">{{'modals.leave.title' | translate}}</div>
	<div class="mc-modal__subtitle">{{'modals.leave.subtitle' | translate}}</div>
	<div class="mc-modal__buttons">
		<div class="mc-modal__button" (click)="leave()">{{'modals.leave.buttons.leave' | translate}}</div>
		<div class="mc-modal__button mc-modal__button--yellow"
			 (click)="closeDialog()">{{'modals.leave.buttons.continue' | translate}}</div>
	</div>
</div>
