import { ElementRef, Injectable, NgZone } from '@angular/core';
import {
    Animation,
    ArcRotateCamera,
    ArcRotateCameraPointersInput,
    Color3,
    Color4,
    DirectionalLight,
    DynamicTexture,
    Engine,
    FreeCamera,
    HemisphericLight,
    MeshBuilder,
    PointLight,
    Scene,
    SceneLoader,
    ShadowGenerator,
    Space,
    StandardMaterial,
    Texture,
    Tools,
    Vector3
} from '@babylonjs/core';

import * as GUI from '@babylonjs/gui';
import '@babylonjs/loaders';

import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { ShareService } from '@core/services/share-data/share-data.service';
import { CalculateSizeService } from '@core/services/calculateSize/calculate-size.service';

import { Observable, Subject } from 'rxjs';
import {
    COLOR_FRAME_PELMET,
    Config,
    FACE_FIX,
    GLOBAL_HIDDEN_PELMET,
    GLOBAL_PELMET,
    GLOBAL_STRIP,
    INTERIOR_COLOR_FIXTURES, INTERIOR_COLOR_FRAME_PELMET,
    INTERIOR_FRAME_TOP_STYLE,
    INTERIOR_HEIGHT_MESH, INTERIOR_HEIGHT_POSITION_MESH,
    INTERIOR_MESHES_IDS, INTERIOR_SCALING_MESHES,
    INTERIOR_WIDTH_MESH,
    INTERIOR_WIDTH_POSITION_MESH,
    MATERIAL_COLORS,
    MATERIAL_MESH,
    MESHES_IDS,
    REVEAL_CHANNEL,
    SCALING_MESHES,
    SPLINE_MESH
} from '../../../app.config';
import * as _ from 'lodash';

@Injectable({
providedIn: 'root'
})
export class EngineService {
    private canvas: HTMLCanvasElement;
    public engine: Engine;
    public camera: ArcRotateCamera;
    public scene: Scene;
    public light: DirectionalLight;

    colorEvents = new Subject();
    sizeEvents = new Subject();
    topStyleEvents = new Subject();
    bottomBarEvents = new Subject();
    operationEvents = new Subject();
    mountingEvents = new Subject();
    reverseEvents = new Subject();
    bottomChannelEvents = new Subject();

    meshPosition = {};
    meshScaling = {};
    defaultSize = {
        interior: {
            width: 0,
            height: 0,
        },
        outdoor: {
            width: 0,
            height: 0,
        }
    };
    box;

    isModelCreated = false;
    cameraRotation = 3.6;
    isModelMoved = false;
    isZoomedIn = false;
    currentPosition = new Vector3(0, 0, 0);
    clicked = false;
    drag = false;
    modelDragging = false;
    currentAlpha = 0.45;
    currentBeta = 0;
    modelPosition = 'front';
    currentBehaviour = 'rotate';
    lowerRadius = 0.7;
    middleRadius = 1.2;
    upperRadius = 2.5;
    currentHeight = 0;
    currentWidth = 1.349;
    fontSize = 20;
    rect;
    label;
    target;
    line;
    advancedTexture;
    ground;
    materialOpacity;

    pickResult;

    defaultStyles = {
        color: false,
        sizeWidth: false,
        sizeHeight: false,
        topStyle: false,
        bottomBar: false,
        operation: false,
        mounting: false,
        bottomChannel: false
    };
    breakpoints = Config.breakpoints;

    baseUrl = '../../../../assets/model/'; // TODO change to REST API
    blindInterior = 'interior-latest.glb';
    blindOutdoor = 'blind-final-by-logis-optimizied-2.glb';

    shutter = {
        value: 1,
        materialScale: 0,
        meshScale: 0,
        materialPosition: 0,
        outdoor: {
            minScale: 0.0868,
            minMeshScale: 0.09,
            maxPosition: 0.045,
            meshPositions: {},
        },
        interior: {
            minScale: 0.26,
            maxPosition: 0.078,
            meshPositions: {},
        }
    };

    constructor(
        private ngZone: NgZone,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService,
        private shareDataService: ShareService,
        private calculationSizeService: CalculateSizeService
    ) { }

    public init(canvas: ElementRef<HTMLCanvasElement>): void {
        const sessionBlindType = this.sessionStorageService.getModelSize('zip-blind-type');
        this.currentBehaviour = 'rotate';
        this.canvas = canvas.nativeElement;
        this.engine = new Engine(this.canvas,  true);
        this.createScene(sessionBlindType ? sessionBlindType : 'outdoor');
    }

    public createScene(type): void {
        const defaultSizeData = this.sessionStorageService.getModelSize('default-model-size');
        this.defaultSize = defaultSizeData ? defaultSizeData : this.defaultSize;
        this.scene = new Scene(this.engine);
        // this.scene.clearColor = new Color4(0.76953125, 0.7421875, 0.71875, 1);
        this.scene.clearColor = new Color4(0, 0, 0, 0);
        this.camera = new ArcRotateCamera('camera', 3.6, Math.PI / 2,
            this.upperRadius, Vector3.Zero(), this.scene);

        const blindType = type === 'outdoor' ? this.blindOutdoor : this.blindInterior;
        SceneLoader.LoadAssetContainerAsync(this.baseUrl, blindType, this.scene)
            .then(container => this.setupSceneHandler(container))
            .then(() => {
                this.setDefaultView();
                this.lightGeneralSetupHandler();
                // this.showAxis(1, this.scene);
                this.groundGeneralSetupHandler();
                this.isModelCreated = true;
                this.shareDataService.setSceneCreated(type);
            });

        this.scene.onPointerDown = () => this.scenePointerDownListener();
        this.scene.onPointerMove = () => this.scenePointerMoveListener();

        this.canvas.addEventListener('wheel', event => this.zoomHandler(event));
        this.canvas.addEventListener('pointerup', () => this.canvasPointerUpEventListener());
        this.canvas.addEventListener('pointermove', () => this.canvasPointerMoveEventListener());
        this.canvas.addEventListener('touchmove', event => this.canvasTouchEventListener(event));
        this.canvas.addEventListener('pointerleave', () => {
            this.clicked = false;
        });

        this.canvas.addEventListener('pointerout', () => {
            this.clicked = false;
        });

        this.getColor().subscribe(res => this.getColorSceneHandler(res));
        this.getSize().subscribe(res => this.getSizeSceneHandler(res));
        this.getTopStyle().subscribe(res => this.getTopStyleSceneHandler(res));
        this.getBottomBar().subscribe(res => this.getBottomBarSceneHandler(res));
        this.getOperation().subscribe(res => this.getOperationSceneHandler(res));
        this.getReverse().subscribe(res => this.getReverseSceneHandler(res));
        this.getMounting().subscribe(res => this.getMountingSceneHandler(res));
        this.getBottomChannel().subscribe(res => this.getBottomChannelHandler(res));
        this.touchHandler(this.lowerRadius);
    }

    scenePointerDownListener(): void {
        this.clicked = true;
        this.canvas.style.cursor = 'url(../../../../assets/icons/cursor_move.svg) 30 15, grabbing';
        this.shareDataService.setCursorGrab({
            cursor: this.canvas.style.cursor,
            clicked: this.clicked
        });
    }

    scenePointerMoveListener(): void {
        this.pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);
        if (!this.drag) {
            this.canvas.style.cursor = 'url(../../../../assets/icons/cursor_click.svg) 30 15, grab';
        } else if (this.drag) {
            this.canvas.style.cursor = 'url(../../../../assets/icons/cursor_move.svg) 30 15, grabbing';
            if (this.camera.radius === this.lowerRadius) {
                this.calculateCameraRotations();
            }
        } else {
            this.canvas.style.cursor = 'default';
        }
    }
    canvasPointerMoveEventListener(): void {
        if (!this.clicked) {
            return;
        }
        if (!this.isModelMoved) {
            this.isModelMoved = true;
        }
        this.drag = true;
        this.modelDragging = true;
        this.canvas.style.cursor = 'url(../../../../assets/icons/cursor_move.svg) 30 15, grabbing';
    }

    canvasPointerUpEventListener(): void {
        this.shareDataService.setCursorPointer({
            cursor: this.canvas.style.cursor,
            clicked: this.clicked,
            zoomIn: this.camera.radius !== this.upperRadius
        });
        this.canvas.style.cursor = 'url(../../../../assets/icons/cursor_click.svg) 30 15, grab';
        this.drag = false;
        this.clicked = false;
        this.calculateModelRotations();
    }

    canvasTouchEventListener(e): void {
        if (e.targetTouches.length === 2) {
            const input = this.camera.inputs.attached.pointers;
            // @ts-ignore
            input.pinchZoom = false;

            if (this.camera.radius === this.upperRadius) {
                // @ts-ignore
                input.panningSensibility = 500;
                this.zoomInHandler();
                // @ts-ignore
                input.pinchPrecision = 100;

                this.shareDataService.setCursorPointer({
                    cursor: this.canvas.style.cursor,
                    clicked: this.clicked,
                    zoomIn: true
                });
            }

            if (this.camera.radius - 0.2 >= this.upperRadius) {
                this.zoomOutHandler(1500);
            }
        }
    }

    getMountingSceneHandler(res): void {
        const getBlindType = this.sessionStorageService.getSession('zip-blind-type') || 'outdoor';
        for (const mesh of REVEAL_CHANNEL) {
            if (this.scene.getMeshByName(mesh)) {
                this.scene.getMeshByName(mesh).setEnabled(+res.id !== 2);
            }
        }

        for (const mesh of FACE_FIX) {
            if (this.scene.getMeshByName(mesh)) {
                this.scene.getMeshByName(mesh).setEnabled(+res.id !== 1);
            }
        }

        if (this.defaultStyles.mounting && res.type === 'click') {
            const targetZ = -0.55 * this.currentWidth + 0.2;
            const targetY = 0.4 * this.currentHeight - 0.6;
            this.lowerRadius = 0.6 * this.currentWidth;
            this.zoomOnSelectedMesh(
                targetZ,
                targetY,
                this.cameraRotation + 1.5
            );
            this.hintDrivingHelper(res, 'mounting');
        }

        this.defaultStyles.mounting = true;
        this.boxStatus(!Object.values(this.defaultStyles).every(item => item));
        this.shareDataService.setModelLoaded(Object.values(this.defaultStyles).every(item => item));
        this.setFontSize();
    }

    getReverseSceneHandler(res): void {
        for (const mesh of res.meshes) {
            if (this.scene.getMeshByName(mesh)) {
                this.scene.getMeshByName(mesh).setEnabled(res.state);
            }
        }

        if (this.defaultStyles.operation && res.type === 'click') {
            this.lowerRadius = -0.1 * this.currentWidth + 0.7;
            const targetY = -0.55 * this.currentHeight * this.shutter.value + 0.35;
            this.zoomOnSelectedMesh(
                -0.25,
                targetY,
                this.cameraRotation + 2
            );

            this.hintDrivingHelper(res, 'reverse');
        }

        if (!res.state) {
            this.isHintsVisible();
        }
    }

    targetModelYZ(type): any {
        const getBlindType = this.sessionStorageService.getSession('zip-blind-type') || 'outdoor';
        let targetZ;
        let targetY;

        this.lowerRadius = 0.6 * this.currentWidth;

        if (type === 'operation') {
            if (window.innerWidth > this.breakpoints['tablet-portrait']) {
                const targetCofY = (getBlindType === 'outdoor' ? -0.5 : -0.3) * this.shutter.value ;
                targetZ = -0.3 * this.currentWidth + 0.2;
                targetY = targetCofY * this.currentHeight + 0.3;
            }
            if (window.innerWidth <= this.breakpoints['tablet-portrait']) {
                targetZ = -0.35 * this.currentWidth + 0.2;
                targetY = getBlindType === 'outdoor' ? -0.5 * this.currentHeight + 0.3 : -0.3 * this.currentHeight + 0.3;
            }
        }

        if (type === 'bottom') {
            if (window.innerWidth > this.breakpoints['tablet-portrait']) {
                const targetCofY = (getBlindType === 'outdoor' ? -0.5 * this.shutter.value : -0.3);
                targetZ = -0.3 * this.currentWidth + 0.2;
                targetY = targetCofY * this.currentHeight + 0.3;
            }
            if (window.innerWidth <= this.breakpoints['tablet-portrait']) {
                targetZ = -0.2 * this.currentWidth + 0.2;
                targetY = -0.5 * this.currentHeight + 0.3;
            }
        }

        return {
            Y: targetY,
            Z: targetZ
        };
    }

    getOperationSceneHandler(res): void {
        for (const mesh of res.meshes) {
            if (this.scene.getMeshByName(mesh)) {
                this.scene.getMeshByName(mesh).setEnabled(+res.id !== 2);
            }
        }

        if (this.defaultStyles.operation && res.type === 'click') {
            this.zoomOnSelectedMesh(
                this.targetModelYZ('operation').Z,
                this.targetModelYZ('operation').Y,
                this.cameraRotation + 0.5
            );
            this.hintDrivingHelper(res, 'operation');
        }

        this.defaultStyles.operation = true;
    }

    getBottomBarSceneHandler(res): void {
        for (const meshId of GLOBAL_STRIP) {
            if (this.scene.getMeshByName(meshId)) {
                this.scene.getMeshByName(meshId).setEnabled(false);
            }
        }

        for (const meshId of res?.meshes) {
            if (this.scene.getMeshByName(meshId)) {
                this.scene.getMeshByName(meshId).setEnabled(true);
            }
        }

        if (this.defaultStyles.bottomBar && res.type === 'click') {
            this.zoomOnSelectedMesh(
                this.targetModelYZ('bottom').Z,
                this.targetModelYZ('bottom').Y,
                this.cameraRotation + 0.5
            );
            this.hintDrivingHelper(res, 'bottomBar');
        }

        this.defaultStyles.bottomBar = true;
    }

    getTopStyleSceneHandler(res): void {
        for (const meshId of GLOBAL_PELMET) {
            if (this.scene.getMeshByName(meshId)) {
                this.scene.getMeshByName(meshId).setEnabled(false);
            }
        }

        for (const meshId of res.meshes) {
            if (this.scene.getMeshByName(meshId)) {
                this.scene.getMeshByName(meshId).setEnabled(true);
            }
        }

        if (this.defaultStyles.topStyle && res.type === 'click') {
            let targetZ;
            let targetY;

            this.lowerRadius = 0.6 * this.currentWidth;

            if (window.innerWidth > this.breakpoints['tablet-portrait']) {
                targetZ = -0.3 * this.currentWidth + 0.2;
                targetY = 0.5 * this.currentHeight - 0.3;
            }

            if (window.innerWidth <= this.breakpoints['tablet-portrait']) {
                targetZ = -0.35 * this.currentWidth + 0.2;
                targetY = 0.5 * this.currentHeight - 0.3;
            }

            this.zoomOnSelectedMesh(
                targetZ,
                targetY,
                this.cameraRotation + 0.5
            );
            this.hintDrivingHelper(res, 'topStyle');
        }

        this.defaultStyles.topStyle = true;
    }

    getBottomChannelHandler(res): void {
        for (const meshId of res.meshes) {
            if (this.scene.getMeshByName(meshId)) {
                this.scene.getMeshByName(meshId).setEnabled(res.state);
            }
        }

        if (this.defaultStyles.bottomChannel && res.type === 'click') {
            this.zoomOnSelectedMesh(
                this.targetModelYZ('bottom').Z,
                this.targetModelYZ('bottom').Y,
                this.cameraRotation + 0.5
            );

            this.hintDrivingHelper(res, 'bottomChannel');
        }

        if (!res.state) {
            this.isHintsVisible();
        }

        this.defaultStyles.bottomChannel = res.state;
    }

    getSizeSceneHandler(res): void {
        this.zoomOutHandler(30);
        if (res.hasOwnProperty('width')) {
            this.currentWidth = res.width;
            this.sceneWidthSize(res);
        } else if (res.hasOwnProperty('height')) {
            this.currentHeight = res.height;
            this.sceneHeightSize(res);
        }

        if (window.innerWidth < this.breakpoints['tablet-portrait']) {
            this.upperRadius = (this.currentWidth > this.currentHeight ? this.currentWidth :  this.currentHeight) * 1.9;
            this.camera.radius = this.upperRadius;

            const zoomLowerRadius = (this.upperRadius - this.lowerRadius) * 0.5;
            this.touchHandler(zoomLowerRadius);
        }

        this.middleRadius = 0.8 * this.currentWidth;

        if (res.width > 1.7 || res.height > 1.7) {
            this.camera.panningDistanceLimit = 1.2;
        } else {
            this.camera.panningDistanceLimit = 0.8;
        }
    }

    getTopSceneHandler(res): void {
        /*
        * MATERIAL-2
        * LARGE WEATHER STRIP-1
        * CUSTOM MADE SKIRT-1
        * ALL BLINDS 2-1
        * ALL BLINDS 2-2
        * ALL BLINDS 2-3
        * ALL BLINDS 2-4
        * ALL BLINDS 3-1
        * ALL BLINDS 3-2
        * HDBB-1
        * REVERSE HANDLE LEVER-1
        * REVERSE HANDLE-1
        * SPLINE-1
        * SPLINE-2
        * SPRING BALANCE ALUMINIUM FLAT BAR-1
        * SPRING BALANCE ALUMINIUM FLAT BAR-2
        * SPRING BALANCE BODY COVER LEFT
        * SPRING BALANCE BODY COVER RIGHT
        * SPRING BALANCE BODY-1
        * SPRING BALANCE BOTTOM STOP-2 **
        * SPRING BALANCE BOTTOM STOP-3 **
        * SPRING BALANCE END CAP-2
        * SPRING BALANCE END CAP-3
        * SPRING BALANCE HANDLE BODY-1
        * SPRING BALANCE HANDLE-1
        * SPRING BALANCE TONGUE LEFT
        * SPRING BALANCE TONGUE RIGHT
        * STANDARD WEATHER STRIP-1
        * */
        const getBlindType = this.sessionStorageService.getSession('zip-blind-type');
        this.shutter.value = res;

        if (getBlindType === 'outdoor') {
            this.scene.getMeshByName(MESHES_IDS[2]).scaling.y = this.shutter.outdoor.minScale + this.shutter.materialScale * res;
            this.scene.getMeshByName(MESHES_IDS[143]).scaling.y = this.shutter.outdoor.minMeshScale + this.shutter.meshScale * res;
            this.scene.getMeshByName(MESHES_IDS[144]).scaling.y = this.shutter.outdoor.minMeshScale + this.shutter.meshScale * res;

            this.scene.getMeshByName(MESHES_IDS[2]).position.y = this.shutter.materialPosition - (this.shutter.materialPosition * res) + 0.0038 * res;
            this.scene.getMeshByName(MESHES_IDS[143]).position.y = this.shutter.materialPosition - (this.shutter.materialPosition * res) + 0.007 * res;
            this.scene.getMeshByName(MESHES_IDS[144]).position.y = this.shutter.materialPosition - (this.shutter.materialPosition * res) + 0.007 * res;

            Object.entries(this.shutter.outdoor.meshPositions).forEach(([key, position]: [string, number]) => {
                if (MESHES_IDS[key].includes('SPRING BALANCE')) {
                    this.scene.getMeshByName(MESHES_IDS[key]).position.z = position - 1.975 * (this.shutter.materialPosition * (1 - res));
                } else {
                    this.scene.getMeshByName(MESHES_IDS[key]).position.y = position + 1.975 * (this.shutter.materialPosition * (1 - res));
                }
            });
        }

        if (getBlindType === 'interior') {
            this.scene.getMeshByName(INTERIOR_MESHES_IDS[136]).scaling.y = this.shutter.interior.minScale + this.shutter.materialScale * res;
            this.scene.getMeshByName(INTERIOR_MESHES_IDS[136]).position.y = this.shutter.materialPosition - (this.shutter.materialPosition * res) + 0.059 * res;

            Object.entries(this.shutter.interior.meshPositions).forEach(([key, position]: [string, number]) => {
                this.scene.getMeshByName(INTERIOR_MESHES_IDS[key]).position.y = position + 2 * (this.shutter.materialPosition * (1 - res)) - 0.0485 * (1 - res);
            });
        }
    }

    getColorSceneHandler(res): void {
        const material = new StandardMaterial('', this.scene);
        const meshName = this.scene.getMeshByName(res.model);
        material.maxSimultaneousLights = 5;

        if (res.texture === 'material' || res.model.toLowerCase().includes('custom')) {
            if (res.opacity === 1) {
                material.specularColor = new Color3(0, 0, 0);
                material.diffuseTexture = new Texture('../../../../assets/textures/material.png', this.scene);
                material.diffuseTexture.hasAlpha = true;
                // @ts-ignore
                material.diffuseTexture.uScale = 50;
                // @ts-ignore
                material.diffuseTexture.vScale = 50;
            }
        } else if (res.texture === 'frame') {
            material.ambientColor = new Color3(0, 0, 0);
        }

        material.diffuseColor = Color3.FromHexString(res.modelColor);
        if (meshName) {
            meshName.material = material;
            meshName.material.alpha = res.opacity;
        }

        this.defaultStyles.color = true;

        if (this.materialOpacity !== res.opacity) {
            this.materialOpacity = res.opacity;
            this.zoomOutHandler(30);
        }
    }

    setupSceneHandler(container): any {
        const getBlindType = this.sessionStorageService.getSession('zip-blind-type');
        const MESH_TYPE = getBlindType === 'outdoor' ? MESHES_IDS : INTERIOR_MESHES_IDS;
        const SCALING_TYPE = getBlindType === 'outdoor' ? SCALING_MESHES : INTERIOR_SCALING_MESHES;

        container.addAllToScene();
        this.camera.attachControl(this.scene);
        this.camera.minZ = 0.1;
        this.scene.meshes[0].rotationQuaternion = null;
        this.camera.panningInertia = 0;
        this.camera.panningSensibility = 600;
        this.camera.panningDistanceLimit = 0.8;
        this.camera._useCtrlForPanning = false;
        this.camera.panningAxis = new Vector3(1, 1, 0);
        (this.camera.inputs.attached.pointers as ArcRotateCameraPointersInput).buttons = [0, -1, -1];
        this.camera.inputs.remove(this.camera.inputs.attached.mousewheel);
        this.camera.angularSensibilityX = 2000;
        this.camera.angularSensibilityY = 2000;
        this.setCameraOffset();

        const color = new StandardMaterial('', this.scene);
        color.diffuseColor = Color3.FromHexString('#818181');

        for (const meshId of GLOBAL_HIDDEN_PELMET) {
            this.scene.getMeshByName(meshId)?.setEnabled(false);
        }

        for (const id in MESH_TYPE) {
            if (id) {
                this.meshPosition[id] = {
                    x: this.scene.getMeshByName(MESH_TYPE[id])?.position.x,
                    y: this.scene.getMeshByName(MESH_TYPE[id])?.position.y,
                    z: this.scene.getMeshByName(MESH_TYPE[id])?.position.z
                };
            }
        }

        for (const id in SCALING_TYPE) {
            if (id) {
                this.meshScaling[id] = {
                    x: this.scene.getMeshByName(MESH_TYPE[id])?.scaling.x,
                    y: this.scene.getMeshByName(MESH_TYPE[id])?.scaling.y,
                    z: this.scene.getMeshByName(MESH_TYPE[id])?.scaling.z
                };
            }
        }

        // TODO: Need to be finished LOADING model
        // this.boxMaskHandler();

        return this.scene;
    }

    showAxis(size, scene): void {
        const makeTextPlane = (text, color, size) => {
            const dynamicTexture = new DynamicTexture('DynamicTexture', 50, scene, true);
            dynamicTexture.hasAlpha = true;
            dynamicTexture.drawText(text, 5, 40, 'bold 36px Arial', color, 'transparent', true);
            const plane = MeshBuilder.CreatePlane('TextPlane', { size }, scene);
            const planeMaterial = new StandardMaterial('TextPlaneMaterial', scene);
            plane.material = planeMaterial;
            planeMaterial.backFaceCulling = false;
            planeMaterial.specularColor = new Color3(0, 0, 0);
            planeMaterial.diffuseTexture = dynamicTexture;
            return plane;
        };

        const axisX = MeshBuilder.CreateLines('axisX', {
                points: [
                    Vector3.Zero(), new Vector3(size, 0, 0), new Vector3(size * 0.95, 0.05 * size, 0),
                    new Vector3(size, 0, 0), new Vector3(size * 0.95, -0.05 * size, 0)]
            }
            , scene);
        axisX.color = new Color3(1, 0, 0);
        const xChar = makeTextPlane('X', 'red', size / 10);
        xChar.position = new Vector3(0.9 * size, -0.05 * size, 0);

        const axisY = MeshBuilder.CreateLines('axisY', {
            points: [
                Vector3.Zero(), new Vector3(0, size, 0), new Vector3(-0.05 * size, size * 0.95, 0),
                new Vector3(0, size, 0), new Vector3(0.05 * size, size * 0.95, 0)
            ]
        }, scene);
        axisY.color = new Color3(0, 1, 0);
        const yChar = makeTextPlane('Y', 'green', size / 10);
        yChar.position = new Vector3(0, 0.9 * size, -0.05 * size);

        const axisZ = MeshBuilder.CreateLines('axisZ', {
            points: [
                Vector3.Zero(), new Vector3(0, 0, size), new Vector3(0, -0.05 * size, size * 0.95),
                new Vector3(0, 0, size), new Vector3(0, 0.05 * size, size * 0.95)
            ]
        }, scene);
        axisZ.color = new Color3(0, 0, 1);
        const zChar = makeTextPlane('Z', 'blue', size / 10);
        zChar.position = new Vector3(0, 0.05 * size, 0.9 * size);
    }

    rotationAlphaBetaHandler(): void {
        const rotationsAlpha = Math.round(this.camera.alpha / Math.PI);
        const rotationsBeta = Math.round(this.camera.beta / Math.PI);

        this.currentAlpha = this.camera.alpha - (rotationsAlpha * Math.PI);
        this.currentBeta = this.camera.beta - (rotationsBeta * Math.PI);
        this.modelPosition = (rotationsAlpha % 2 === 0) ? 'back' : 'front';
    }

    calculateModelRotations(): void {
        this.rotationAlphaBetaHandler();

        if ((this.cameraRotation + Math.PI) < this.camera.alpha) {
            this.cameraRotation += 2 * Math.PI;
        }
        if ((this.cameraRotation - Math.PI) > this.camera.alpha) {
            this.cameraRotation -= 2 * Math.PI;
        }
    }

    calculateCameraRotations(): void {
        const limitZ = (this.currentWidth - 0.6) / 2;
        this.rotationAlphaBetaHandler();

        if ((this.pickResult.pickedPoint?.z < -limitZ && this.modelPosition === 'front' && this.currentAlpha < -1) ||
            (this.pickResult.pickedPoint?.z < -limitZ && this.modelPosition === 'back' && this.currentAlpha > 1) ||
            (this.pickResult.pickedPoint?.z > limitZ && this.modelPosition === 'front' && this.currentAlpha > 1) ||
            (this.pickResult.pickedPoint?.z > limitZ && this.modelPosition === 'back' && this.currentAlpha < -1) ||
            ((this.pickResult.pickedPoint?.z < limitZ && this.pickResult.pickedPoint.z > -limitZ) && (this.currentAlpha > 1 ||
                this.currentAlpha < -1)) ||
            Math.abs(this.currentBeta) < 0.7 && (this.pickResult.pickedPoint.y < 0.2 && this.pickResult.pickedPoint.y > -0.2)) {
            this.isZoomedIn = false;
            Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'target.x',
                60,
                40,
                this.camera.target.x,
                0,
                0
            );
            Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'target.y',
                60,
                40,
                this.camera.target.y,
                0,
                0
            );
            Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'target.z',
                60,
                40,
                this.camera.target.z,
                0,
                0
            );
            Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'targetScreenOffset.x',
                60,
                40,
                this.camera.targetScreenOffset.x,
                window.innerWidth > this.breakpoints['tablet-landspace'] ? -0.5 * window.devicePixelRatio : 0,
                0
            );
            Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'radius',
                60,
                40,
                this.camera.radius,
                this.upperRadius,
                0
            );
        }
    }

    lightGeneralSetupHandler(): void {
        const hemiLight = new HemisphericLight('HemiLight', new Vector3(0, 0, 0), this.scene);
        hemiLight.specular = new Color3(0, 0, 0);
        hemiLight.intensity = 1.4;

        this.light = new DirectionalLight('frontDirectLight', new Vector3(1, -1, 0), this.scene);
        this.light.intensity = 0.4;

        const backLight = new DirectionalLight('backDirectLight', new Vector3(-1, 0, 0), this.scene);
        backLight.intensity = 0.3;
        backLight.specular = new Color3(0, 0, 0);

        const rightLight = new PointLight('pointLight', new Vector3(-1, 1, -5), this.scene);
        rightLight.intensity = 0.1;

        const leftLight = new PointLight('leftLight', new Vector3(1, 1, 5), this.scene);
        leftLight.intensity = 0.1;
    }

    groundGeneralSetupHandler(): void {
        this.ground = MeshBuilder.CreateDisc('ground', {radius: 2.5}, this.scene);
        this.ground.isPickable = false;
        const groundMaterial = new StandardMaterial('ground', this.scene);
        groundMaterial.emissiveColor = new Color3(0, 0, 0);

        this.ground.material = groundMaterial;
        this.ground.rotation.x = Math.PI / 2;
        this.ground.position.y = -0.9;
        this.ground.position.z = 0.4;

        const shadowGenerator = new ShadowGenerator(512, this.light);

        shadowGenerator.bias = 0.00001;
        shadowGenerator.normalBias = 0.01;
        shadowGenerator.contactHardeningLightSizeUVRatio = 0.1;
        shadowGenerator.setDarkness(0);
        shadowGenerator.blurBoxOffset = 3;
        shadowGenerator.blurKernel = 4;
        shadowGenerator.blurScale = 4;
        shadowGenerator.useBlurExponentialShadowMap = true;
        shadowGenerator.addShadowCaster(this.scene.meshes[0]);
        this.ground.receiveShadows = true;
    }

    hintDrivingHelper(data, type): void {
        const getBlindType = this.sessionStorageService.getSession('zip-blind-type') || 'outdoor';
        this.isHintsVisible();

        if (data.description) {
            if (type === 'bottomBar') {
                this.hintsComponentHandler(data.description, data.meshes[0]);
            }

            if (type === 'bottomChannel') {
                this.hintsComponentHandler(data.description, data.meshes[0]);
            }

            if (type === 'reverse') {
                this.hintsComponentHandler(data.description, 'REVERSE HANDLE-1');
            }

            if (type === 'topStyle') {
                this.hintsComponentHandler(data.description, data.meshes[1]);
            }

            if (type === 'operation') {
                this.hintsComponentHandler(data.description, getBlindType === 'outdoor' ? data.meshes[5] : 'BOTTOM BAR GUIDE 2');
            }

            if (type === 'mounting') {
                this.hintsComponentHandler(data.description, getBlindType === 'outdoor' ? data.meshes[0] : data.meshes[1]);

                this.rect.linkOffsetY = -200;
                this.target.linkOffsetY = 0;
                this.target.linkOffsetY = 0;
            }
        }

    }

    isHintsVisible(): void {
        if (this.rect && this.target && this.label && this.line) {
            this.rect.isVisible = false;
            this.target.isVisible = false;
            this.label.isVisible = false;
            this.line.isVisible = false;
        }
    }

    zoomOnSelectedMesh(positionZ, positionY, alpha): void {
        this.pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);
        this.isZoomedIn = true;
        // this.currentBehaviour = 'move';
        // this.camera._panningMouseButton = 0;
        Animation.CreateAndStartAnimation(
            'anim',
            this.camera,
            'target.x',
            15,
            10,
            this.camera.target.x,
            0,
            0
        );

        Animation.CreateAndStartAnimation(
            'anim',
            this.camera,
            'target.y',
            15,
            10,
            this.camera.target.y,
            positionY,
            0
        );
        Animation.CreateAndStartAnimation(
            'anim',
            this.camera,
            'target.z',
            15,
            10,
            this.camera.target.z,
            positionZ,
            0
        );
        Animation.CreateAndStartAnimation(
            'anim',
            this.camera,
            'targetScreenOffset.x',
            15,
            10,
            this.camera.targetScreenOffset.x,
            -0.1,
            0
        );
        Animation.CreateAndStartAnimation(
            'anim',
            this.camera,
            'radius',
            15,
            10,
            this.camera.radius,
            this.lowerRadius,
            0
        );
        Animation.CreateAndStartAnimation(
            'anim',
            this.camera,
            'alpha',
            15,
            10,
            this.camera.alpha,
            alpha,
            0
        );
        Animation.CreateAndStartAnimation(
            'anim',
            this.camera,
            'beta',
            15,
            10,
            this.camera.beta,
            Math.PI / 2,
            0
        );

        this.shareDataService.setCursorPointer({
            cursor: this.canvas.style.cursor,
            clicked: this.clicked,
            zoomIn: true
        });
    }

    zoomInHandler(): void {
        const pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);
        this.pickResult = pickResult;
        if (this.currentBehaviour === 'rotate' && pickResult.hit && pickResult.pickedMesh.name !== 'ground' && !this.isZoomedIn) {
            this.isZoomedIn = true;
            // this.camera._panningMouseButton = 0;
            let zoomRadius = this.lowerRadius;
            if (this.currentAlpha < -0.5 && (this.currentBeta >= 1.2 || this.currentBeta < -1.2)) {
                if ((pickResult.pickedPoint.z < 0 && this.modelPosition === 'front')
                    || (pickResult.pickedPoint.z >= 0 && this.modelPosition === 'back')) {
                    zoomRadius = this.middleRadius;
                }
            } else if (this.currentAlpha >= 0.5  && (this.currentBeta >= 1.2 || this.currentBeta < -1.2)) {
                if ((pickResult.pickedPoint.z >= 0 && this.modelPosition === 'front')
                    || (pickResult.pickedPoint.z < 0 && this.modelPosition === 'back')) {
                    zoomRadius = this.middleRadius;
                }
            } else if (this.currentBeta < 1.2 && this.currentBeta >= -1.2 && (this.currentAlpha < 0.5 || this.currentAlpha >= -0.5)) {
                if ((pickResult.pickedPoint.y >= 0 && this.currentBeta < 0) || (pickResult.pickedPoint.y < 0 && this.currentBeta >= 0)) {
                    zoomRadius = this.middleRadius + 0.5;
                }
            }

            Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'target.x',
                15,
                10,
                this.camera.target.x,
                pickResult.pickedPoint.x * 0.7,
                0
            );
            Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'target.y',
                15,
                10,
                this.camera.target.y,
                pickResult.pickedPoint.y * 0.7,
                0
            );
            Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'target.z',
                15,
                10,
                this.camera.target.z,
                pickResult.pickedPoint.z * 0.7,
                0
            );
            Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'targetScreenOffset.x',
                15,
                10,
                this.camera.targetScreenOffset.x,
                window.innerWidth > this.breakpoints['tablet-landspace'] ? -0.15 * window.devicePixelRatio : 0,
                0
            );
            const zoomInEnd = Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'radius',
                15,
                10,
                this.camera.radius,
                zoomRadius,
                0
            );

            zoomInEnd.onAnimationEnd = () => {
                // this.currentBehaviour = 'move';
            };

            this.isModelMoved = true;
            this.shareDataService.setCursorPointer({
                cursor: this.canvas.style.cursor,
                clicked: this.clicked,
                zoomIn: true
            });
        }
    }

    zoomOutHandler(framePerSecond): void {
        // if (this.currentBehaviour === 'move') {
        //     this.camera._panningMouseButton = -1;

            Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'target.x',
                framePerSecond,
                40,
                this.camera.target.x,
                0,
                0
            );
            Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'target.y',
                framePerSecond,
                40,
                this.camera.target.y,
                0,
                0
            );
            Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'target.z',
                framePerSecond,
                40,
                this.camera.target.z,
                0,
                0
            );
            Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'targetScreenOffset.x',
                framePerSecond,
                40,
                this.camera.targetScreenOffset.x,
                window.innerWidth > this.breakpoints['tablet-landspace'] ? -0.5 * window.devicePixelRatio : 0,
                0
            );
            const zoomOutEnd = Animation.CreateAndStartAnimation(
                'anim',
                this.camera,
                'radius',
                framePerSecond,
                40,
                this.camera.radius,
                this.upperRadius,
                0
            );

            zoomOutEnd.onAnimationEnd = () => {
                // this.currentBehaviour = 'rotate';
                this.isHintsVisible();
                this.isZoomedIn = false;
            };

            this.shareDataService.setCursorPointer({
                cursor: this.canvas.style.cursor,
                clicked: this.clicked,
                zoomOut: true
            });
        // }
    }

    zoomHandler(event): void {
        event.stopImmediatePropagation();

        if (this.currentBehaviour === 'rotate' && event.deltaY < 0) {
            this.zoomInHandler();
        }

        if (event.deltaY > 0) {
            this.zoomOutHandler(30);
        }
    }

    public animate(): void {
        this.ngZone.runOutsideAngular(() => {
            const rendererLoopCallback = () => {
                this.scene.render();
            };
            this.engine.runRenderLoop(rendererLoopCallback);
            window.addEventListener('resize', () => {
                this.engine.resize();
                this.setCameraOffset();
                this.setFontSize();
            });
        });
    }

    hintsComponentHandler(text, mesh): void {
        this.advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI');
        this.advancedTexture.idealWidth = 2000;

        this.rect = new GUI.Rectangle();
        this.label = new GUI.TextBlock();
        this.target = new GUI.Ellipse();
        this.line = new GUI.Line();

        text = text.replace(/&#13;&#10;/g, '\n');
        const longestStr = text.split('\n').sort((a, b) => {a.lenght - b.lenght; });
        const dynamicSize = {
            length: !_.isEmpty(text) ? this.calculationSizeService.measureText(longestStr[0], this.fontSize) : 0,
            height: longestStr.length * (this.fontSize * 2)
        };

        this.drawRectangleHintsHandler(dynamicSize, mesh);
        this.drawLabelHintsHandler(text);
        this.drawTargetHintsHandler(mesh);
        this.drawLineHintsHandler(dynamicSize.length, mesh);
    }

    drawRectangleHintsHandler(dynamicSize, mesh): void {
        this.rect.widthInPixels = dynamicSize.length + 60;
        this.rect.heightInPixels = dynamicSize.height;
        this.rect.cornerRadius = 5;
        this.rect.color = '#bdbdbd';
        this.rect.thickness = 1;
        this.rect.background = '#ffffff';

        this.advancedTexture.addControl(this.rect);

        this.rect.linkWithMesh(this.scene.getMeshByName(mesh));
        this.rect.linkOffsetY = -100;
        this.rect.linkOffsetX = -300;
    }

    drawLabelHintsHandler(text): void {
        this.label.text = text;
        this.label.color = '#212529';
        this.label.fontFamily = 'Roboto';
        this.label.fontSize = `${this.fontSize}px`;
        this.rect.addControl(this.label);
    }

    drawTargetHintsHandler(mesh): void {
        this.target.width = '20px';
        this.target.height = '20px';
        this.target.color = '#bdbdbd';
        this.target.thickness = 1;
        this.target.background = '#ffffff';

        this.advancedTexture.addControl(this.target);

        this.target.linkWithMesh(this.scene.getMeshByName(mesh));
    }

    drawLineHintsHandler(dynamicLength, mesh): void {
        this.line.lineWidth = 1;
        this.line.color = '#bdbdbd';
        this.line.y2 = 20;
        this.line.x2 = dynamicLength <= 120 ? dynamicLength <= 90 ? 40 : 70 : dynamicLength >= 220 ? 130 : dynamicLength - 100;
        this.line.linkOffsetY = -5;
        this.line.linkOffsetX = -5;
        this.line.zIndex = -1;

        this.advancedTexture.addControl(this.line);

        this.line.linkWithMesh(this.scene.getMeshByName(mesh));
        this.line.connectedControl = this.rect;
    }

    getParentSize(parent): any {
        const sizes = parent.getHierarchyBoundingVectors();
        return {
            depth: sizes.max.x - sizes.min.x + 0.09,
            height: sizes.max.y - sizes.min.y - 0.1,
            width: sizes.max.z - sizes.min.z - 0.5
        };
    }

    boxMaskHandler(): void {
        this.box = MeshBuilder.CreateBox('box', this.getParentSize(this.scene.meshes[0]));
        const boxMat = new StandardMaterial('boxMat', this.scene);

        this.box.material = boxMat;
        boxMat.alpha = 1;
        this.box.position.y = 0.015;
        this.box.rotation.y = -1;
        // this.boxStatus(false);
    }

    setDefaultView(): void {
        const getBlindType = this.sessionStorageService.getSession('zip-blind-type') || 'outdoor';
        const localStorageDefaultData = this.localStorageService.getBlindData('zip-blind-config') || {};
        const colorPalette = getBlindType === 'outdoor' ? COLOR_FRAME_PELMET : INTERIOR_COLOR_FRAME_PELMET;
        const localDefaultDataType = localStorageDefaultData[getBlindType];

        if (localDefaultDataType?.material) {
            const getDefaultMaterial = _.filter(localDefaultDataType.material, {is_default: true})[0];
            const material = [...MATERIAL_MESH, ...SPLINE_MESH];

            for (const meshId of material) {
                this.setColor(meshId, getDefaultMaterial.color.default, 1, 'material');
            }

            if (getBlindType === 'interior') {
                const color = MATERIAL_COLORS.find(item => item.name === 'Monument').color;
                this.setColor('WEATHER STRIP', color, 1, 'material');
            }
        }

        if (localDefaultDataType?.frame && localDefaultDataType.frame.frame_color) {
            const getDefaultFrame = _.filter(localDefaultDataType.frame.frame_color, {is_default: true})[0];

            for (const meshId of colorPalette) {
                this.setColor(meshId, getDefaultFrame.color, 1, 'frame');
            }
        }

        if (localDefaultDataType?.fixtures_color && localDefaultDataType?.material && getBlindType === 'interior') {
            const getDefaultFixturesColor = _.filter(localDefaultDataType.fixtures_color, {is_default: true})[0];
            const getDefaultSizes = _.filter(localDefaultDataType.material, {is_default: true})[0].sizes;
            const width = getDefaultSizes['width'].default;
            const height = getDefaultSizes['height'].default;

            const topStyleId = INTERIOR_FRAME_TOP_STYLE.reduce((acc, x) => !acc && width > x.width && height > x.height ? x.id : acc, '');
            const topStyle = localDefaultDataType.frame.top_style.filter(el => el.id === topStyleId)[0];
            const meshArray = topStyle.id === INTERIOR_FRAME_TOP_STYLE[2].id ? INTERIOR_COLOR_FIXTURES : INTERIOR_COLOR_FIXTURES.slice(0, -2);

            for (const meshId of meshArray) {
                this.setColor(meshId, getDefaultFixturesColor.color, 1, 'frame');
            }
        }

        this.shareDataService.setModelLoaded(true);
    }

    boxStatus(status): void {
        if (this.box?.setEnabled(status)) {
            setTimeout(() => {
                this.box.setEnabled(status);
            }, 500);
        }
    }

    outdoorWidthSizeHandler(widthDiff: number): void {
        this.scene.getMeshByName(MESHES_IDS[1]).scaling.x = this.meshScaling[1].x + 2.12 * widthDiff;
        this.scene.getMeshByName(MESHES_IDS[2]).scaling.x = this.meshScaling[2].x + 2.12 * widthDiff;
        this.scene.getMeshByName(MESHES_IDS[11]).scaling.z = this.meshScaling[11].z + 2.015 * widthDiff;
        this.scene.getMeshByName(MESHES_IDS[22]).scaling.z = this.meshScaling[22].z + 2.2 * widthDiff;
        this.scene.getMeshByName(MESHES_IDS[65]).scaling.z = this.meshScaling[65].z + 2.2 * widthDiff;
        this.scene.getMeshByName(MESHES_IDS[74]).scaling.z = this.meshScaling[74].z + 2.015 * widthDiff;
        this.scene.getMeshByName(MESHES_IDS[107]).scaling.z = this.meshScaling[107].z + 2.21 * widthDiff;
        this.scene.getMeshByName(MESHES_IDS[112]).scaling.z = this.meshScaling[112].z + 2.015 * widthDiff;
        this.scene.getMeshByName(MESHES_IDS[123]).scaling.z = this.meshScaling[123].z + 2.2 * widthDiff;
        this.scene.getMeshByName(MESHES_IDS[136]).scaling.z = this.meshScaling[136].z + 2.2 * widthDiff;

        this.scene.getMeshByName(MESHES_IDS[35]).scaling.x = this.meshScaling[35].z + 2.55 * widthDiff;
        this.scene.getMeshByName(MESHES_IDS[53]).scaling.x = this.meshScaling[53].z + 2.55 * widthDiff;

        this.scene.getMeshByName(MESHES_IDS[4]).position.y = this.meshPosition[4].y - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[8]).position.y = this.meshPosition[8].y - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[13]).position.z = this.meshPosition[13].z - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[15]).position.z = this.meshPosition[15].z + widthDiff;
        this.scene.getMeshByName(MESHES_IDS[18]).position.y = this.meshPosition[18].y - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[20]).position.y = this.meshPosition[20].y - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[35]).position.x = this.meshPosition[35].x - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[38]).position.x = this.meshPosition[38].x - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[44]).position.x = this.meshPosition[44].x + widthDiff;
        this.scene.getMeshByName(MESHES_IDS[55]).position.x = this.meshPosition[55].x - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[58]).position.x = this.meshPosition[58].x + widthDiff;
        this.scene.getMeshByName(MESHES_IDS[69]).position.y = this.meshPosition[69].y - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[71]).position.y = this.meshPosition[71].y - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[76]).position.z = this.meshPosition[76].z - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[78]).position.z = this.meshPosition[78].z + widthDiff;
        this.scene.getMeshByName(MESHES_IDS[82]).position.z = this.meshPosition[82].z - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[85]).position.z = this.meshPosition[85].z + widthDiff;
        this.scene.getMeshByName(MESHES_IDS[88]).position.z = this.meshPosition[88].z + widthDiff;
        this.scene.getMeshByName(MESHES_IDS[91]).position.z = this.meshPosition[91].z + widthDiff;
        this.scene.getMeshByName(MESHES_IDS[95]).position.z = this.meshPosition[95].z - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[97]).position.z = this.meshPosition[97].z - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[99]).position.x = this.meshPosition[99].x - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[100]).position.x = this.meshPosition[100].x - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[101]).position.x = this.meshPosition[101].x - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[102]).position.x = this.meshPosition[102].x - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[103]).position.x = this.meshPosition[103].x - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[104]).position.x = this.meshPosition[104].x - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[110]).position.z = this.meshPosition[110].z + widthDiff;
        this.scene.getMeshByName(MESHES_IDS[114]).position.z = this.meshPosition[114].z - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[117]).position.y = this.meshPosition[117].y - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[119]).position.y = this.meshPosition[119].y - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[130]).position.z = this.meshPosition[130].z + widthDiff;
        this.scene.getMeshByName(MESHES_IDS[131]).position.z = this.meshPosition[131].z + widthDiff;
        this.scene.getMeshByName(MESHES_IDS[132]).position.z = this.meshPosition[132].z - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[133]).position.z = this.meshPosition[133].z - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[134]).position.z = this.meshPosition[134].z + widthDiff;
        this.scene.getMeshByName(MESHES_IDS[135]).position.z = this.meshPosition[135].z + widthDiff;
        this.scene.getMeshByName(MESHES_IDS[137]).position.z = this.meshPosition[137].z - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[138]).position.z = this.meshPosition[138].z - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[139]).position.z = this.meshPosition[139].z - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[140]).position.z = this.meshPosition[140].z - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[143]).position.z = this.meshPosition[143].z + widthDiff;
        this.scene.getMeshByName(MESHES_IDS[144]).position.z = this.meshPosition[144].z - widthDiff;
        this.scene.getMeshByName(MESHES_IDS[145]).position.z = this.meshPosition[145].z + widthDiff;
        this.scene.getMeshByName(MESHES_IDS[146]).position.z = this.meshPosition[146].z - widthDiff;
    }

    outdoorHeightSizeHandler(heightDiff: number): void {
        this.scene.getMeshByName(MESHES_IDS[2]).scaling.y = this.meshScaling[2].y + 1.9323 * heightDiff;
        this.scene.getMeshByName(MESHES_IDS[117]).scaling.z = this.meshScaling[117].z + 2 * heightDiff;
        this.scene.getMeshByName(MESHES_IDS[71]).scaling.z = this.meshScaling[71].z + 2 * heightDiff;
        this.scene.getMeshByName(MESHES_IDS[20]).scaling.z = this.meshScaling[20].z + 2 * heightDiff;
        this.scene.getMeshByName(MESHES_IDS[8]).scaling.z = this.meshScaling[8].z + 2 * heightDiff;
        this.scene.getMeshByName(MESHES_IDS[143]).scaling.y = this.meshScaling[143].y + 2 * heightDiff;
        this.scene.getMeshByName(MESHES_IDS[144]).scaling.y = this.meshScaling[144].y + 2 * heightDiff;

        this.scene.getMeshByName(MESHES_IDS[1]).position.y = this.meshPosition[1].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[4]).position.z = this.meshPosition[4].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[11]).position.y = this.meshPosition[11].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[13]).position.y = this.meshPosition[13].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[15]).position.y = this.meshPosition[15].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[18]).position.z = this.meshPosition[18].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[22]).position.y = this.meshPosition[22].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[29]).position.z = this.meshPosition[29].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[32]).position.z = this.meshPosition[32].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[35]).position.z = this.meshPosition[35].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[38]).position.z = this.meshPosition[38].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[41]).position.z = this.meshPosition[41].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[44]).position.z = this.meshPosition[44].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[47]).position.z = this.meshPosition[47].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[50]).position.z = this.meshPosition[50].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[53]).position.z = this.meshPosition[53].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[55]).position.z = this.meshPosition[55].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[58]).position.z = this.meshPosition[58].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[60]).position.z = this.meshPosition[60].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[63]).position.z = this.meshPosition[63].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[65]).position.y = this.meshPosition[65].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[69]).position.z = this.meshPosition[69].z - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[74]).position.y = this.meshPosition[74].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[76]).position.y = this.meshPosition[76].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[78]).position.y = this.meshPosition[78].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[82]).position.y = this.meshPosition[82].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[85]).position.y = this.meshPosition[85].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[88]).position.y = this.meshPosition[88].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[91]).position.y = this.meshPosition[91].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[95]).position.y = this.meshPosition[95].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[97]).position.y = this.meshPosition[97].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[99]).position.y = this.meshPosition[99].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[100]).position.y = this.meshPosition[100].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[101]).position.y = this.meshPosition[101].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[102]).position.y = this.meshPosition[102].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[103]).position.y = this.meshPosition[103].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[104]).position.y = this.meshPosition[104].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[107]).position.y = this.meshPosition[107].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[110]).position.y = this.meshPosition[110].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[112]).position.y = this.meshPosition[112].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[114]).position.y = this.meshPosition[114].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[119]).position.z = this.meshPosition[119].z + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[123]).position.y = this.meshPosition[123].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[130]).position.y = this.meshPosition[130].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[131]).position.y = this.meshPosition[131].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[132]).position.y = this.meshPosition[132].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[133]).position.y = this.meshPosition[133].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[134]).position.y = this.meshPosition[134].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[135]).position.y = this.meshPosition[135].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[136]).position.y = this.meshPosition[136].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[137]).position.y = this.meshPosition[137].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[138]).position.y = this.meshPosition[138].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[139]).position.y = this.meshPosition[139].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[140]).position.y = this.meshPosition[140].y + heightDiff;

        this.scene.getMeshByName(MESHES_IDS[141]).position.y = this.meshPosition[141].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[142]).position.y = this.meshPosition[142].y - heightDiff;
        this.scene.getMeshByName(MESHES_IDS[145]).position.y = this.meshPosition[145].y + heightDiff;
        this.scene.getMeshByName(MESHES_IDS[146]).position.y = this.meshPosition[146].y + heightDiff;

        const meshPositions = {
            22: this.meshPosition[22].y - heightDiff,
            29: this.meshPosition[29].z + heightDiff,
            32: this.meshPosition[32].z + heightDiff,
            35: this.meshPosition[35].z + heightDiff,
            38: this.meshPosition[38].z + heightDiff,
            41: this.meshPosition[41].z + heightDiff,
            44: this.meshPosition[44].z + heightDiff,
            47: this.meshPosition[47].z + heightDiff,
            50: this.meshPosition[50].z + heightDiff,
            53: this.meshPosition[53].z + heightDiff,
            55: this.meshPosition[55].z + heightDiff,
            58: this.meshPosition[58].z + heightDiff,
            60: this.meshPosition[60].z + heightDiff,
            63: this.meshPosition[63].z + heightDiff,
            65: this.meshPosition[65].y - heightDiff,
            88: this.meshPosition[88].y - heightDiff,
            91: this.meshPosition[91].y - heightDiff,
            95: this.meshPosition[95].y - heightDiff,
            97: this.meshPosition[97].y - heightDiff,
            99: this.meshPosition[99].y - heightDiff,
            100: this.meshPosition[100].y - heightDiff,
            101: this.meshPosition[101].y - heightDiff,
            102: this.meshPosition[102].y - heightDiff,
            103: this.meshPosition[103].y - heightDiff,
            104: this.meshPosition[104].y - heightDiff,
            107: this.meshPosition[107].y - heightDiff,
            123: this.meshPosition[123].y - heightDiff,
            141: this.meshPosition[141].y - heightDiff,
            142: this.meshPosition[142].y - heightDiff,
        };

        this.shutter.outdoor.meshPositions = {...meshPositions};
        this.shutter.materialScale = (this.meshScaling[2].y + 1.9323 * heightDiff) - this.shutter.outdoor.minScale;
        this.shutter.meshScale = (this.meshScaling[143].y + 2 * heightDiff) - this.shutter.outdoor.minMeshScale;
        this.shutter.materialPosition = this.meshPosition[1].y + heightDiff - this.shutter.outdoor.maxPosition;
        this.getTopSceneHandler(this.shutter.value);
    }

    interiorWidthSizeHandler(widthDiff: number): void {
        for (const [key, value] of Object.entries(INTERIOR_WIDTH_MESH)) {
            const cof = [112, 134, 136].includes(+key) ? 1.05 : key === '114' ?  1.03 : 1;
            this.scene.getMeshByName(value).scaling.z = this.meshScaling[key].z + cof * widthDiff;
        }

        for (const [key, value] of Object.entries(INTERIOR_WIDTH_POSITION_MESH)) {
            if (value.includes('1')) {
                this.scene.getMeshByName(value).position.z = this.meshPosition[key].z - 0.49 * widthDiff;
            }
            if (value.includes('2')) {
                this.scene.getMeshByName(value).position.z = this.meshPosition[key].z + 0.49 * widthDiff;
            }
        }
    }

    interiorHeightSizeHandler(heightDiff: number): void {
        this.shutter.interior.meshPositions = {};

        for (const [key, value] of Object.entries(INTERIOR_HEIGHT_MESH)) {
            if (value.includes('MATERIAL')) {
                const cof = heightDiff >= -0.1 ? 2.06 : 1.85;
                this.scene.getMeshByName(value).scaling.y = this.meshScaling[key].y + cof * heightDiff;
                this.shutter.materialScale = (this.meshScaling[key].y + cof * heightDiff) - this.shutter.interior.minScale;
            }
            if (value.includes('TRACK') || value.includes('REVEAL') || value.includes('FACE')) {
                this.scene.getMeshByName(value).scaling.y = this.meshScaling[key].y + 1.95 * heightDiff;
            }
        }

        for (const [key, value] of Object.entries(INTERIOR_HEIGHT_POSITION_MESH)) {
            if (value.includes('MATERIAL')) {
                this.shutter.materialPosition = (this.meshPosition[key].y + 0.49 * heightDiff) - this.shutter.interior.maxPosition;
            }
            if (value.includes('BOTTOM BAR') || value.includes('STRIP')) {
                this.shutter.interior.meshPositions[key] = this.meshPosition[key].y - 0.49 * heightDiff + 0.012;
            }
            if (value.includes('PELMET') || value.includes('END') || value.includes('MATERIAL')) {
                this.scene.getMeshByName(value).position.y = this.meshPosition[key].y + 0.49 * heightDiff;
            }
            if (value.includes('BOTTOM') || value.includes('STRIP') || value.includes('FACE')) {
                this.scene.getMeshByName(value).position.y = this.meshPosition[key].y - 0.49 * heightDiff;
            }
            if (value.includes('BOTTOM CHANNEL')) {
                this.scene.getMeshByName(value).position.y = this.meshPosition[key].y - 0.49 * heightDiff + 0.034;
            }
        }

        this.getTopSceneHandler(this.shutter.value);
    }

    sceneWidthSize(res): void {
        const getBlindType = this.sessionStorageService.getSession('zip-blind-type');
        if (this.defaultSize[getBlindType].width === 0) {
            this.defaultSize[getBlindType].width = res.width;
            this.sessionStorageService.setModelSize(this.defaultSize, 'default-model-size');
        }
        const widthDiff = (res.width - this.defaultSize[getBlindType].width) * 0.7;

        if (getBlindType === 'outdoor') {
            this.outdoorWidthSizeHandler(widthDiff);
        }

        if (getBlindType === 'interior') {
            this.interiorWidthSizeHandler(widthDiff);
        }

        this.defaultStyles.sizeWidth = true;
    }

    sceneHeightSize(res): void {
        const getBlindType = this.sessionStorageService.getSession('zip-blind-type');
        if (this.defaultSize[getBlindType].height === 0) {
            this.defaultSize[getBlindType].height = res.height;
            this.sessionStorageService.setModelSize(this.defaultSize, 'default-model-size');
        }

        const heightDiff = (res.height - this.defaultSize[getBlindType].height) * 0.5;

        if (getBlindType === 'outdoor') {
            this.outdoorHeightSizeHandler(heightDiff);
        }

        if (getBlindType === 'interior') {
            this.interiorHeightSizeHandler(heightDiff);
        }

        this.defaultStyles.sizeHeight = true;
    }

    setScreenShot(): void {
        const cameraForScreenShot = new FreeCamera('camera2', new Vector3(-2, 0, -2), this.scene);
        cameraForScreenShot.setTarget(Vector3.Zero());
        this.ground.setEnabled(false);
        this.ground.receiveShadows = false;

        Tools.CreateScreenshotUsingRenderTargetAsync(this.engine, cameraForScreenShot, 1300).then((data) => {
            this.shareDataService.setSceenShotBLindTemp(data);
            this.ground.setEnabled(true);
            this.ground.receiveShadows = true;
        });
    }

    setColor(name, color, transparency, material): void {
        this.colorEvents.next({model: name, modelColor: color, opacity: transparency, texture: material});
    }

    setTop(param): void {
        this.getTopSceneHandler(param);
    }

    setSize(param): any {
        this.sizeEvents.next(param);
    }

    setTopStyle(array): any {
        this.topStyleEvents.next(array);
    }

    setBottomBar(array): any {
        this.bottomBarEvents.next(array);
    }

    setOperation(data): any {
        this.operationEvents.next(data);
    }

    setReverse(data): any {
        this.reverseEvents.next(data);
    }

    setBottomChannel(array): any {
        this.bottomChannelEvents.next(array);
    }

    setMounting(data): any {
        this.mountingEvents.next(data);
    }

    getColor(): Observable<any> {
        return this.colorEvents.asObservable();
    }

    getSize(): Observable<any> {
        return this.sizeEvents.asObservable();
    }

    getTopStyle(): Observable<any> {
        return this.topStyleEvents.asObservable();
    }

    getBottomBar(): Observable<any> {
        return this.bottomBarEvents.asObservable();
    }

    getOperation(): Observable<any> {
        return this.operationEvents.asObservable();
    }

    getReverse(): Observable<any> {
        return this.reverseEvents.asObservable();
    }

    getMounting(): Observable<any> {
        return this.mountingEvents.asObservable();
    }

    getBottomChannel(): Observable<any> {
        return this.bottomChannelEvents.asObservable();
    }

    getShutterValue(): number {
        return this.shutter.value;
    }

    setCameraOffset(): void {
        if (window.innerWidth > this.breakpoints['tablet-landspace']) {
            this.camera.targetScreenOffset.x = -0.5 * window.devicePixelRatio;
        }
        if (window.innerWidth < this.breakpoints['tablet-landspace']) {
            this.camera.targetScreenOffset.x = 0;
        }
    }

    setFontSize(): void {
        if (window.innerWidth > this.breakpoints['xl-desktop']) {
            this.fontSize = 20;
        }
        if (window.innerWidth < this.breakpoints['xl-desktop']) {
            this.fontSize = 30;
        }
        if (window.innerWidth <= this.breakpoints.phone) {
            this.fontSize = 60;
        }
    }

    touchHandler(lowerLimit): void {
        const input = this.camera.inputs.attached.pointers;
        // @ts-ignore
        input.multiTouchPanning = false;

        this.camera.lowerRadiusLimit = lowerLimit;
        this.camera.upperRadiusLimit = this.upperRadius + 0.2;
    }
}
