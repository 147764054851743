export const Countries = [
    { code: 'au', name: 'Australia', data_store: 'https://www.usence.com.au/australia/api', link: 'https://www.usence.com.au/australia' },
    { code: 'nz', name: 'New Zealand', data_store: 'https://usence.com.au/new-zealand/api', link: 'https://www.nz.ziptrak.com/en' }
];

export const Config = {
  breakpoints: {
    'small-phone': 375,
    phone: 599,
    'tablet-portrait': 600,
    'tablet-landspace': 992,
    desktop: 1280,
    'l-desktop': 1400,
    'xl-desktop': 1530,
    'full-hd': 1980
  }
};

/**
* MOBILE RESPONSIVE RESOLUTIONS
* */
export const BREAKPOINTS: any = {
    'small-phone': {
        min: '(min-width: 0px)',
        max: '(max-width: 375px)'
    },
    'phone': {
        min: '(min-width: 375px)',
        max: '(max-width: 599px)'
    },
    'phone-portrait': {
        min: '(min-width: 599px)',
        max: '(max-width: 850px)'
    },
    'tablet-portrait': {
        min: '(min-width: 850px)',
        max: '(max-width: 992px)'
    },
    'desktop': {
        min: '(min-width: 992px)',
        max: '(max-width: 1200px)'
    },
    'l-desktop': {
        min: '(min-width: 1200px)',
        max: '(max-width: 1400px)'
    },
    'xl-desktop': {
        min: '(min-width: 1400px)',
        max: '(max-width: 1600px)'
    },
    'full-hd': {
        min: '(min-width: 1600px)',
        max: '(max-width: 1920px)'
    },
    'full-screen': {
        min: '(min-width: 600px)',
        max: '(max-width: 1920px)'
    }
};

// CONDITIONS FOR INTERIOR FRAME TOP STYLE
export const INTERIOR_FRAME_TOP_STYLE = [
    { id: 6, width: 2000, height: 1200, name: 'blind.pelmet.95mm' },
    { id: 6, width: 0, height: 1600, name: 'blind.pelmet.95mm' },
    { id: 5, width: 0, height: 0, name: 'blind.pelmet.75mm' }
];

// TOP TUBE
export const TOP_TUBE = [
    '6MM IDLER CUP-1',
    '6MM IDLER PIN-1',
    '86 93 TUBE ADAPTOR-1',
    '86 93 TUBE ADAPTOR-2',
    '86 93 TUBE ADAPTOR-3',
    '86 93 TUBE ADAPTOR-4',
    '86MM TOPTUBE-1',
    'tab-1',
    'ZDRW0089- TRY Bearing Housing-1_primitive0',
    'ZDRW0089- TRY Bearing Housing-1_primitive1',
    'ZDRW0094 2 GUIDE SLEEVE-1'
];

export const SHUTTER_CONTROL = [
     'MATERIAL-2',
     'LARGE WEATHER STRIP-1',
     'CUSTOM MADE SKIRT-1',
     'ALL BLINDS 2-1',
     'ALL BLINDS 2-2',
     'ALL BLINDS 2-3',
     'ALL BLINDS 2-4',
     'ALL BLINDS 3-1',
     'ALL BLINDS 3-2',
     'HDBB-1',
     'REVERSE HANDLE LEVER-1',
     'REVERSE HANDLE-1',
     'SPLINE-1',
     'SPLINE-2',
     'SPRING BALANCE ALUMINIUM FLAT BAR-1',
     'SPRING BALANCE ALUMINIUM FLAT BAR-2',
     'SPRING BALANCE BODY COVER LEFT',
     'SPRING BALANCE BODY COVER RIGHT',
     'SPRING BALANCE BODY-1',
     'SPRING BALANCE END CAP-2',
     'SPRING BALANCE END CAP-3',
     'SPRING BALANCE HANDLE BODY-1',
     'SPRING BALANCE HANDLE-1',
     'SPRING BALANCE TONGUE LEFT',
     'SPRING BALANCE TONGUE RIGHT',
     'STANDARD WEATHER STRIP-1'
];

// HEXAGONAL PELMET
export const TRADITIONAL_PELMET = [
    'TRADITIONAL PELMET 1-1',
    'TRADITIONAL PELMET 3-1',
    'TRADITIONAL PELMET 2-1',
];

// CAST BRACKET
export const CAST_BRACKET = [
    'CAST BRACKET 1-2',
    'CAST BRACKET 2-2',
];

// CAST BRACKET WITH BACK FLASHING
export const CAST_BRACKET_2 = [
    'CAST BRACKET 1-2',
    'CAST BRACKET 2-2',
    'CAST BRACKET BACK FLASHING-1'
];

// SQUARE PELMET
export const TOP_STYLE_PELMET = [
    'TOP STYLE STREAMLINE PELMET 1-1',
    'TOP STYLE STREAMLINE PELMET 3-1',
    'TOP STYLE STREAMLINE PELMET 2-1'
];

// ALL PELMET PARTS
export const GLOBAL_PELMET = [
    'TRADITIONAL PELMET 1-1',
    'TRADITIONAL PELMET 2-1',
    'TRADITIONAL PELMET 3-1',
    'CAST BRACKET 1-2',
    'CAST BRACKET BACK FLASHING-1',
    'CAST BRACKET 2-2',
    'TOP STYLE STREAMLINE PELMET 1-1',
    'TOP STYLE STREAMLINE PELMET 3-1',
    'TOP STYLE STREAMLINE PELMET 2-1',
    'BOTTOM CHANNEL'
];

export const COLOR_FRAME_PELMET = [
    'REVEAL CHANNEL 1',
    'REVEAL CHANNEL 2',
    'TOP STYLE STREAMLINE PELMET 1-1',
    'TOP STYLE STREAMLINE PELMET 3-1',
    'TOP STYLE STREAMLINE PELMET 2-1',
    'FACE FIX CHANNEL 1',
    'FACE FIX CHANNEL 2',
    'TRADITIONAL PELMET 1-1',
    'TRADITIONAL PELMET 2-1',
    'TRADITIONAL PELMET 3-1',
    'CAST BRACKET 1-2',
    'CAST BRACKET 2-2',
    'CAST BRACKET BACK FLASHING-1',
    'HDBB-1'
];

export const INTERIOR_COLOR_FRAME_PELMET = [
    'BOTTOM BAR',
    'BOTTOM CHANNEL',
    'FACE FIX CHANNEL 1',
    'FACE FIX CHANNEL 2',
    'FACE FIX STOPPER 2',
    'FACE FIX STOPPER 1',
    'FRONT PELMET FOAM',
    'PELMET FRONT',
    'PELMET TOP',
    'REVEAL CHANNEL 2',
    'REVEAL CHANNEL 1',
    'TRACK ALUMINIUM 1',
    'TRACK ALUMINIUM 2'
];

export const INTERIOR_COLOR_FIXTURES = [
    'BOTTOM BAR GUIDE 1',
    'BOTTOM BAR GUIDE 2',
    'END TRIM 1',
    'END TRIM 2',
    'END CAP 1',
    'END CAP 2'
];

export const INTERIOR_END_CAPS = [
    'END CAP 1',
    'END CAP 2'
];

export const SPRING_BALANCE = [
    'SPRING BALANCE BODY COVER LEFT',
    'SPRING BALANCE BODY COVER RIGHT',
    'SPRING BALANCE ALUMINIUM FLAT BAR-1',
    'SPRING BALANCE ALUMINIUM FLAT BAR-2',
    'SPRING BALANCE TONGUE LEFT',
    'SPRING BALANCE TONGUE RIGHT',
    'SPRING BALANCE BOTTOM STOP-2',
    'SPRING BALANCE BOTTOM STOP-3',
    'SPRING BALANCE END CAP-2',
    'SPRING BALANCE END CAP-3',
    'SPRING BALANCE HANDLE-1',
    'SPRING BALANCE HANDLE BODY-1',
    'SPRING BALANCE BODY-1',
];

export const REVERSE_HANDLE = [
    'REVERSE HANDLE LEVER-1',
    'REVERSE HANDLE-1'
];

export const BOTTOM_CHANNEL_HANDLE = [
    'BOTTOM CHANNEL'
];

export const GLOBAL_HIDDEN_PELMET = [
    'TRADITIONAL PELMET 1-1',
    'TRADITIONAL PELMET 2-1',
    'TRADITIONAL PELMET 3-1',
    'CAST BRACKET BACK FLASHING-1',
    'CAST BRACKET 1-2',
    'CAST BRACKET 2-2',
    'LARGE WEATHER STRIP-1',
    'CUSTOM MADE SKIRT-1',
    'REVERSE HANDLE LEVER-1',
    'REVERSE HANDLE-1',
    'FACE FIX CHANNEL 1',
    'FACE FIX CHANNEL 2',
    'FACE FIX GUIDE FUNNEL 1',
    'FACE FIX GUIDE FUNNEL 2',
    'BOTTOM CHANNEL',
    'FACE FIX STOPPER 1',
    'FACE FIX STOPPER 2',
];

export const FACE_FIX = [
    'FACE FIX CHANNEL 1',
    'FACE FIX CHANNEL 2',
    'FACE FIX GUIDE FUNNEL 1',
    'FACE FIX GUIDE FUNNEL 2'
];

export const FACE_FIX_INTERIOR = [
    'FACE FIX CHANNEL 1',
    'FACE FIX CHANNEL 2'
];

export const REVEAL_CHANNEL = [
    'REVEAL CHANNEL 2',
    'REVEAL CHANNEL 1',
    'REVEAL GUIDE FUNNEL 1',
    'REVEAL GUIDE FUNNEL 2',
];

export const REVEAL_CHANNEL_INTERIOR = [
    'REVEAL CHANNEL 1',
    'REVEAL CHANNEL 2'
];

// ALL BOTTOM BAR PARTS
export const GLOBAL_STRIP = [
    'STANDARD WEATHER STRIP-1',
    'LARGE WEATHER STRIP-1',
    'CUSTOM MADE SKIRT-1'
];

export const MATERIAL_MESH = [
    'MATERIAL-1',
    'MATERIAL-2'
];

export const SPLINE_MESH = [
    'SPLINE-1',
    'SPLINE-2',
    'SPLINE-1-TOP',
    'SPLINE-2-TOP'
];

export const SCALING_MESHES = {
    1: 'MATERIAL-1',
    2: 'MATERIAL-2',
    8: 'REVEAL CHANNEL 1',
    11: 'TOP STYLE STREAMLINE PELMET 1-1',
    20: 'REVEAL CHANNEL 2',
    22: 'STANDARD WEATHER STRIP-1',
    35: 'SPRING BALANCE ALUMINIUM FLAT BAR-1',
    53: 'SPRING BALANCE ALUMINIUM FLAT BAR-2',
    65: 'LARGE WEATHER STRIP-1',
    71: 'FACE FIX CHANNEL 2',
    74: 'TRADITIONAL PELMET 1-1',
    107: 'HDBB-1',
    112: 'CAST BRACKET BACK FLASHING-1',
    117: 'FACE FIX CHANNEL 1',
    123: 'CUSTOM MADE SKIRT-1',
    136: '86MM TOPTUBE-1',
    143: 'SPLINE-1',
    144: 'SPLINE-2',
};

// ALL PARTS
export const MESHES_IDS = {
    1: 'MATERIAL-1',
    2: 'MATERIAL-2',
    4: 'REVEAL GUIDE FUNNEL 1',
    8: 'REVEAL CHANNEL 1',
    11: 'TOP STYLE STREAMLINE PELMET 1-1',
    13: 'TOP STYLE STREAMLINE PELMET 2-1',
    15: 'TOP STYLE STREAMLINE PELMET 3-1',
    18: 'REVEAL GUIDE FUNNEL 2',
    20: 'REVEAL CHANNEL 2',
    22: 'STANDARD WEATHER STRIP-1',
    29: 'SPRING BALANCE BODY COVER LEFT',
    32: 'SPRING BALANCE BODY COVER RIGHT',
    35: 'SPRING BALANCE ALUMINIUM FLAT BAR-1',
    38: 'SPRING BALANCE TONGUE LEFT',
    41: 'SPRING BALANCE BODY-1',
    44: 'SPRING BALANCE TONGUE RIGHT',
    47: 'SPRING BALANCE HANDLE BODY-1',
    50: 'SPRING BALANCE END CAP-2',
    53: 'SPRING BALANCE ALUMINIUM FLAT BAR-2',
    55: 'SPRING BALANCE BOTTOM STOP-2',
    58: 'SPRING BALANCE BOTTOM STOP-3',
    60: 'SPRING BALANCE HANDLE-1',
    63: 'SPRING BALANCE END CAP-3',
    65: 'LARGE WEATHER STRIP-1',
    69: 'FACE FIX GUIDE FUNNEL 2',
    71: 'FACE FIX CHANNEL 2',
    74: 'TRADITIONAL PELMET 1-1',
    76: 'TRADITIONAL PELMET 2-1',
    78: 'TRADITIONAL PELMET 3-1',
    82: 'ALL BLINDS 1-1',
    85: 'ALL BLINDS 1-2',
    88: 'ALL BLINDS 2-1',
    91: 'ALL BLINDS 2-2',
    95: 'ALL BLINDS 2-3',
    97: 'ALL BLINDS 2-4',
    99: 'ALL BLINDS 3-1_primitive0',
    100: 'ALL BLINDS 3-1_primitive1',
    101: 'ALL BLINDS 3-1_primitive2',
    102: 'ALL BLINDS 3-2_primitive0',
    103: 'ALL BLINDS 3-2_primitive1',
    104: 'ALL BLINDS 3-2_primitive2',
    107: 'HDBB-1',
    110: 'CAST BRACKET 1-2',
    112: 'CAST BRACKET BACK FLASHING-1',
    114: 'CAST BRACKET 2-2',
    117: 'FACE FIX CHANNEL 1',
    119: 'FACE FIX GUIDE FUNNEL 1',
    123: 'CUSTOM MADE SKIRT-1',
    130: '6MM IDLER CUP-1',
    131: '6MM IDLER PIN-1',
    132: '86 93 TUBE ADAPTOR-1',
    133: '86 93 TUBE ADAPTOR-2',
    134: '86 93 TUBE ADAPTOR-3',
    135: '86 93 TUBE ADAPTOR-4',
    136: '86MM TOPTUBE-1',
    137: 'tab-1',
    138: 'ZDRW0089- TRY Bearing Housing-1_primitive0',
    139: 'ZDRW0089- TRY Bearing Housing-1_primitive1',
    140: 'ZDRW0094 2 GUIDE SLEEVE-1',
    141: 'REVERSE HANDLE LEVER-1',
    142: 'REVERSE HANDLE-1',
    143: 'SPLINE-1',
    144: 'SPLINE-2',
    145: 'SPLINE-1-TOP',
    146: 'SPLINE-2-TOP'
};

export const INTERIOR_MESHES_IDS = {
    107: 'BOTTOM BAR GUIDE 1',
    110: 'BOTTOM BAR GUIDE 2',
    112: 'BOTTOM BAR',
    114: 'BOTTOM CHANNEL',
    116: 'END CAP 1',
    118: 'END CAP 2',
    120: 'END TRIM 1',
    122: 'END TRIM 2',
    124: 'FACE FIX CHANNEL 1',
    126: 'FACE FIX CHANNEL 2',
    128: 'FACE FIX STOPPER 2',
    130: 'FACE FIX STOPPER 1',
    132: 'FRONT PELMET FOAM',
    134: 'MATERIAL-1',
    136: 'MATERIAL-2',
    137: 'PELMET FRONT',
    139: 'PELMET TOP',
    141: 'REVEAL CHANNEL 2',
    143: 'REVEAL CHANNEL 1',
    145: 'TRACK ALUMINIUM 1',
    147: 'TRACK ALUMINIUM 2',
    149: 'WEATHER STRIP',
};

export const INTERIOR_SCALING_MESHES = {
    134: 'MATERIAL-1',
    136: 'MATERIAL-2',
    112: 'BOTTOM BAR',
    114: 'BOTTOM CHANNEL',
    132: 'FRONT PELMET FOAM',
    137: 'PELMET FRONT',
    139: 'PELMET TOP',
    149: 'WEATHER STRIP',
    145: 'TRACK ALUMINIUM 1',
    147: 'TRACK ALUMINIUM 2',
    141: 'REVEAL CHANNEL 2',
    143: 'REVEAL CHANNEL 1',
    124: 'FACE FIX CHANNEL 1',
    126: 'FACE FIX CHANNEL 2',
};

export const INTERIOR_WIDTH_MESH = {
    134: 'MATERIAL-1',
    136: 'MATERIAL-2',
    112: 'BOTTOM BAR',
    114: 'BOTTOM CHANNEL',
    132: 'FRONT PELMET FOAM',
    137: 'PELMET FRONT',
    139: 'PELMET TOP',
    149: 'WEATHER STRIP'
};

export const INTERIOR_WIDTH_POSITION_MESH = {
    107: 'BOTTOM BAR GUIDE 1',
    110: 'BOTTOM BAR GUIDE 2',
    116: 'END CAP 1',
    118: 'END CAP 2',
    120: 'END TRIM 1',
    122: 'END TRIM 2',
    124: 'FACE FIX CHANNEL 1',
    126: 'FACE FIX CHANNEL 2',
    130: 'FACE FIX STOPPER 1',
    128: 'FACE FIX STOPPER 2',
    141: 'REVEAL CHANNEL 2',
    143: 'REVEAL CHANNEL 1',
    145: 'TRACK ALUMINIUM 1',
    147: 'TRACK ALUMINIUM 2'
};

export const INTERIOR_HEIGHT_MESH = {
    136: 'MATERIAL-2',
    141: 'REVEAL CHANNEL 2',
    143: 'REVEAL CHANNEL 1',
    145: 'TRACK ALUMINIUM 1',
    147: 'TRACK ALUMINIUM 2',
    124: 'FACE FIX CHANNEL 1',
    126: 'FACE FIX CHANNEL 2',
};

export const INTERIOR_HEIGHT_POSITION_MESH = {
    134: 'MATERIAL-1',
    107: 'BOTTOM BAR GUIDE 1',
    110: 'BOTTOM BAR GUIDE 2',
    112: 'BOTTOM BAR',
    114: 'BOTTOM CHANNEL',
    116: 'END CAP 1',
    118: 'END CAP 2',
    120: 'END TRIM 1',
    122: 'END TRIM 2',
    130: 'FACE FIX STOPPER 1',
    128: 'FACE FIX STOPPER 2',
    132: 'FRONT PELMET FOAM',
    137: 'PELMET FRONT',
    139: 'PELMET TOP',
    149: 'WEATHER STRIP'
};

export const TOP_STYLE_IDS = [
    { id: 1, meshes: CAST_BRACKET },
    { id: 3, meshes: TRADITIONAL_PELMET },
    { id: 4, meshes: TOP_STYLE_PELMET },
    { id: 2, meshes: CAST_BRACKET_2 }
];

export const BOTTOM_BAR_IDS = [
    { id: 2, meshes: [MESHES_IDS[22]]},
    { id: 3, meshes: [MESHES_IDS[65]]},
    { id: 4, meshes: [MESHES_IDS[123]] }
];

export const MATERIAL_COLORS = [
    {color: '#FBFBFB', name: 'Snow'},
    {color: '#edf1ed', name: 'Shale Grey'},
    {color: '#d2dbdb', name: 'Mist'},
    {color: '#c1c9c2', name: 'Windspray'},
    {color: '#ada195', name: 'Jasper'},
    {color: '#e3dbbe', name: 'Bondi'},
    {color: '#ddd9d1', name: 'Porcelain'},
    {color: '#98b7b6', name: 'Neptune'},
    {color: '#79908f', name: 'Steel Blue'},
    {color: '#969b8f', name: 'Green Tea'},
    {color: '#e1cdb1', name: 'Oyster'},
    {color: '#bab4a8', name: 'Cappuccino'},
    {color: '#aa9478', name: 'Taupe'},
    {color: '#666155', name: 'Caramel'},
    {color: '#6b6762', name: 'Basalt'},
    {color: '#D9CAA3', name: 'Classic Cream'},
    {color: '#615D52', name: 'Woodland Grey'},
    {color: '#404040', name: 'Monument'},
    {color: '#38444c', name: 'Gunmetal'},
    {color: '#89987c', name: 'Evergreen'},
    {color: '#BFAB92', name: 'Paperbark'},
    {color: '#2D2825', name: 'Ebony'},
    {color: '#514432', name: 'Cocoa'},
    {color: '#6d3b3e', name: 'Chinaberry'},
    {color: '#245445', name: 'Fed Green'}
];

export const FRAME_COLORS = [
    {color: '#FBFBFB', name: 'Pearl White'},
    {color: '#edf1ed', name: 'Shale Grey'},
    {color: '#d2dbdb', name: 'Surfmist'},
    {color: '#c1c9c2', name: 'Bushland'},
    {color: '#ada195', name: 'Jasper'},
    {color: '#e3dbbe', name: 'Magnolia'},
    {color: '#ddd9d1', name: 'Shoji White'},
    {color: '#98b7b6', name: 'Windspray'},
    {color: '#79908f', name: 'Ironstone'},
    {color: '#969b8f', name: 'Storm Grey'},
    {color: '#e1cdb1', name: 'Stone Beige'},
    {color: '#bab4a8', name: 'Dune'},
    {color: '#aa9478', name: 'Doeskin'},
    {color: '#666155', name: 'Hamersley Brown'},
    {color: '#6b6762', name: 'Gully'},
    {color: '#D9CAA3', name: 'Classic Cream'},
    {color: '#615D52', name: 'Woodland Grey'},
    {color: '#404040', name: 'Monument'},
    {color: '#38444c', name: 'Deep Ocean'},
    {color: '#89987c', name: 'Windspray'},
    {color: '#BFAB92', name: 'Paperbark'},
    {color: '#2D2825', name: 'Black Satin'},
    {color: '#514432', name: 'Core Ten'},
    {color: '#6d3b3e', name: 'Claret'},
    {color: '#245445', name: 'Cottage Green'},
];
