import { Injectable } from '@angular/core';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { ShareService } from '../share-data/share-data.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';
import * as _ from 'lodash';
import { IBlindSelect } from '@core/config';
declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class SaveSessionBlindsService {
    isNewBlind: boolean | string;
    getBlindSelect = this.shareService.getBlindSelect.subscribe((res: IBlindSelect) => {
        this.isNewBlind = res.status === 'new';
    });

    constructor(
        private localStorageService: LocalStorageService,
        private serverDataService: ServerDataService,
        private sessionStorageService: SessionStorageService,
        private shareService: ShareService,
    ) { }

    getCountryCode(): any {
      return this.sessionStorageService.getSession('zip-country-code');
    }

    PutStorageDataToServer(): void {
        const sessionSaved = this.localStorageService.getBlindData('zip-blind-session-saved');

        if (sessionSaved) {
            const sessionKey = this.localStorageService.getBlindData('zip-blind-session');
            const getStorageData = this.localStorageService.getBlindData('zip-blind-data');
            const lastBlindSetup = getStorageData[getStorageData.length - 1]?.setup;
            const storageDataWithAction = {
                country: this.getCountryCode(),
                action: 'blinds-update',
                blinds: getStorageData
            };

            if (lastBlindSetup?.mounting || _.isEmpty(getStorageData)) {
                if (this.isNewBlind) {
                    $('.mc-tool-bar-accordion').addClass('loading');
                    this.isNewBlind = false;
                }

                this.preventReloadPage(true);

                this.serverDataService.PutSessionData(storageDataWithAction, sessionKey).subscribe(() => {
                    $('.mc-tool-bar-accordion').removeClass('loading');
                    this.preventReloadPage(false);
                    this.shareService.setBlindsUpdated(true);
                });

            }
        }
    }

    preventReloadPage(status): void {
        // window.onbeforeunload = (e) => {
        //     if (status) {
        //         e.returnValue = '';
        //     }

        //     return status;
        // };
    }
}
