import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BlindListComponent } from '@features/blind-list/blind-list.component';
import { ShareService } from '@core/services/share-data/share-data.service';
import { EngineService } from '@core/services/engine/engine.service';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { ServerDataService } from '@core/services/server-data/server-data.service';
import { SaveSessionBlindsService } from '@core/services/save-session-blinds/save-session-blinds.service';
import { ActivatedRoute } from '@angular/router';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { SessionStorageService } from '@core/services/session-storage/session-storage.service';

@Component({
    selector: 'app-outdoor-list',
    templateUrl: './outdoor-list.component.html',
    styleUrls: ['../blind-list.component.scss', '../blind-list.responsive.scss']
})
export class OutdoorListComponent extends BlindListComponent implements OnInit {
    @Input() index: number;
    @Input() blindsListComponent: BlindListComponent;
    blind: any;
    constructor(
        public shareDataService: ShareService,
        public engineService: EngineService,
        public localStorageService: LocalStorageService,
        public serverDataService: ServerDataService,
        public saveSessionBlind: SaveSessionBlindsService,
        public route: ActivatedRoute,
        public screenShotService: ScreenshotService,
        public sessionStorageService: SessionStorageService,
        public changeDetection: ChangeDetectorRef
    ) {
        super(
            shareDataService,
            engineService,
            localStorageService,
            serverDataService,
            saveSessionBlind,
            route,
            screenShotService,
            sessionStorageService,
            changeDetection
        );
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.blind = this.blindsData[this.index];
        setTimeout(this.blindNameSave.bind(this, this.blind.blind_id));
    }

    blindNameSave(id: any): void {
        super.blindNameSave(id);
        this.blindsListComponent.blindsData[this.index].name = this.blind.name;
    }
}
