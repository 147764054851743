<div #overviewPage class="mc-overview-wrap">
    <app-summary-table class="mc-overview-section"></app-summary-table>
    <div #retailerInfo *ngIf="addInfoAvailability" class="mc-overview-section">
        <app-retailar-action></app-retailar-action>
    </div>

    <div class="mc-overview-copy">
        <a class="mc-overview-copy-link" href="https://www.ziptrak.com.au/contact/" target="_blank">Contact</a>
        <a class="mc-overview-copy-link" href="https://www.ziptrak.com.au/contact/privacy-policy/" target="_blank">Privacy Policy</a>

        <div class="mc-overview-copy-block">
            <span class="mc-overview-copy-text">Copyright © Ziptrak Pty Ltd 2022</span>
            <a class="mc-overview-copy-link" href="https://www.ziptrak.com.au/contact/terms-and-conditions/"
               target="_blank">Terms and Conditions.</a>
        </div>
    </div>
</div>
