import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { ScreenshotService } from '@core/services/screenshot/screenshot.service';
import { ShareService } from '@core/services/share-data/share-data.service';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class OverviewLinkHandlerService {
  getScreenShotSaved: Subscription;

    constructor(
      private localStorageService: LocalStorageService,
      private screenShotService: ScreenshotService,
      private shareService: ShareService,
      private router: Router
    ) { }

    link(): void {
      const currentBlindId = this.localStorageService.getBlindData('zip-current-blind-id');

      this.screenShotService.saveScreenShot(currentBlindId);
      this.getScreenShotSaved = this.shareService.getScreenShotSaved.subscribe(res => {
        this.router.navigate(['/overview']);
        $(document).ready().scrollTop(0);
        this.addActiveClass();
        this.getScreenShotSaved.unsubscribe();
      });
    }

    addActiveClass(): void {
      $('.mc-nav-menu li').last().addClass('active');
    }
}
